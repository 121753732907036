import React, { Component } from "react";
import "./Unit.css"
import { HomeAPi } from "../../../../api/HomeAPI";
import { Table } from "./modules/Table/Table";
import { Board } from "./modules/Board/Board";
import { UnitAPi } from "../../../../api/UnitAPI";

const homeapi = new HomeAPi();
const unitapi = new UnitAPi();

export class Unit extends Component {
    columns = [
        {
            name: 'id',
            selector: 'id_nodo',
            sortable: true,
            omit: true,
            center:true
        },
        {
            name: 'Truck #',
            selector: 'tolva_id_caex',
            sortable: true,
            center:true
        },
        {
            name: 'Cycles',
            selector: 'Cycles',
            sortable: true,
            center:true
        },
        {
            name: 'Avg. Cycles Time',
            selector: 'avg_tt',
            sortable: true,
            center:true
        },
        {
            name: 'Avg. Payload',
            selector: 'avg_payload',
            sortable: true,
            center:true
        },
        {
            name: 'Availability %',
            selector: 'availability',
            sortable: true,
            center:true
        },
    ];
    data = [ ];

    constructor(props) {
        super(props);
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.state = {
            data: this.data,
            dataTruckPosition: [],
            dateSelected: date,
            view: 1,
            selectedRow: {},
            truckRoute: []
        }
    }

    componentWillMount() {
        this.updateData();
        if (!this.refreshdata) {
            homeapi.getMRT().then(response => {
                if (response.status) this.setState({ dataTruckPosition: response.data });
              //  console.log(response)

                this.refreshdata = setInterval(() => {
                    homeapi.getMRT().then(response => {
                        if (response.status) this.setState({ dataTruckPosition: response.data });
                    }).catch(err => {

                        console.log(err)
                    })
                }, 5000);
            }).catch(err => {

                console.log(err)
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    updateData(date = null) {
        unitapi.getTable(date || this.state.dateSelected).then((response) => {
            if (response.status) this.setState({ data: response.data });
            unitapi.getMapTruckRoute(date || this.state.dateSelected).then((response) => {
 
                if (response.status) this.setState({ truckRoute: response.data });
            }).catch((e) => { console.log(e) });
        });
    }

    

    datatableSelect(data) {
        this.setState({ view: 2, selectedRow: data })
    }

    onChangeSelectValue(date,interval) {

        this.setState({ dateSelected: date })
        this.updateData(date);
    }

    boardreturn(e) {

        this.setState({ view: 1 })
    }

    render() {
        if (this.state.view == 2) {
            return (<Board btnreturn={(e) => { this.boardreturn(e) }} data={this.state.selectedRow} />)
        } 
        return (<Table data={this.state.data} mapTruckRoute={this.state.truckRoute} mapPositionTruck={this.state.dataTruckPosition} columns={this.columns} onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }} />)

    }
};

