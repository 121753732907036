import React, { Component } from "react";
import "./RMS.css"
import { HomeAPi }            from "../../../../api/HomeAPI";
import { Table }              from "./modules/Table/Table";
import { Board }              from "./modules/Board/Board";
import { UnitAPi }            from "../../../../api/UnitAPI";
import { CycleAnalyticsAPi }  from "../../../../api/CycleAnalyticsApi";
import { Auth }               from "../../../../helpers/auth";

const auth = new Auth();
const homeapi = new HomeAPi();
const unitapi = new UnitAPi();
const cycleapi = new CycleAnalyticsAPi();

export class RMS extends Component {
  columns = [
      {
          name: 'id',
          selector: 'id_nodo',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.tolva_id_caex,
          sortable: true,
          center:true,
      },
  ];
  data = [ ];

  constructor(props){
      super(props);
      this.state = {
          TruckInOperation: 0,
          MinutesInOperation: 0,
          NCycles: 0,
          SpeedingAlarms: 0,
          KmToday: 0,
          data: this.data,
          view: 'table',
          selectedRow: {},
          batery_level: 0,
          sat_in_use: 0,
          avg_payload: 0
      }
  }

  componentWillMount() {
    this.updateData();
  }

  componentWillUnmount(){
    clearInterval(this.refreshdata)
  }

  updateData() {
    const date = new Date();
    cycleapi.getTable(date).then((response) => {
      if (response.status){
        this.setState({ data: response.data[0] });
      }
    }).catch((e) => { console.log(e) });
  }

  renderSwitch() {
    switch(this.state.view) {
      case 'board':
        return (
          <Board
            btnreturn={(e) => { this.boardreturn(e) }}
            btnunitreturn={(e) => { this.unitreturn(e) }}
            data={this.state.selectedRow}
          />);
      case 'table':
      return (
        <Table
          btnreturn={(e) => { this.boardreturn(e) }}
          btnfleetreturn={(e) => { this.fleetreturn(e) }}
          btnunitreturn={(e) => { this.unitreturn(e) }}
          data={this.state.data}
          columns={this.columns}
          onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
        />
            )
      default:
        return 'foo';
    }
  }

  datatableSelect(data) {
      this.setState({ view: 'board', selectedRow: data })
  }

  boardreturn(e) {
      this.setState({ view: 'table' })
  }

  unitreturn(e) {
    this.setState ({ view: 'table'})
  }

  render() {
    return (
      <div>
        <div>{this.renderSwitch()}</div>
      </div>
    );
  };

}
