import React, { Component } from "react";
import "./RTMPics.css"
import { HomeAPi } from "../../../../api/HomeAPI";
import { Table } from "./modules/Table/Table";
import { Board } from "./modules/Board/Board";
import { CycleAnalyticsAPi } from "../../../../api/CycleAnalyticsApi";

const homeapi = new HomeAPi();
const cycleapi = new CycleAnalyticsAPi();

export class RTMPics extends Component {
    columns = [
        {
            name: 'id',
            selector: 'id_nodo',
            sortable: true,
            omit: true,
            center:true
        },
        {
            name: 'Truck #',
            selector: row => row.tolva_id_caex,
            sortable: true,
            center:true,
        },
    ];
    data = [ ];

    constructor(props) {
        super(props);
        this.state = {
            data: this.data,
            view: 'table',
            selectedRow: {},
        }
    }

    componentWillMount() {
      this.updateData();
   }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    updateData() {
      const date = new Date();
      cycleapi.getTable(date).then((response) => {
        if (response.status){
          this.setState({ data: response.data[0] });
        }
      }).catch((e) => { console.log(e) });
    }

    datatableSelect(data) {
        this.setState({ view: 'board', selectedRow: data })
    }

    boardreturn(e) {
        this.setState({ view: 'table' })
    }

    renderSwitch() {
      switch(this.state.view) {
        case 'board':
          return (
            <Board
              btnreturn = {(e) => { this.boardreturn(e) }}
              data = {this.state.selectedRow}
            />);
        case 'table':
          return (
            <Table
              data={this.state.data}
              columns={this.columns}
              onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
            />
          )
        default:
          return 'foo';
      }
    }

    render() {
      return (
          <div>{this.renderSwitch()}</div>
      );
    };
}
