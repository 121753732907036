import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BarTitle.css"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
export class BarTitle extends Component {
    constructor(props) {
        super(props)
    }

    onClickBtn(e) {
        if (this.props.onClickbtnReturn) {
            this.props.onClickbtnReturn(e)
        }
    }

    onClickFleetBtn(e) {
        if (this.props.onClickbtnFleetReturn) {
            this.props.onClickbtnFleetReturn(e)
        }
    }

    onClickUnitBtn(e) {
        if (this.props.onClickbtnUnitReturn) {
            this.props.onClickbtnUnitReturn(e)
        }
    }

    render() {
        return (<>
            <div className={"row bar"}>
                {(
                    this.props.btnReturn ?
                    <div className={"col s1 offset bar-button"}>
                      <a className={"main-item white-text btn bar-button-a"}
                        onClick={(e) => { this.onClickBtn(e) }}>
                        <i className={"bar-button-a-i material-icons  white-text"}>
                          <ArrowBackIosIcon />
                        </i>
                      </a>
                    </div>
                    :<div className={"col s1 offset bar-button"}>
                      <a className={"main-item white-text "}>

                      </a>
                    </div>
                  )
                }
              <div className={"col s1 bar-title"}>{this.props.title}</div>
                {this.props.pagSelect != 'Fleet'
                  ?<div className={"col s1 bar-overview"}><a className={"btn_noselect"} onClick={(e) => { this.onClickFleetBtn(e) }}>Fleet Analytics</a></div>
                  :<div className={"col s1 bar-overview"}><a className={"btn_select"} >Fleet Analytics</a></div>
                }
              {this.props.pagSelect != 'Unit'
                ?<div className={"col s1 bar-overview"}><a className={"btn_noselect"} onClick={(e) => { this.onClickUnitBtn(e) }}>Unit Analytics</a></div>
                :<div className={"col s1 bar-overview"}><a className={"btn_select"} >Unit Analytics</a></div>
              }
          </div>
        </>
        )
    }
}
