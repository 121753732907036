import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Board.css"
import { BarTitle }           from "../../../../../modulos/BarTitle/BarTitle";
import { SelectorDate }       from "../../../../../modulos/SelectorDate/SelectorDate"
import { Payload }            from "../../../../../modulos/Payload/Payload"
import { Payload2 }            from "../../../../../modulos/Payload2/Payload2"
import { BoxPayload }         from "../../../../../modulos/BoxPayload/BoxPayload"
import { GraphPayloadCycle }  from "../../../../../modulos/GraphPayloadCycle/GraphPayloadCycle"
import { UnitAPi }            from "../../../../../../api/UnitAPI";


const unitapi = new UnitAPi();

export class Board extends Component {

  constructor(props) {
    super(props);
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    this.state = {
        monitor: false,
        dateSelected: date,
        intervalSelected: "h",
        graph_key: [],
        graph_payload: [],
        graph_payload_d: [],
        graph_target_payload: [],
        graph_target_payload_d: [],
        graph_qciclos: [],
        graph_tt: [],
        data: false,
        refreshdata: false,
        graph_max_payload: false,
        graph_max_payload_d: false,
        type_xaxis: false
    }
  }

  componentWillMount() {
      if (!this.state.refreshdata) {
          this.updateData()
          unitapi.getMRTByIdNodo(this.props.data.id_nodo).then(response => {
              if (response.status) this.setState({ monitor: response.data })
              this.state.refreshdata = setInterval(() => {
                  unitapi.getMRTByIdNodo(this.props.data.id_nodo).then(response => {
                      if (response.status) this.setState({ monitor: response.data })
                  })
              }, 5000);
          })
      }
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshdata)
  }

  updateData(date = null, interval = "h") {
      unitapi.getBoardByIdNodo(this.props.data.id_nodo, date || this.state.dateSelected, interval).then((response) => {
          if (response.status) {
              let payload = [];
              let target_payload = [];
              let tt = [];
              let date_x;
              let secuencia_x = 0;
              let graph = {}
              let max_payload = 0;
              let qciclos = [];

              if(response.data.length != 0){
                for (const g of response.data.graph) {
                  graph[new Date(g.date).getTime()] = g
                }
              }


              for (const gk of Object.keys(graph).sort()) {
                  if (this.state.intervalSelected == "h") {
                      //date_x = new Date(graph[gk].date).toLocaleTimeString().substring(0, 5)
                  } else if (this.state.intervalSelected == "d") {
                      date_x = new Date(graph[gk].date).toDateString().split(" ")
                      date_x = date_x[1] + "  " + date_x[2];
                  } else if ((this.state.intervalSelected == "m")||
                             (this.state.intervalSelected == "y")||
                             (this.state.intervalSelected == "1y")||
                             (this.state.intervalSelected == "2y")){
                      date_x = new Date(graph[gk].date).toDateString().split(" ")
                      date_x = date_x[1] + ", " + date_x[3]
                  }
                  secuencia_x ++;
                  payload.push({
                      x: (this.state.intervalSelected == "h" ? secuencia_x : graph[gk].date),
                      y: Math.round(graph[gk].payload / 1000)
                  })
                  target_payload.push({
                      x: (this.state.intervalSelected == "h" ? secuencia_x : graph[gk].date),
                      y: Math.round(graph[gk].target_payload)
                  })
                  qciclos.push({
                      x: (this.state.intervalSelected == "h" ? secuencia_x : graph[gk].date),
                      y: Math.round(graph[gk].n_ciclos)
                  })
                  tt.push({
                      x: (this.state.intervalSelected == "h" ? secuencia_x : graph[gk].date),
                      y: Math.round(graph[gk].tt)
                  })

                  if( graph[gk].payload/1000 > graph[gk].target_payload ) {
                    if ( graph[gk].payload/1000 > max_payload ) {
                      max_payload = Math.round(graph[gk].payload/1000)
                    }
                  }else{
                    if ( graph[gk].target_payload > max_payload ) {
                      max_payload = Math.round(graph[gk].target_payload)
                    }
                  }

              }
              //console.log(" response.data ", response.data);
              max_payload = max_payload * 1.1
              if (response.status){
                if (this.state.intervalSelected == "h"){
                  this.setState({
                      data: response.data,
                      graph_payload: payload,
                      graph_target_payload: target_payload,
                      graph_max_payload : max_payload,
                      graph_payload_d: payload,
                      graph_target_payload_d: target_payload,
                      graph_max_payload_d : max_payload,
                      graph_qciclos: qciclos,
                      graph_tt: tt
                  });
                }else{
                  this.setState({
                      data: response.data,
                      graph_payload: payload,
                      graph_target_payload: target_payload,
                      graph_max_payload : max_payload,
                      graph_qciclos: qciclos,
                      graph_tt: tt
                  });
                }
              }
          }
      });
  }

  onClickbtnReturn(event) {
      if (this.props.btnreturn) {
          this.props.btnreturn(event)
      }
  }

  onClickbtnFleetReturn(event) {
      if (this.props.btnfleetreturn) {
          this.props.btnfleetreturn(event)
      }
  }

  onClickbtnUnitReturn(event) {
      if (this.props.btnunitreturn) {
          this.props.btnunitreturn(event)
      }
  }

  onChangeValue(date, interval) {
      this.setState({
          dateSelected: date,
          intervalSelected: interval
      })
      this.updateData(date, interval);
  }

  RenderGraficoPayload(){
      if(this.state.intervalSelected == "h"){

        return (<Payload
          series_1 ={this.state.graph_payload}
          series_2 ={this.state.graph_target_payload}
          max_series = {this.state.graph_max_payload}
          type_1 = {this.state.intervalSelected == "h" ? 'area' : 'area' }
          type_2 = 'line'
        />)
      }else{

        return(<Payload2
          series_1 ={this.state.graph_payload}
          series_2 ={this.state.graph_target_payload}
          series_3 ={this.state.graph_qciclos}
          max_series = {this.state.graph_max_payload}
          type_1 = {this.state.intervalSelected == "h" ? 'line' : 'area' }
          type_2 = 'line'
          type_3 = 'line'
        />)
      }


  }


  render() {
    return (<>
      <div className={"row"}>
        <div className={"row unitpayload-board_1"}>
          <div className={"col s12 unitpayload-BarTitle"}>
            <BarTitle
              onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }}
              onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
              onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
              btnReturn title={this.props.data.tolva_id_caex}
              subtitle={this.subtitle}
              pagSelect= "Unit"
            />
          </div>
        </div>
        <div className={"row unitpayload-board_2"}>
          <div className={"col s6 box-iz "}>
            <div className={"row"}>
              <div className={"col s12 board-selector-date"}>
                  <SelectorDate onChange={(d, i) => { this.onChangeValue(d, i) }} />
              </div>
              <div className={"col s12 board-payload"}>
                  <GraphPayloadCycle
                    payloadAvg = {(this.state.data.avg_payload ? (Math.round(this.state.data.avg_payload/1000)).toLocaleString('en')  + "" : "0")}
                    series_1 ={this.state.graph_payload_d}
                    series_2 ={this.state.graph_target_payload_d}
                    max_series = {this.state.graph_max_payload_d}
                    type_1 = {this.state.intervalSelected == "h" ? 'area' : 'area' }
                    type_2 = 'line'
                  />
              </div>
            </div>
          </div>
          <div className={"col s6 box-der "}>
            <div className={"row "}>
                <BoxPayload
                  totaltonnes={(this.state.data.total_payload ? (Math.round(this.state.data.total_payload/1000)).toLocaleString('en')  + "" : "0")}
                  totalload={(this.state.data.porcentage_payload ? Math.round(this.state.data.porcentage_payload) + " %" : "0 %")}
                  tonnesbehind={(this.state.data.target_schedule ? (Math.round(this.state.data.target_schedule/1000)).toLocaleString('en')  + "" : "0")}
                  />
            </div>
          </div>
        </div>
        <div className={"row unitpayload-board_3"}>
          <div className={"col s12"} >
            {this.RenderGraficoPayload()}
          </div>
        </div>
      </div>
      </>
    );
  }
}
