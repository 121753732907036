import React, { Component } from "react";
import { Redirect }         from "react-router";
import { Monitor }          from "../../../modulos/Monitor/Monitor";
import { Map }              from "../../../modulos/Map/Map";
import { Graphome }         from "../../../modulos/GraficoHome/GraficoHome";
import { LogAlarms }        from "../../../modulos/LogAlarms/LogAlarms"
import { HomeAPi }          from "../../../../api/HomeAPI";
import "./HomeMel.css"

const homeapi = new HomeAPi();

export class HomeMel extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data: [],
          dataLat: [],
          dataLon: [],
          route: true
       }
    }

    componentWillMount() {
        if (!this.refreshdata) {
            homeapi.getGpsPoint().then(response => {
              console.log("--> ", response)
              if (response.status){
                let lstLat = [];
                let lstLon = [];
                for(const reg of response.data){
                  lstLat.push(reg.gps_lat)
                  lstLon.push(reg.gps_lat)
                }
                this.setState({
                  dataLat: lstLat,
                  dataLon: lstLon
                });
              }
                this.refreshdata = setInterval(() => {
                    homeapi.getGpsPoint().then(response => {
                      let lstLat = [];
                      let lstLon = [];
                      for(const reg of response.data){
                        lstLat.push(reg.gps_lat)
                        lstLon.push(reg.gps_lon)
                      }
                      this.setState({
                        dataLat: lstLat,
                        dataLon: lstLon
                      });
                    }).catch(err => {
                        console.log(err)
                    })
                }, 5000);
            }).catch(err => {
                console.log(err)
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    render() {
      return (
        <>
          <div className={"home-component"}>
            <div className={"row home-component-row"}>
              <div className={"col s10 "}>
                <Map
                  trucks={this.state.data}
                  route={this.state.route}
                />
              </div>
              <div className={"col s2 "}>
                <LogAlarms />
              </div>
            </div>
          </div>
        </>
      )
    }

};
