import React, { Component } from "react";
import { Monitor } from "../../../../modulos/Monitor/Monitor";
import { Map } from "../../../../modulos/Map/Map";
import { Graphome } from "../../../../modulos/GraficoHome/GraficoHome";
import "./style.css"
import { SettingsAPi } from "../../../../../api/SettingsAPI";
import { Redirect } from "react-router";

const settingsapi = new SettingsAPi();

export class Areaupdate extends Component {

  constructor(props) {
      super(props);
      this.state = {
        Nombre_area:'',
        descripcion:'',
        diametro:'',
        latitud:'',
        longitud:'',
        tipo_area:'',
        redirect: false
      }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    const datos = {
      Nombre_area: data.get('Nombre_area'),
      descripcion: data.get('descripcion'),
      diametro: data.get('diametro'),
      latitud: data.get('latitud'),
      longitud: data.get('longitud'),
      tipo_area: data.get('tipo_area'),
      id:data.get('id'),
    };
    let datosjason = JSON.stringify(datos);
    settingsapi.UpdateAreaID(datosjason).then(response => {
        alert ("Se han actualizado correctamente los datos.");
        this.setState({
          redirect: true
        });
    }).catch(err => {
        alert ("Ha ocurrido un error al actualizar los datos.")
    })
  }

  componentWillMount() {
    if (!this.refreshdata) {
      settingsapi.getSETTINGSAreaID(this.props.id).then(response => {
        this.setState({
          Nombre_area: response[0].Nombre_area,
          descripcion:response[0].descripcion,
          diametro:response[0].diametro,
          latitud:response[0].latitud,
          longitud:response[0].longitud,
          tipo_area:response[0].tipo_area
        })
      })
    }
  }

  componentWillUnmount() {
      clearInterval(this.refreshdata)
  }

  componentDidMount(props) {

  }

  render() {

    if (this.state.redirect){
      return <Redirect to='/settings' />
    }


    return (

      <>
      <div class="user-detail">
        <center><h2>Modify area data</h2></center>
        <form onSubmit={this.handleSubmit} id="formArea">
              <p><label>Area name</label>
              <input type="text" placeholder="Enter area name" name="Nombre_area" value={this.state.Nombre_area} onChange={this.handleInputChange} required /></p>
              <p><label>Description</label>
              <input type="text" placeholder="Enter description" name="descripcion" value={this.state.descripcion} onChange={this.handleInputChange} required /></p>
              <p><label>Area type</label>
              <input type="text" placeholder="Enter area type" name="tipo_area" value={this.state.tipo_area} onChange={this.handleInputChange} required /></p>
              <p><label>Latitude</label>
              <input type="text" placeholder="Enter latitude" name="latitud" value={this.state.latitud} onChange={this.handleInputChange} required /></p>
              <p><label>Longitude</label>
              <input type="text" placeholder="Enter longitude" name="longitud" value={this.state.longitud} onChange={this.handleInputChange} required /></p>
              <p><label>Diameter</label>
              <input type="text" placeholder="Enter diameter" name="diametro"  value={this.state.diametro} onChange={this.handleInputChange} required /></p>
              <input type="hidden" name="id" value={this.props.id} />
              <center><input class="btn waves-effect waves-green" type="submit" value="Submit"  /></center>
          </form>
      </div>
      </>)
  }
}
