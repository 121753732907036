import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();
export class PicsAPI {

  contructor() {}


  getPhotoList(id_nodo){
    return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/mrtpicslist/`  + id_nodo, {
      method: "GET",
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
            'authorization': auth.getToken()
        }
    }).then((response) =>  response.json())
  }

  CommandTakePic(data){
    return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/commandtakepic`, {
      method: "PUT",
      body: data,
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
            'authorization': auth.getToken()
        }
    }).then((response) =>  response.json())
  }

  CommandLightOn(data){
    return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/commandlightOn`, {
      method: "PUT",
      body: data,
        headers: {
            "Accept": 'application/json',
            'Content-Type': 'application/json',
            'authorization': auth.getToken()
        }
    }).then((response) =>  response.json())
  }

}
