import React, { Component } from "react";
import { Monitor } from "../../../../modulos/Monitor/Monitor";
import { Map } from "../../../../modulos/Map/Map";
import { Graphome } from "../../../../modulos/GraficoHome/GraficoHome";
import "./style.css"
import { SettingsAPi } from "../../../../../api/SettingsAPI";
import { Auth } from "../../../../../helpers/auth";
import { Redirect } from "react-router";
const auth = new Auth();
const settingsapi = new SettingsAPi();

export class Tolvaupdate extends Component {

  constructor(props) {
      super(props);
      this.state = {
        Id_Caex:'',
        modelo:'',
        angulo_descarga:'',
        redirect: false,
        select_tipocaex:'',
        listaTipoCaex:new Array([]) //Previene error Unhandled Rejection (TypeError) new Array([])
      }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    const datos = {
      id:data.get('id'),
      Id_Caex: data.get('Id_Caex'),
      id_tipocaex: data.get('select_tipocaex'),
      angulo_descarga: data.get('angulo_descarga')
    };
    let datosjason = JSON.stringify(datos);
    settingsapi.UpdateTolvaID(datosjason).then(response => {
        alert ("Se han actualizado correctamente los datos.")
        this.setState({
          redirect: true
        });
    }).catch(err => {
        alert ("Ha ocurrido un error al actualizar los datos.")
    })
  }

  componentWillMount() {
    if (!this.refreshdata) {
      settingsapi.getSETTINGSTolvaID(this.props.id).then(response => {
         this.setState({
          Id_Caex: response[0].Id_Caex,
          modelo: response[0].modelo,
          angulo_descarga: response[0].angulo_descarga,
          select_tipocaex: response[0].id_tipocaex
        })
      })
      settingsapi.getReadTipoCaex().then(response => {
         this.setState({
          listaTipoCaex: response
        })
      })
    }
  }

  componentWillUnmount() {
      clearInterval(this.refreshdata)
  }

  componentDidMount(props) {
  }

  renderOptionTipoCaex(){
    return(
      this.state.listaTipoCaex.map((value, index) => {
          if(value.id==this.state.id_tipocaex){
            return (<option selected value={value.id}>{value.modelo}</option>)
          }else{
            return (<option value={value.id}>{value.modelo}</option>)
          };
        }
      )
    )
  }

  render() {

    if (this.state.redirect){
      return <Redirect to='/settings' />
    }

    return (
      <>
      <div class="user-detail">
        {auth.restrics('NOT-ANG')
          ?
          <div>
            <center><h2>Modify tray data</h2></center>
              <form onSubmit={this.handleSubmit} id="formTrucks">
                    <p><label>Id truck</label>
                    <input type="text" placeholder="Enter identifier" name="Id_Caex"  value={this.state.Id_Caex}  onChange={this.handleInputChange}   required  /></p>
                    <p><label>Model</label>
                      <select className="browser-default" value ={this.state.select_tipocaex} name="select_tipocaex" onChange={this.handleInputChange}>
                        <option value="" disabled selected>Choose your option</option>
                        {this.renderOptionTipoCaex()}
                      </select>
                    </p>
                    <input type="hidden" name="angulo_descarga" value="0" />
                    <input type="hidden" name="id" value={this.props.id} />
                    <center><input class="btn waves-effect waves-green" type="submit" value="Submit"  /></center>
              </form>
          </div>
          :
          <div>
            <center><h2>Modify tray data</h2></center>
              <form onSubmit={this.handleSubmit} id="formTrucks">
                    <p><label>Id truck</label>
                    <input type="text" placeholder="Enter identifier" name="Id_Caex"  value={this.state.Id_Caex}  onChange={this.handleInputChange}   required  /></p>
                    <p><label>Model</label>
                      <select className="browser-default" value ={this.state.select_tipocaex} name="select_tipocaex" onChange={this.handleInputChange}>
                        <option value="" disabled selected>Choose your option</option>
                        {this.renderOptionTipoCaex()}
                      </select>
                    </p>
                    <p><label>Angle</label>
                    <input type="text" placeholder="Enter angle" name="angulo_descarga"   value={this.state.angulo_descarga}  onChange={this.handleInputChange}  required /></p>
                    <input type="hidden" name="id" value={this.props.id} />
                    <center><input class="btn waves-effect waves-green" type="submit" value="Submit"  /></center>
              </form>
          </div>
        }


      </div>
      </>)
  }
}
