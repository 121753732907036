import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Board.css"
import { BarTitle }    from "../../../../../modulos/BarTitle/BarTitle";
import { GraphMel }    from "../../../../../modulos/GraphMel/GraphMel"
import { LogAlarms }   from "../../../../../modulos/LogAlarms/LogAlarms"
import { UnitAPi }     from "../../../../../../api/UnitAPI";

const unitapi = new UnitAPi();
export class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
        refreshdata: false,
        graph_series_1: [],
        grpah_series_2: [],
        graph_max: 0,
        series_1_name: "",
        series_2_name: "",
        graph_labels: [],
        title: ""
    }
  }

  componentWillMount() {
      if (!this.state.refreshdata) {
          this.updateData();
          this.state.refreshdata = setInterval(() => {
              this.updateData();
          }, 5000);
      }
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshdata)
  }

  updateData(date = null, interval = "h") {
    unitapi.getDataGraphByNodo(this.props.data.id_nodo).then((response) => {
      let aux_serie_1 = [];
      let aux_serie_2 = [];
      let aux_label = [];

      if (response.status) {

        for (const registro of response.data[0]) {
          aux_serie_1.push({
              x: new Date(registro.date).getTime(),
              y: registro.rms_acc
          })
          aux_serie_2.push({
              x: new Date(registro.date).getTime(),
              y: registro.rms_evento
          })

          //aux_label.push({registro.date})
        }
        this.setState({
            graph_series_1: aux_serie_1,
            graph_series_2: aux_serie_2,
            graph_max : 16,
            series_1_name: "RMS",
            series_2_name: "EventRMS",
            title: "RMS Level",
            graph_labels: aux_label
        });
      }
    }).catch((e) => { console.log("updateData : ", e) });
  }

  onClickbtnReturn(event) {
      if (this.props.btnreturn) {
          this.props.btnreturn(event)
      }
  }

  onClickbtnFleetReturn(event) {
      if (this.props.btnfleetreturn) {
          this.props.btnfleetreturn(event)
      }
  }

  onClickbtnUnitReturn(event) {
      if (this.props.btnunitreturn) {
          this.props.btnunitreturn(event)
      }
  }

  render() {
    return (<>
      <div className={"row"}>
        <div className={"row unitpayload-board_1"}>
          <div className={"col s12 unitpayload-BarTitle"}>
            <BarTitle
              onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }}
              onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
              onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
              btnReturn title={this.props.data.tolva_id_caex}
              subtitle={this.subtitle}
              pagSelect= "Unit"
            />
          </div>
        </div>
        <div className={"row"} style={{width:'100%',backgroundColor: '#1A2028'}} >
          <div className={"col s10 "}>
            <GraphMel
              title = {this.state.title}
              series_1 ={this.state.graph_series_1}
              series_2 ={this.state.graph_series_2}
              max_series = {this.state.graph_max}
              type_1 = 'area'
              type_2 = 'line'
              series_1_name = {this.state.series_1_name}
              series_2_name = {this.state.series_2_name}
              labels = {this.state.graph_labels}
            />
          </div>
          <div className={"col s2 "}>
            <LogAlarms />
          </div>
        </div>
      </div>
      </>
    );
  }
}
