import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();
export class SettingsAPi {

    contructor() {}

    getSETTINGS(){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settings`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    getSETTINGSArea(){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsareas`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    getSETTINGSTolva(){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingstolvas`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    UpdateSettings(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsupdate`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    UpdateNodoAreaSettings(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsnodoareaupdate`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    getSETTINGSAreaID(id){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsareaid/` + id, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    UpdateAreaID(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsupdateareaid`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    CreateArea(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingscreatearea`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    UpdateTolvaID(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsupdatetolvaid`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    CreateTolva(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingscreatetolva`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    getSETTINGSTolvaID(id){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingstolvaid/` + id, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    getDataNodoByFaena(id){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingsgetnodos/` + id, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    getReadTipoCaex(){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/settingstipocaex/`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    CommandSendTramaRt(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/commandsendtramart`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    CommandSendCiclo(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/commandsendciclo`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }

    CommandSendWifi(data){
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/commandsendwifi`, {
        method: "PUT",
        body: data,
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) =>  response.json())
    }
}
