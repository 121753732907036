import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoardGINGIN.css"
import { BarTitle }           from "../../../../../modulos/BarTitle/BarTitle";
import { SelectorDateGinGin } from "../../../../../modulos/SelectorDateGinGin/SelectorDateGinGin"
import { BoxTimeGINGIN }      from "../../../../../modulos/BoxTimeGINGIN/BoxTimeGINGIN"
import { BoxSpeed }           from "../../../../../modulos/BoxSpeed/BoxSpeed"
import { Donut }              from "../../../../../modulos/Donut/Donut"
import { Payload }            from "../../../../../modulos/Payload/Payload"
import { Cycle }              from "../../../../../modulos/Cycle/Cycle"
import { Monitor }            from "../../../../../modulos/Monitor/Monitor"
import { UnitAPi }            from "../../../../../../api/UnitAPI";
import DataTable, { createTheme } from "react-data-table-component";

const unitapi = new UnitAPi();

export class BoardGINGIN extends Component {

    columns = [
      {
          id: 'id',
          name: 'Cycle #',
          selector: row => row.posicion,
          sortable: true,
          center:true,
          width : '95px',
          style: {
              backgroundColor: '#323f4f',
              color: 'white',
          },
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
      {
          id: 'DateCycle',
          name: 'Date',
          width : '200px',
          selector: row => row.DateCycle,
          sortable: true,
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
      {
        id: 'StartCycle',
          name: 'Start Time',
          selector: row => row.StartCycle,
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
      {
        id: 'TimeCycle',
          name: 'End Time',
          selector: row => row.TimeCycle,
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
      {
        id: 'totaltimeGINGIN',
          name: 'Cycle Duration',
          selector: row => row.totaltimeGINGIN,
          format: row => this.FormatTimeTable( row.totaltimeGINGIN * 60),
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
      {
        id: 'Distance',
          name: 'Cycle Distance (km)',
          selector: row => row.Distance,
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
      {
        id: 'payload',
          name: 'Payload (tonnes)',
          selector: row => row.payload,
          format: row => (row.payload / 1000).toLocaleString('en'),
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.flag_A == 'OP',
              style: {
                color: 'lightgreen',
              },
            },
          ],
      },
    ];

    constructor(props) {
        super(props)
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.state = {
            monitor: false,
            dateSelected: date,
            intervalSelected: "h",
            data: false,
            refreshdata: false,
            tabletimedata: []
        }
        createTheme("darkk", {
            text: {
                primary: '#59677a',
                secondary: '#59677a',
            },
            background: {
                default: '#191f27',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#4d4a52',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        })
    }

    componentWillMount() {
        if (!this.state.refreshdata) {
          this.updateData();
          }
      }

    componentWillUnmount() {
        clearInterval(this.state.refreshdata)
    }

    updateData(date = null, interval = "d") {
      unitapi.getBoardByIdNodoGinGin(this.props.data.id_nodo, interval).then((response) => {
        if (response.status ) {
          if (response.status){
            this.setState({
              data: response.data,
            });
          }

          unitapi.getBoardTableCycleTimeGinGin(this.props.data.id_nodo, this.state.intervalSelected ).then(responseTable => {
            if (responseTable.status) {
              this.setState({ tabletimedata : responseTable.data })
            }
          })
        }
      });
    }

    onClickbtnReturn(event) {
        if (this.props.btnreturn) {
            this.props.btnreturn(event)
        }
    }

    onClickbtnFleetReturn(event) {
        if (this.props.btnfleetreturn) {
            this.props.btnfleetreturn(event)
        }
    }

    onClickbtnUnitReturn(event) {
        if (this.props.btnunitreturn) {
            this.props.btnunitreturn(event)
        }
    }

    onChangeValue(date, interval) {
        this.setState({
            dateSelected: date,
            intervalSelected: interval
        })
        this.updateData(date, interval);
    }

    FormatTimeTable(sec) {
        let hour: number = 0
        let minutes: number =  0
        let seconds: number = 0

        if(sec != null){
          hour = Math.trunc(sec / 3600)
          minutes = Math.trunc((sec - (hour * 3600))/60)
          seconds = Math.trunc(sec-((hour*3600)+(minutes*60)))
        }

        let hh = ''
        if (hour < 10 ) {
          hh = '0' + hour.toString();
        }else {
          hh = (hour).toLocaleString('en')
        }

        let mm = ''
        if (minutes < 10 ) {
          mm = '0' + minutes.toString()
        }else {
          mm = (minutes).toLocaleString('en')
        }

        let ss = ''
        if (seconds < 10 ) {
          ss = '0' + seconds.toString()
        }else {
          ss = (seconds).toLocaleString('en')
        }

        return hh + ":" + mm + ":" + ss
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))
          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }

        return (hour).toLocaleString('en') + "h " + minutes + "m"
    }



    render() {
        return (<>
          <div className={"row filaGinGin1"} >
            <BarTitle
              onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }}
              onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
              onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
              btnReturn title={this.props.data.tolva_id_caex}
              subtitle={this.subtitle}
              pagSelect= "Unit"
            />
          </div>
          <div className={"row filaGinGin2"} >
            <div className={"col colGinGinIzq2"} >
              <div className={"row"} >
                <div className={"col"}>
                  <div className={"row"} ><SelectorDateGinGin onChange={(d, i) => { this.onChangeValue(d, i) }} />
                    <BoxTimeGINGIN
                      Payload={this.state.data.total_payload?Math.round(this.state.data.total_payload/1000):"0"}
                      CycleNumber = {this.state.data.Cycles}
                      AvgCycleTime = {this.MinuteDecimalToString(this.state.data.avg_cycleTime)}
                      OperationTime = {this.MinuteDecimalToString(this.state.data.time_runing_GINGIN)}
                      TruckshopTime = {this.MinuteDecimalToString(this.state.data.time_truckshop_GINGIN)}
                      ParkingTime = {this.MinuteDecimalToString(this.state.data.parking_time)}
                      IdleTime ={this.MinuteDecimalToString(this.state.data.idle_time)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={"col colGinGinDer2"} >
              <div className={"row"} >
                <Donut title="Occupancy (%)"
                  series={[
                    (this.state.data.availability_GINGIN ? Math.round(this.state.data.availability_GINGIN) : 0),
                    (this.state.data.availability_truckshop_GINGIN ? Math.round(this.state.data.availability_truckshop_GINGIN) : 0),
                    (this.state.data.availability_parking_GINGIN ? Math.round(this.state.data.availability_parking_GINGIN) : 0)
                  ]}
                  labels={["Operation", "Truckshop", "Parking"]} />
              </div>
            </div>
          </div>
            <div className={"row board-row"}>
                <div className={"col s12 gbox-componente-down"}>
                    <div className={"row "}>
                        <div className={"col s12 TimeonEach"}>
                            Time on each stage (hh:mm:ss)
                        </div>
                        <div className={"col s12 table-datatable"}>
                          <DataTable
                            pagination
                            paginationPerPage="20"
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            theme="darkk"
                            noHeader
                              columns={this.columns}
                                data={this.state.tabletimedata}
                                customStyles={{
                                    rows: {
                                        style: {
                                          backgroundColor: '#1A2028',
                                          fontSize: '18px',
                                          color: 'white',
                                            minHeight: '30px', // override the row height
                                            '&:not(:last-of-type)': {
                                                borderBottomStyle: 'solid',
                                                borderBottomWidth: '1px',
                                                borderBottomColor: 'black',
                                            },
                                        }
                                    },

                                    pagination: {
                                      style: {
                                        color: '#8497B0',
                                        fontSize: '13px',
                                        minHeight: '56px',
                                        backgroundColor: '#1A2028',
                                        borderTopStyle: 'solid',
                                        borderTopWidth: '1px',
                                        borderTopColor: '#8497B0',
                                      },
                                      pageButtonsStyle: {
                                        borderRadius: '50%',
                                        height: '40px',
                                        width: '40px',
                                        padding: '8px',
                                        margin: 'px',
                                        cursor: 'pointer',
                                        transition: '0.4s',
                                        color: '#8497B0',
                                        fill: '#8497B0',
                                        backgroundColor: 'transparent',
                                        '&:disabled': {
                                          cursor: 'unset',
                                          color: '#8497B0',
                                          fill: '#8497B0',
                                        },
                                        '&:hover:not(:disabled)': {
                                          backgroundColor: '#FFFFFF',
                                        },
                                        '&:focus': {
                                          outline: 'none',
                                          backgroundColor: '#FFFFFF',
                                        },
                                      },
                                    },
                                    headRow: {
                                      style: {
                                        backgroundColor: '#8497B0',
                                        minHeight: '30px',
                                        borderBottomWidth: '2px',
                                        borderBottomColor: 'black',
                                        borderBottomStyle: 'solid',
                                      },
                                      denseStyle: {
                                        minHeight: '32px',
                                      },
                                    },
                                    headCells: {
                                        style: {
                                            fontSize: '16px',
                                            color: 'white',
                                            backgroundColor:'#323f4f',
                                            paddingLeft: '0px', // override the cell padding for head cells
                                            paddingRight: '0px',
                                            borderRightStyle: 'solid',
				                                    borderRightWidth: '2px',
				                                    borderRightColor: 'black',
                                        },
                                        activeSortStyle: {
                                            color: 'gray',
                                            '&:focus': {
                                              outline: 'none',
                                            },
                                            '&:hover:not(:focus)': {
                                              color: 'gray',
                                            },
                                        },
                                        inactiveSortStyle: {
                                            '&:focus': {
                                              outline: 'none',
                                              color: 'green',
                                            },
                                            '&:hover': {
                                              color: 'gray',
                                            },
                                        },
                                    },
                                    cells: {
                                        style: {
                                            paddingLeft: '0px', // override the cell padding for data cells
                                            paddingRight: '8px',
                                            borderRightStyle: 'solid',
				                                    borderRightWidth: '2px',
				                                    borderRightColor: 'black',
                                            borderBottomStyle: 'solid',
                                            borderBottomWidth: '1px',
                                            borderBottomColor: 'black',
                                        },
                                    },
                                    table: {
                                        style: {
                                            minHeight: '600px',
                                            maxHeight: '630px'
                                        }
                                    }, noData: {
                                        style: {
                                            minHeight: '600px',
                                            maxHeight: '600px'
                                        }
                                    }
                                }}
                          />
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
