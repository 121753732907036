import env from "react-dotenv";
import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';
import truck from "../../../assets/map/truck.png";
import pointImg from "../../../assets/map/point.png";
import "./Map.css";
import { Auth } from "../../../helpers/auth";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const statickDefaultProps = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    zoom: 11
}
const auth = new Auth();
export class Map extends Component {
    state = {
        data: [],
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 0,
        trucksCoords: []

    }
    color = ["#bebe4b", "#89bc6d", "#65b38c", "#009cf0", "#d100b9", "#80a951", "#73bf9a", "#98cdcb", "#5eb1ee", "#c76fce", "#b9cf68", "#88d68a", "#54d9b3", "#0099a3", "#275a72", "#27c4a4",
        "#009ff3", "#7445e3", "#0072ad", "#2d6249", "#c0ac16", "#9ac06f", "#9dc8ae"]
    colorLine = ["#89bc6d","#2986cc",  "#65b38c", "#009cf0", "#d100b9", "#80a951", "#73bf9a", "#98cdcb", "#5eb1ee", "#c76fce", "#b9cf68", "#88d68a", "#54d9b3", "#0099a3", "#275a72", "#27c4a4",
            "#009ff3", "#7445e3", "#0072ad", "#2d6249", "#c0ac16", "#9ac06f", "#9dc8ae"]
    colorArea = ["#ffa43a", "#759eff", "#ff7360", "#ffff51", "#ff81ff", "#DC136C", "#12355B", "#4F9DA6", "#1A0841", "#5AA469", "#5AA469"]
    getMapOptions = {
        mapTypeId: 'satellite'

    }
    map;
    maps;
    areasCargadas = false;
    nameAreasCargadas = false;
    constructor(props) {
        super(props)
        this.updateDataTrucks()
        let data = auth.getData()
        //console.log("data : ", data);
        let areas = auth.getAreas()
        if (data) {
            this.state = {
                data: [],
                center: {
                    lat: parseFloat(data.faena_map_lat),
                    lng: parseFloat(data.faena_map_lng)
                },
                zoom: parseInt(data.faena_map_zoom),
                trucksCoords: this.reouteget(),
                areas: areas,
                view_pointmap: parseInt(data.faena_view_pointmap),
                view_qpointmap: parseInt(data.faena_view_qpointmap),
            }
        }
    }

    updateDataTrucks() {
        if (this.props.trucks != this.state.data) {
            this.setState({
                data: this.props.trucks,
                trucksCoords: this.reouteget()
            })
        }
    }

    componentDidUpdate() {
        this.updateDataTrucks()
    }

    point() {
        if (this.props.trucks) {
            if (this.props.route) {
                this.reouteget()
                let c = 0;
                let point = [];

                for (const truck of this.props.trucks) {
                  if((truck.trama_latitud!=null)&&(truck.trama_longitud!=null)){
                    let varlat =  truck.trama_latitud.substring(0, truck.trama_latitud.length - 1);
                    let varlon =  truck.trama_longitud.substring(0, truck.trama_longitud.length - 1);
                    const trama_latitud = varlat.split(",")
                    const trama_longitud = varlon.split(",")
                      c++;
                      if (c >= this.color.length) c = 0;
                      for (const lati in trama_latitud) {
                          if (!isNaN(trama_latitud[lati]) && !isNaN(trama_longitud[lati])) {
                              point.push(<div
                                  lat={trama_latitud[lati]}
                                  lng={trama_longitud[lati]}>
                                  <FiberManualRecordIcon style={{ color: this.color[c] }} />
                              </div>
                              )
                          }
                      }
                      var ct = 0;
                      return this.props.trucks.map((value, index) => {
                          ct++;
                          if(value.trama_latitud != null && value.trama_longitud != null ){
                            let latlist =  value.trama_latitud.substring(0, value.trama_latitud.length - 1);
                            let lanlist =  value.trama_longitud.substring(0, value.trama_longitud.length - 1);
                            const point_latitud = latlist.split(",")
                            const point_longitud = lanlist.split(",")
                            if (ct >= this.color.length) ct = 0;
                            return <div
                                lat={value.trama_ultima_latitud}
                                lng={value.trama_ultima_longitud}>
                                <span style={{ background: this.color[ct] }} className={"map-truck-text"}>{value.tolva_id_caex}</span>
                                <img className={"map-truck"} src={"truck.png"} alt={"Background app"} />
                            </div>
                          }
                      })
                  }
                }
                if (point) {
                    //return point
                }
            } else {
                let c = 0;
                return this.props.trucks.map((value, index) => {
                    c++;
                    console.log(value.trama_latitud);
                    console.log(value.trama_longitud);
                    if (c >= this.color.length) c = 0;
                    return <div
                        lat={value.trama_latitud}
                        lng={value.trama_longitud}>
                        <span style={{ background: this.color[c] }} className={"map-truck-text"}>{value.tolva_id_caex}</span>
                        <img className={"map-truck"} src={truck} alt={"Background app"} />
                    </div>
                })
            }
        }
        return null
    }

    nameAreasMap() {
         if (!this.nameAreasCargadas && this.maps) {
             let point = [];
             for (const area of this.state.areas) {
                 point.push(<div
                     lat={area.latitud}
                     lng={area.longitud}
                     options={{ scaledSize: new this.maps.Size(1, 1) }}
                     className={"bigmap-name-area"}>
                     <span
                         options={{ scaledSize: new this.maps.Size(1, 1) }}
                         className={"bigmap-name-area-span"}>{area.tipo_area}</span>
                 </div>
                 );
             }
             if (point) {
                 return point
             }
             this.nameAreasCargadas = true;
         }
    }

    areasMap() {
        if (!this.areasCargadas && this.state.areas && this.maps) {
            let c = 0;
            for (const area of this.state.areas) {
                c++;
                const coord = [];
                if (c >= this.colorArea.length) c = 0;
                const cityCircle = new this.maps.Circle({
                    //strokeColor: this.colorArea[c],
                    strokeColor: "#FFFFFF",
                    //strokeOpacity: 1.2,
                    strokeWeight: 0.7,
                    fillColor: this.colorArea[c],
                    fillOpacity: 0.4,
                    map: this.map,
                    center: { lat: area.latitud, lng: area.longitud },
                    radius: area.diametro / 2
                });
                cityCircle.setMap(this.map);

            }
            this.areasCargadas = true;
        }
    }

    reouteget() {

        if (this.props.trucks) {
            if (this.props.route) {
                let c = 0;
                for (const truck of this.props.trucks) {
                    if((truck.trama_latitud!=null)&&(truck.trama_longitud!=null)){
                      let varlat =  truck.trama_latitud.substring(0, truck.trama_latitud.length - 1);
                      let varlon =  truck.trama_longitud.substring(0, truck.trama_longitud.length - 1);
                      const trama_latitud = varlat.split(",")
                      const trama_longitud = varlon.split(",")
                      c++;
                      const coord = [];
                      if (c >= this.color.length) c = 0;
                      let cix = 0;
                      for (const lati in trama_latitud) {
                          if (!isNaN(trama_latitud[lati]) && !isNaN(trama_longitud[lati]) && trama_latitud[lati] !='' && trama_longitud[lati]!='' ) {
                              cix++;
                              coord.push(
                                  { lat: parseFloat(trama_latitud[lati]), lng: parseFloat(trama_longitud[lati]) }
                              )
                          }
                      }

                      if (this.maps) {
                          const lineSymbol = { path: this.maps.SymbolPath.FORWARD_CLOSED_ARROW,};
                          const flightPath = new this.maps.Polyline({
                              path: coord,
                              geodesic: true,
                              strokeColor: this.colorLine[c],
                              strokeOpacity: 1.0,
                              strokeWeight: 2,
                              icons: [
                                {
                                  icon: lineSymbol,
                                  offset: "100%",
                                  repeat: "15%"
                                },
                              ],
                          });
                          flightPath.setMap(this.map);
                          for (const lati in trama_latitud) {
                              if (!isNaN(trama_latitud[lati]) && !isNaN(trama_longitud[lati]) && trama_latitud[lati] !='' && trama_longitud[lati]!='' ) {
                                  const image = "\point.png";
                                  var marker = new this.maps.Marker({
                                    position:{ lat: parseFloat(trama_latitud[lati]), lng: parseFloat(trama_longitud[lati]) },
                                    map: this.map,
                                    icon: image,
                                    title: truck.tolva_id_caex
                                });
                              }
                          }
                      }
                    }
                }
            }
        }
    }

    handleApiLoaded(map, maps) {
        this.map = map;
        this.maps = maps;
        this.areasMap();
    }

    render() {
        return (
            <div style={{ height: '49vh', width: '100%' }}>
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals={true}
                    options={this.getMapOptions}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_KEY }}
                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}

                    center={this.state.center}
                    zoom={this.state.zoom}
                    defaultCenter={statickDefaultProps.center}
                    defaultZoom={statickDefaultProps.zoom}
                >
                    {this.nameAreasMap()}
                    {this.point()}
                </GoogleMapReact>

            </div>
        );
    }
};
