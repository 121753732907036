import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoxSpeed.css"

export class BoxSpeed extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<>
            <div className={"row box-speed"}>
                <div className={"col s6 box-speed-value"}>
                    <div className={"col s12 offset box-speed-title"}>Speed</div>
                    <div className={"col s12"}>
                        <div className={"row"}>
                            <div className={"col s6 box-speed-info"}>
                                <div className={"row box-speed-info-row"}>
                                    <div className={"col s12 box-speed-info-value"}>{this.props.averageEmptySpeed}</div>
                                    <div className={"col s12 box-speed-info-title"}>Average empty speed</div>
                                </div>
                            </div>
                            <div className={"col s6 box-speed-info"}>
                                <div className={"row box-speed-info-row"}>
                                    <div className={"col s12 box-speed-info-value"}>{this.props.averageLoadedSpeed}</div>
                                    <div className={"col s12 box-speed-info-title"}>Average loaded speed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col s6 box-speed-alert"}>
                    <div className={"row  box-speed-alert-row"}>
                        <div className={"col s12 box-speed-alert-value"}>{this.props.speedingAlerts}</div>
                        <div className={"col s12 box-speed-alert-title"}>Speeding alerts</div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
