import React, { Component }       from "react";
import "./Alarms.css";
import DataTable, { createTheme } from "react-data-table-component";
import M                          from "materialize-css";
import { BarTitleAlarms }         from "../../../modulos/BarTitleAlarms/BarTitleAlarms";
import { AlarmsApi }              from "../../../../api/AlarmsApi";
import { Auth }                   from "../../../../helpers/auth";

const auth = new Auth();
const alarmsApi = new AlarmsApi();

export class Alarms extends Component {
  columns = [
      {
          name: 'Id',
          selector: 'Id',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.Id_CAEX,
          sortable: true,
          center:true,
      },
      {
          name: 'Date',
          selector: 'date',
          sortable: true,
          center:true
      },
      {
          name: 'Speed (km/h)',
          selector: row => row.speed,
          sortable: true,
          center:true
      },
      {
          name: 'Description',
          selector: row => row.Nombre_alarm,
          sortable: true,
          center:true
      },
  ];

  constructor(props) {
      super(props);
      this.state = {
        data: new Array([])
      }
      createTheme("darkk", {
          text: {
              primary: '#59677a',
              secondary: '#59677a',
          },
          background: {
              default: '#191f27',
          },
          context: {
              background: '#cb4b16',
              text: '#FFFFFF',
          },
          divider: {
              default: '#4d4a52',
          },
          action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
          },
      })
  }

  componentWillMount() {
      if (!this.state.refreshdata) {
          this.updateData();
          this.state.refreshdata = setInterval(() => {
              this.updateData();
          }, 15000);
      }
  }


  componentWillUnmount() {
    clearInterval(this.state.refreshdata)
  }

  updateData() {
    alarmsApi.getTableAlarms().then((response) => {
      if (response.status) {
        console.log("response : ", response)
        this.setState({ data: response.data[0]});
      }
    }).catch((e) => { console.log("updateData : ", e) });
  }

  render() {
    return (
      <>
      <div className={"col s12 fleet-title"}>
        <BarTitleAlarms
          onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
          onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
          title={"FLEET ALARMS"}
          subtitle={this.subtitle}
          pagSelect = "Fleet"
        />
      </div>
        <div className={"table-component"}>
          <div className={"row table-component-row"}>
            <div className={"col s12 table-datatable"}>
              <DataTable
                  pagination
                  paginationPerPage="20"
                  theme="darkk"
                  columns={this.columns}
                  data={this.state.data}
                  selectableRows
                  onRowClicked={(e) => { this.datatableSelect(e) }}
                  pointerOnHover
                  paginationComponentOptions={{ noRowsPerPage: true }}
                  noHeader
                  highlightOnHover
                  customStyles={{
                      rows: {
                          style: {
                            backgroundColor: '#1A2028',
                            fontSize: '18px',
                            color: 'white',
                              minHeight: '30px', // override the row height
                              '&:not(:last-of-type)': {
                                  borderBottomStyle: 'solid',
                                  borderBottomWidth: '1px',
                                  borderBottomColor: '#8497B0',
                              },
                          }
                      },

                      pagination: {
                        style: {
                          color: '#8497B0',
                          fontSize: '13px',
                          minHeight: '56px',
                          backgroundColor: '#1A2028',
                          borderTopStyle: 'solid',
                          borderTopWidth: '1px',
                          borderTopColor: '#8497B0',
                        },
                        pageButtonsStyle: {
                          borderRadius: '50%',
                          height: '40px',
                          width: '40px',
                          padding: '8px',
                          margin: 'px',
                          cursor: 'pointer',
                          transition: '0.4s',
                          color: '#8497B0',
                          fill: '#8497B0',
                          backgroundColor: 'transparent',
                          '&:disabled': {
                            cursor: 'unset',
                            color: '#8497B0',
                            fill: '#8497B0',
                          },
                          '&:hover:not(:disabled)': {
                            backgroundColor: '#FFFFFF',
                          },
                          '&:focus': {
                            outline: 'none',
                            backgroundColor: '#FFFFFF',
                          },
                        },
                      },
                      headRow: {
                        style: {
                          backgroundColor: '#8497B0',
                          minHeight: '30px',
                          borderBottomWidth: '1px',
                          borderBottomColor: '#8497B0',
                          borderBottomStyle: 'solid',
                        },
                        denseStyle: {
                          minHeight: '32px',
                        },
                      },
                      headCells: {
                          style: {
                              fontSize: '16px',
                              color: 'white',
                              backgroundColor:'#191f27',
                              paddingLeft: '10px', // override the cell padding for head cells
                              paddingRight: '2px',
                          },
                          activeSortStyle: {
                              color: 'gray',
                              '&:focus': {
                                outline: 'none',
                              },
                              '&:hover:not(:focus)': {
                                color: 'gray',
                              },
                          },
                          inactiveSortStyle: {
                              '&:focus': {
                                outline: 'none',
                                color: 'green',
                              },
                              '&:hover': {
                                color: 'gray',
                              },
                          },
                      },
                      cells: {
                          style: {
                              paddingLeft: '8px', // override the cell padding for data cells
                              paddingRight: '8px',
                          },
                      },
                      table: {
                          style: {
                              minHeight: '600px',
                              maxHeight: '600px'
                          }
                      }, noData: {
                          style: {
                              minHeight: '600px',
                              maxHeight: '600px'
                          }
                      }
                  }}
              />
            </div>
          </div>
        </div>
      </>

    );
  };

}
