import React, { Component } from "react";
import "./Fleet.css"
import { GraphMultiLine } from "../../../modulos/GraphMultiLine/GraphMultiLine";
import { GraphBar } from "../../../modulos/GraphBar/GraphBar";
import { Availability } from "../../../modulos/Availability/Availability";
import { BoxTime } from "../../../modulos/BoxTime/BoxTime";
import { BarTitle } from "../../../modulos/BarTitle/BarTitle";
import { SelectorDate } from "../../../modulos/SelectorDate/SelectorDate";
import { FleetAPi } from "../../../../api/FleetApi";

const fleetAPi = new FleetAPi();

export class Fleet extends Component {
    subtitle = ["Efficiency", "Payload", "Availability", "Speed"]
    databarCycleTitleStatus = ["?", "Queue to load", "Loading", "?", "Return", "?", "Queue To dump", "Dumping", "?", "To load"]
    data = [{
        name: "truck1",
        data: [{ x: 1, y: "2" }, { x: 2, y: 4 }, { x: 3, y: 8 }, { x: 4, y: 4 }]
    }, {
        name: "truck2",
        data: [{ x: 1, y: 4 }, { x: 2, y: 3 }, { x: 3, y: 2 }, { x: 4, y: 8 }]
    }];
    databar = [{
        name: "hour1",
        data: [{ x: "truck1", y: 3 }, { x: "truck2", y: 4 }, { x: "truck3", y: 8 }, { x: "truck4", y: 4 }]
    }, {
        name: "hour2",
        data: [{ x: "truck1", y: 4 }, { x: "truck2", y: 3 }, { x: "truck3", y: 2 }, { x: "truck4", y: 8 }]
    }];
    constructor(props) {
        super(props);
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.state = {
            data: false,
            intervalSelected: "h",
            dataTruckPosition: [],
            dateSelected: date,
            view: 1,
            selectedRow: {},
            truckRoute: [],
			runingTime: 0,
            queuingtoload: 0,
            queuingtodump: 0,
            fuellingstop: 0,
            efficiency: 0
        }
    }

    componentWillMount() {
        this.updateData()
    }


    updateData(date = null, interval = "h") {
        fleetAPi.getData(date || this.state.dateSelected, interval || this.state.intervalSelected).then((response) => {
            console.log(date)
            if (response.status) {
                let trucksum = {}
                let availability = []
                let cycle = [[], [], [], [], [], [], [], [], [], [], [], []];
				let runingTimeAux = 0;
                let queuingtoloadAux = 0;
                let queuingtodumpAux = 0;
                let fuellingstopAux = 0;
                let efficiencyAux = 0;
                let countCycles = 0;
                let count = 0;

                response.data["maxcycle"] = 0;
                let valcycle = 0;
                for (const truck of response.data.trucks) {
                    trucksum["Cycles"] = truck.Cycles;
                    trucksum["avg_tt"] = truck.avg_tt;
                    trucksum["avg_payload"] = truck.avg_payload;
                    trucksum["time_load"] = truck.time_load;
                    trucksum["time_runing"] = truck.time_runing;
                    trucksum["time_dump"] = truck.time_dump;
                    trucksum["time_service"] = truck.time_service;
                    trucksum["availability"] = truck.availability;
                    trucksum["Efficiency"] = truck.Efficiency;

                    count++;
                    countCycles += truck.Cycles;
                    queuingtodumpAux += truck.time_dump;
                    runingTimeAux += truck.time_runing;
                    queuingtoloadAux += truck.time_load;
                    fuellingstopAux += truck.time_service;
                    efficiencyAux += truck.Efficiency * truck.Cycles;
                    availability.push({
                        name: truck.Tipo_CAEX,
                        value: [truck.availability, 100 - truck.availability]
                    })
                    for (let i in cycle) {
                        valcycle = (Math.trunc(truck["dt" + (parseInt(i) + 1)] * 100) / 100);
                        cycle[i].push({
                            x: truck.Tipo_CAEX,
                            y: valcycle
                        })

                        if (valcycle > response.data.maxcycle) {
                            response.data.maxcycle = valcycle;
                        }
                    }
                }

                let efficiencyAVG = 0;
                //console.log("countCycles :", countCycles, " efficiencyAux: ", efficiencyAux );
                if (count > 0) { efficiencyAVG = Math.round(efficiencyAux / countCycles ) }
                this.setState({
                  runingTime: runingTimeAux,
                  queuingtoload: queuingtoloadAux,
                  queuingtodump: queuingtodumpAux,
                  fuellingstop: fuellingstopAux,
                  efficiency: efficiencyAVG
                });
                response.data["trucksum"] = trucksum;
                response.data["availability"] = availability;
                response.data["cycle"] = [];
                for (let i in cycle) {

                    if ((parseInt(i) + 1) == 2 || parseInt(i) + 1 == 3 || parseInt(i) + 1 == 5 || parseInt(i) + 1 == 7 || parseInt(i) + 1 == 8 || parseInt(i) + 1 == 10) {
                        response.data["cycle"].push({
                            name: this.databarCycleTitleStatus[i],
                            data: cycle[i]
                        });
                    }
                }

                let trucks = [];
                let areasLoad = [];
                let areasDump = [];
                let areasPayload = [];
                let data = {};
                let maxpayload = 0;
                for (const g of response.data.graphline) {
                    if (!data[g.type]) data[g.type] = {}
                    if (!data[g.type][g.area]) data[g.type][g.area] = {}
                    data[g.type][g.area][g.Tipo_CAEX] = Math.round(g.value * 100) / 100
                    if (trucks.indexOf(g.Tipo_CAEX) < 0) trucks.push(g.Tipo_CAEX)
                    if (g.type == "load") areasLoad.push(g.area)
                    if (g.type == "dump") areasDump.push(g.area)
                    if (g.type == "payload") {
                        if (g.value > maxpayload) {
                            maxpayload = g.value;
                        }
                        areasPayload.push(g.area)
                    }
                }
                for (const type in data) {
                    for (const area in data[type]) {
                        for (const truck of trucks) {
                            if (!data[type][area][truck])
                                data[type][area][truck] = 0
                        }
                    }
                }

                let graphloadn = {}
                let graphdumpn = {}
                let graphpayloadn = {}
                for (const truck of trucks) {
                    for (const type in data) {

                        if (type == "load") {
                            for (const area of areasLoad.sort()) {
                                if (Object.keys(data[type]).indexOf(area) >= 0) {

                                    if (!graphloadn[truck]) graphloadn[truck] = []
                                    graphloadn[truck].push(data[type][area][truck])

                                }
                            }
                        } else if (type == "dump") {
                            for (const area of areasDump.sort()) {
                                if (Object.keys(data[type]).indexOf(area) >= 0) {
                                    if (!graphdumpn[truck]) graphdumpn[truck] = []
                                    graphdumpn[truck].push(data[type][area][truck])
                                }
                            }
                        } else if (type == "payload") {
                            for (const area of areasPayload.sort()) {
                                if (Object.keys(data[type]).indexOf(area) >= 0) {
                                    if (!graphpayloadn[truck]) graphpayloadn[truck] = []
                                    graphpayloadn[truck].push(Math.round(data[type][area][truck]))
                                }
                            }
                        }

                    }
                }

                response.data["trucksum"]["count"] = trucks.length;


                response.data["graphdump"] = []
                for (let len of Object.keys(graphdumpn).sort()) {
                    response.data.graphdump.push({
                        name: len,
                        data: graphdumpn[len]
                    })
                }

                response.data["graphload"] = []
                for (let len of Object.keys(graphloadn).sort()) {
                    response.data.graphload.push({
                        name: len,
                        data: graphloadn[len]
                    })
                }
                response.data["grappayload"] = []
                for (let len of Object.keys(graphpayloadn).sort()) {
                    response.data.grappayload.push({
                        name: len,
                        data: graphpayloadn[len]
                    })
                }
                response.data["maxpayload"] = maxpayload
                response.data["graphareasload"] = areasLoad.sort()
                response.data["graphareasdump"] = areasDump.sort()
                response.data["graphareasPayload"] = areasPayload.sort()
                this.setState({ data: response.data });
                console.log(response.data)
            }
        });
    }



    datatableSelect(data) {
        this.setState({ view: 2, selectedRow: data })
    }

    onChangeSelectValue(date, interval) {

        this.setState({ dateSelected: date })
        this.updateData(date, interval);
    }

    boardreturn(e) {

        this.setState({ view: 1 })
    }

    onChangeValueDate(date, interval) {

        this.setState({
            dateSelected: date,
            intervalSelected: interval
        })
        //  console.log(interval)
        this.updateData(date, interval);
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))

          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }
        //console.log(" MinuteDecimalToString : " + minuted +"--" + hour + ":" + minutes + "h");
        return hour + ":" + minutes + "h"
    }

    render() {
        return <div className={"row fleet"}>
            <div className={"col s12 fleet-title"}>
                <BarTitle title={"FLEET"} subtitle={this.subtitle} />
            </div>
            <div className={"col s6 fleet-iz"}>
                <div className={"row fleet-iz-row"}>
                    <div className={"col s12 fleet-margin-bottom fleet-selectordate"}>
                        <SelectorDate onChange={(d, i) => { this.onChangeValueDate(d, i) }} />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-boxtime"}>
                        <BoxTime
                          efficiency={ this.state.efficiency + " %" }
                          runingTime={this.MinuteDecimalToString(this.state.runingTime)}
                          queuingToLoad={this.MinuteDecimalToString(this.state.queuingtoload)}
                          queuingToDump={this.MinuteDecimalToString(this.state.queuingtodump)}
                          fuelingstop={this.MinuteDecimalToString(this.state.fuellingstop)}
                        />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-graphMultiLine-load"}>
                        <GraphMultiLine
                          backgounrd="#2b1872"
                          title="Queue to load (avg.)"
                          subTitle="Loading zone"
                          categories={(this.state.data.graphareasload ? this.state.data.graphareasload : [])}
                          series={(this.state.data.graphload ? this.state.data.graphload : [])}
                        />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-graphMultiLine-dump"}>
                        <GraphMultiLine
                          title="Queue to dump (avg.)"
                          subTitle="Dumping zone"
                          categories={(this.state.data.graphareasdump ? this.state.data.graphareasdump : [])}
                          series={(this.state.data.graphdump ? this.state.data.graphdump : [])}
                        />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-raphBar"}>
                        <GraphBar
                          titleH="Zone"
                          legendMargin={1}
                          title2="Average payload"
                          max={this.state.data.maxpayload}
                          orientationLabel="vertical"
                          positionLabel="top"
                          categories={(this.state.data.graphareasPayload ? this.state.data.graphareasPayload : [])}
                          series={(this.state.data.grappayload ? this.state.data.grappayload : [])}
                        />
                    </div>
                </div>
            </div>
            <div className={"col s6 fleet-der"}>
                <div className={"row fleet-der-row"}>
                    <div className={"col s12 fleet-margin-bottom fleet-availability"}>
                        <Availability series={this.state.data.availability} average={(this.state.data ? Math.round(this.state.data.trucksum.availability) + " %" : "0 %")} trucks={(this.state.data ? Math.round(this.state.data.trucksum.count) : "0")} />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-cycle-title"}>
                        Total cycle time
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-cycle"}>
                        <GraphBar backgounrd="#101d30" legendMargin={20} legendOffsetY={20} max={this.state.data.maxcycle} stacked={true} legndPosition="right" title="Time" subTitle="Truck type/recorrido type" height="309%" series={(this.state.data.cycle ? this.state.data.cycle : [])} />
                    </div>
                </div>
            </div>
        </div>
    }
};
