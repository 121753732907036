import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BarTitleRtmpicsList.css"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export class BarTitleRtmpicsList extends Component {
    constructor(props) {
        super(props)
    }

    onClickBtn(e) {
        if (this.props.onClickbtnReturn) {
            this.props.onClickbtnReturn(e)
        }
    }

    componentWillMount() {
      //console.log("this.props.pagSelect : " , this.props.pagSelect);
   }

    render() {
        return (<>
            <div className={"row bar"}>
                {(
                    this.props.btnReturn ?
                    <div className={"col s1 offset bar-button"}>
                      <a className={"main-item white-text btn bar-button-a"}
                        onClick={(e) => { this.onClickBtn(e) }}>
                        <i className={"bar-button-a-i material-icons  white-text"}>
                          <ArrowBackIosIcon />
                        </i>
                      </a>
                    </div>
                    :<div className={"col s1 offset bar-button"}><a className={"main-item white-text "}></a></div>
                  )
                }
                <div className={"col s1 bar-title"}>{this.props.bartitle}</div>
                <div className={"col s1 bar-title"}>{this.props.subtitle}</div>
          </div>
        </>
        )
    }
}
