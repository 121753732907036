import React, { Component, isValidElement } from "react";
import Chart from "react-apexcharts";
import "./BarMonitor.css";

export class BarMonitor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      series: [{
        name: 'Inpact',
        data: [props.value.trama_value_inpact_1, props.value.trama_value_inpact_2, props.value.trama_value_inpact_3, props.value.trama_value_inpact_4]
      }, {
        name: 'Limit',
        data: [16 - props.value.trama_value_inpact_2, 16 - props.value.trama_value_inpact_2, 16 - props.value.trama_value_inpact_3, 16 - props.value.trama_value_inpact_4]
      }],
      options: {
        plotOptions: {
          bar: {
            columnWidth: '40%'
          }
        },
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },

        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            if (w.config.series[seriesIndex].name == "Limit") {
              return ""
            } else {
              return Math.floor(w.config.series[seriesIndex].data[dataPointIndex])
            }
          }
        },
        responsive: [{
          breakpoint: 10,
          options: {
            legend: {
              position: 'top',
              offsetX: 100,
              offsetY: 0
            }
          }
        }],
        xaxis: {
          categories: ['Imp 1', 'Imp 2', 'Imp 3', 'Imp 4'],
        },
        yaxis: {
          show: false
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          position: 'top',
          offsetX: 0,
          offsetY: 10
        }, tooltip: {
          enabled: false
        }
      },
    };
  }

  renderGrafico(){
    //console.log("this.state.series[0].data[0]", this.state.series[0].data[0])
    //console.log("this.props.value.trama_value_inpact_1", this.props.value.trama_value_inpact_1)
    if(this.state.series[0].data[0] == undefined && this.props.value.trama_value_inpact_1 != undefined){
      this.setState({
        series: [{
          name: 'Inpact',
          data: [this.props.value.trama_value_inpact_1, this.props.value.trama_value_inpact_2, this.props.value.trama_value_inpact_3, this.props.value.trama_value_inpact_4]
        }, {
          name: 'Limit',
          data: [16 - this.props.value.trama_value_inpact_2, 16 - this.props.value.trama_value_inpact_2, 16 - this.props.value.trama_value_inpact_3, 16 - this.props.value.trama_value_inpact_4]
        }]
      })
    }



    if(
        this.state.series[0].data[0] != undefined ){
          //console.log(this.state.series);
      return(<Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="100%"
        />);
    }else {
      return (
        <div className={"row"}>
          <div className={"col"}>
            <div className={"no-data-chart"} >No data impact.</div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <div className={"mixed-chart"}>
        {this.renderGrafico()}
        </div>
      </>
    );
  }
}
