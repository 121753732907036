import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();

export class CycleAnalyticsAPi {

  contructor() {

  }

  getBox() {

      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/cycleanalytics/box`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())

  }

  getBoxGinGin() {

      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/cycleanalytics/boxgingin`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())

  }

  getTable(id_nodo) {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/cycleanalytics/table/` + id_nodo, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }

  getTableGinGin(id_nodo) {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/cycleanalytics/tablegingin/` + id_nodo, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }

}
