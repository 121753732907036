import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoxCycleAnalyticsGinGin.css"

export class BoxCycleAnalyticsGinGin extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (<>
          <div class="row cycle-analytics-g">
            <div class="col s1 trucks-in-operation-g">
              <div className={" col s12 offset box-time-info-number-b-g"}>
                <span>{this.props.TruckInOperation}</span>
              </div>
              <div className={" col s12 box-time-info-title-g"}><span>Trucks in<br></br>operation</span></div>
            </div>

             <div class="col s2 speeding-alarms-g">
               <div className={" col s12 offset box-time-info-number-b-g"}>
                   <span>{this.props.Payload}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-g"}>
                   <span>Payload<br></br>Today</span><br></br>
                   <span></span>
               </div>
             </div>

             <div class="col s2 cycles-today-g">
               <div className={" col s12 offset box-time-info-number-b-g"}>
                   <span>{this.props.NCycles}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-g"}>
                   <span>#Cycles</span>
               </div>
             </div>

             <div class="col s2 km-today-g">
               <div className={" col s12 offset box-time-info-number-b-g"}>
               <span>{this.props.KmToday}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-g"}>
                   <span>KM<br></br>today</span>
               </div>
             </div>

             <div class="col s2 hours-of-operation-g">
               <div className={" col s12 offset box-time-info-number-h-g"}>
                   <span>{this.props.MinutesInOperation_GINGIN}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-b-g"}>
                   <span>Operation Time<br></br>Today</span>
               </div>
             </div>

             <div class="col s2 hours-of-operation-g">
               <div className={" col s12 offset box-time-info-number-h-g"}>
                   <span>{this.props.MinutesInTruckshoop_GINGIN}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-b-g"}>
                   <span>Truckshop Time<br></br>Today</span>
               </div>
             </div>

             <div class="col s2 hours-of-operation-g">
               <div className={" col s12 offset box-time-info-number-h-g"}>
                   <span>{this.props.MinutesInService_GINGIN}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-b-g"}>
                   <span>Parking Time<br></br>Today</span>
               </div>
             </div>

             <div class="col s2 hours-of-operation-g">
               <div className={" col s12 offset box-time-info-number-h-g"}>
                   <span></span>
               </div>
               <div className={" col s12 offset box-time-info-title-b-g"}>
                   <span></span>
               </div>
             </div>
          </div>
        </>
        )
    }
}
