import React, { Component } from "react";
import "./HeadNav.css";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";

import { SideNav } from "../SideNav/SideNav";
import { FastfoodOutlined } from "@material-ui/icons";
import { Auth } from "../../helpers/auth";
import logoPath from "../../assets/HeadNav/logo.png";

const auth = new Auth();

export class HeadNav extends Component {
    refreshdata = false;
    constructor(props) {
        super(props)
        const data = auth.getData();
        this.state = {
            fecha: new Date().toLocaleDateString(),
            hora: new Date().toLocaleTimeString(),
            name: data.faena_nombre,
            usuario: data.usuario_name
        };
    }
    componentWillMount() {
        if (!this.refreshdata) {
            this.refreshdata = setInterval(() => {
                this.setState({
                    fecha: new Date().toLocaleDateString(),
                    hora: new Date().toLocaleTimeString()
                })
            }, 900);

        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }
    render() {
        return (
            <>
                <header>
                    {/* Barra con titulo, fecha y hora */}
                    <div className={"dash"}>
                        <nav className={"head-nav"}>
                            <div className={"nav-wrapper"}>
                                <span className={"right"}>{this.state.usuario} | {this.state.name} | {this.state.hora} | {this.state.fecha}</span>
                            </div>
                        </nav>
                    </div>
                    {/* Navbar */}
                    <div className={"dash-nav"}>
                        <nav className={"dash-nav-nav"}>
                            <div className={"nav-wrapper"}>
                                <a href="/menu" className={"brand-logo"}><img className={"bg"} src={logoPath} alt={"Nexus"} /></a>
                            </div>
                        </nav>
                    </div>
                    <SideNav />
                </header>
            </>
        );
    }
};
