import React, { Component } from "react";
import M from "materialize-css";
//import { Redirect }         from "react-router";
//import { Monitor }          from "../../../../../modulos/Monitor/Monitor";
//import { Map }              from "../../../../../modulos/MapRtmPic/MapRtmPic";
//import { Graphome }         from "../../../../../modulos/GraficoHome/GraficoHome";
//import { GraphImpact }      from "../../../../../modulos/GraphImpact/GraphImpact";
//import { LogAlarms }        from "../../../../../modulos/LogAlarms/LogAlarms";
//import { SettingsAPi }      from "../../../../../../api/SettingsAPI";
import { BarTitleRtmpics }  from "../../../../../modulos/BarTitleRtmpics/BarTitleRtmpics";
import { PicsAPI }          from "../../../../../../api/PicsAPI";
import { HomeAPi }          from "../../../../../../api/HomeAPI";
import Viewer1              from 'react-viewer';
import Viewer2              from 'react-viewer';
import "./Board.css"

//const settingsapi = new SettingsAPi();
const picsapi = new PicsAPI();
const homeapi = new HomeAPi();

export class Board extends Component {

  columns = [
      {
          name: 'Id',
          selector: 'Id',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.Id_CAEX,
          sortable: true,
          width: "58px",
          center:true,
      },
      {
          name: 'Time',
          selector: 'date',
          width: "60px",
          sortable: true,
          center:true
      },
      {
          name: 'Value',
          selector: row => row.Nombre_alarm,
          sortable: true,
          width: "54px",
      },
  ];

    constructor(props) {
        super(props);
        this.state = {
          //data: [],
          //route: true,
          pathpicsA: process.env.PUBLIC_URL + '/loading-bar.gif',
          pathpicsB: process.env.PUBLIC_URL + '/loading-bar.gif',
          pathloadA: process.env.PUBLIC_URL + '/loading-bar.gif',
          pathloadB: process.env.PUBLIC_URL + '/loading-bar.gif',
          pathpicsAAnt: process.env.PUBLIC_URL + '/loading-bar.gif',
          pathpicsBAnt: process.env.PUBLIC_URL + '/loading-bar.gif',
          altpathpicsA: true,
          altpathpicsB: true,
          visible1: false,
          visible2: false,
          visibleBotonA: true,
          visibleBotonB: true,
          warningMessageA: false,
          warningMessageB: false,
          accionA: false,
          accionB: false,
          timeout: 0,
         }
    }

  checkTakePicsA(){
      let toastHTML = '<span>Failure to load photo A</span>';
      clearInterval(this.refreshtakephotoA)
      if(this.state.pathpicsA==this.state.pathpicsAAnt){
        M.toast({html: toastHTML});
      }
      this.setState({ altpathpicsA: false, visibleBotonA: true, accionA : false });
  }

  checkTakePicsB(){
      let toastHTML = '<span>Failure to load photo B</span>';
      clearInterval(this.refreshtakephotoB)
      if(this.state.pathpicsB==this.state.pathpicsBAnt){
        M.toast({html: toastHTML});
      }
      this.setState({ altpathpicsB: false, visibleBotonB: true , accionB : false });
  }

  handleSubmitCommandPic(id_nodo, tipo) {
      if(tipo=='A'){
        this.refreshtakephotoA = setInterval(() => { this.checkTakePicsA(); }, this.state.timeout * 1000 );
        let auxpath = this.state.pathpicsA;
        this.setState({
          altpathpicsA: true,
          visibleBotonA: false,
          pathpicsAAnt: auxpath,
          warningMessageA: false,
          accionA: true,
        });
      }else{
        this.refreshtakephotoB = setInterval(() => { this.checkTakePicsB(); }, this.state.timeout * 1000 );
        let auxpath = this.state.pathpicsB;
        this.setState({
          altpathpicsB: true,
          visibleBotonB: false,
          pathpicsBAnt: auxpath,
          warningMessageB: false,
          accionB: true,
        });
      }
      const datos = {
        id_config: 0,
        id_faena: 0,
        id_nodo: id_nodo,
        tipo:tipo
      };
      let datosjason = JSON.stringify(datos);
      picsapi.CommandTakePic(datosjason).then(response => {
          //console.log("Respuesta CommandTakePic:", response)
        }).catch(err => {
          if(tipo=='A'){
            let toastHTML = '<span>Photo A, failure to send command to device.</span>';
            M.toast({html: toastHTML});
            this.setState({ altpathpicsA: false, visibleBotonA: true, accionA : false });
            clearInterval(this.refreshtakephotoA)
          }else{
            let toastHTML = '<span>Photo B, failure to send command to device.</span>';
            M.toast({html: toastHTML});
            this.setState({ altpathpicsB: false, visibleBotonB: true , accionB : false });
            clearInterval(this.refreshtakephotoB)
          }

      })
    }

/*
    handleSubmitCommandLightOn(id_nodo) {
      const datos = {
        id_config: 0,
        id_faena: 0,
        id_nodo: id_nodo
      };
      let datosjason = JSON.stringify(datos);
      picsapi.CommandLightOn(datosjason).then(response => {
          //alert ("Se han actualizado correctamente los datos.")
      }).catch(err => {
          alert ("Ha ocurrido un error al actualizar los datos.")
          console.log(err)
      })
    }
*/

/*
    componentWillMount() {
        if (!this.refreshdata) {
            homeapi.getMRT().then(response => {
                if (response.status) this.setState({ data: response.data });
                homeapi.getPics(this.props.data.id_nodo).then(responsePics => {
                  if (responsePics.status){
                    if(responsePics.data[0].picsA != undefined){
                      this.setState({
                        pathpicsA: process.env.PUBLIC_URL + '/pics/' + responsePics.data[0].picsA,
                        pathpicsB: process.env.PUBLIC_URL + '/pics/' + responsePics.data[0].picsB,
                        altpathpicsA: false,
                        altpathpicsB: false,
                       });
                    }else{
                      this.setState({
                        pathpicsA: "undefined",
                        pathpicsB: "undefined",
                        altpathpicsA: true,
                        altpathpicsB: true
                      });
                    }
                    this.updateData();
                  }
                })
            }).catch(err => {
                console.log(err)
            })
        }
    }
*/

  updateDataInterval() {
    this.refreshphoto = setInterval(() => { this.readPhotoDB(); }, 5000);
  }

  readPhotoDB() {
    homeapi.getPics(this.props.data.id_nodo).then(responsePics => {
      let var_warningA = false;
      let var_warningB = false;
      let waitfoto = responsePics.data[0].waitfoto;
      let timeout = responsePics.data[0].timeoutfoto;
      if((responsePics.data[0].diffA >= waitfoto )&&(!this.state.accionA)) {
        var_warningA = true;
      }
      if((responsePics.data[0].diffB >= waitfoto )&&(!this.state.accionB)){
        var_warningB = true;
      }
      this.setState({
        warningMessageA : var_warningA,
        warningMessageB : var_warningB,
        timeout: timeout,
      });

      if (responsePics.status){
        if(responsePics.data[0].picsA==null){
          clearInterval(this.refreshtakephotoA)
          this.setState({
            pathpicsA: process.env.PUBLIC_URL + '/noimage.png',
            altpathpicsA: false,
            visibleBotonA: true,
          });
        } else if(( '/pics/' + responsePics.data[0].picsA != this.state.pathpicsA )&&(this.state.pathpicsA != undefined )) {
          clearInterval(this.refreshtakephotoA)
          this.setState({
            pathpicsA: process.env.PUBLIC_URL + '/pics/' + responsePics.data[0].picsA,
            altpathpicsA: false,
            visibleBotonA: true,
          });
        }
        if(responsePics.data[0].picsB==null){
          clearInterval(this.refreshtakephotoB)
          this.setState({
            pathpicsB: process.env.PUBLIC_URL + '/noimage.png',
            altpathpicsB: false,
            visibleBotonB: true,
          });
        }else if(( '/pics/' + responsePics.data[0].picsB != this.state.pathpicsB )&&(this.state.pathpicsB != undefined )) {
          clearInterval(this.refreshtakephotoB)
          this.setState({
            pathpicsB: process.env.PUBLIC_URL + '/pics/' + responsePics.data[0].picsB,
            altpathpicsB: false,
            visibleBotonB: true,
          });
        }
      }
    })
  }

  componentWillMount() {
    if (!this.refreshphoto) {
      this.readPhotoDB();
      this.updateDataInterval();
    }
  }

  componentWillUnmount() {
    if(this.refreshphoto) clearInterval(this.refreshphoto);
    if(this.refreshtakephotoA) clearInterval(this.refreshtakephotoA);
    if(this.refreshtakephotoB) clearInterval(this.refreshtakephotoB);
  }

  onClickbtnReturn(event) {
    if (this.props.btnreturn) {
      this.props.btnreturn(event)
    }
  }

  render() {
    return (
      <>
        <div className={"row fleet-title"}>
          <BarTitleRtmpics
            btnReturn={(e) => { this.onClickbtnReturn(e) }}
            title={"Photo Truck : "+this.props.data.tolva_id_caex}
            subtitle={""}
            pagSelect={"board"}
          />
        </div>
        <div className={"row home-component-row-pics"}>
          {/*<div className={"col s4 home-mapRtm-pics-a"}>
            <div className={"row"}>
              <Map
                trucks={this.state.data}
                truckSelect={this.props.data.id_nodo}
                route={this.state.route}
              />
            </div>
              <div className={"row"}>
                <table className={"home-monitor-barra-table-pics"} >
                  <tr className={"home-monitor-barra-tr-pics"}>
                    {this.state.data.map((value, index) => {
                      if(value.id_nodo==this.props.data.id_nodo){
                        return <td className={"home-monitor-pics"}><Monitor value={value} className={"home-monitor-barra-tr-monitor-pics"}/></td>
                      }
                    })}
                  </tr>
                </table>
              </div>
            </div>*/}
        <div className={"row"}>
          <div className={"row "}>
            <div className={"col s8"}>
              <div className={"row"}>
                  <div className={"col titlePhoto"}>Photo</div><div className={"col warningPhoto"}>{ this.state.warningMessageA ? "Warning! No new photos received.":""}</div>
              </div>
                { this.state.altpathpicsA
                  ?<img class="picstolva-pics" src={ this.state.pathloadA }  alt='loading...'  />
                  :<img class="picstolva-pics" src={ this.state.pathpicsA }  alt='pic'  />
                }
            </div>
            {/*
            <div className={"col s6"}>
              <div className={"row"}>
                  <div className={"col titlePhoto"}>Photo B</div><div className={"col warningPhoto"}>{ this.state.warningMessageB ? "Warning! No new photos received.":""}</div>
              </div>
                { this.state.altpathpicsB
                    ?<img class="picstolva-pics" src={ this.state.pathloadA }  alt='loading...'  />
                    :<img class="picstolva-pics" src={ this.state.pathpicsB }  alt='pic'  />
                }
            </div>
            */}
          </div>
          <div className={"row"}>
            <div className={"col s6"}>
              <div  className={"col s2"} style={{padding:'5px'}}><button className={"btn waves-effect waves-green "} onClick={() => {this.setState({ visible1: true }); } }>Show pic A</button></div>
              <div  className={"col s10"} style={{padding:'5px'}}>
                { this.state.visibleBotonA
                  ?<button className={"btn waves-effect waves-green "} onClick={() => {this.handleSubmitCommandPic(this.props.data.id_nodo,"A")}} >Take pic</button>
                  :<button className={"btn waves-effect waves-greenpress "} >Take pic</button>
                }
              </div>
            </div>
            <div className={"col s6"}>
              {/*
              <div  className={"col s2"} style={{padding:'5px'}}><button className={"btn waves-effect waves-green "} onClick={() => {this.setState({ visible2: true }); } }>Show pic B</button></div>
              <div  className={"col s2"} style={{padding:'5px'}}>
                { this.state.visibleBotonB
                  ?<button className={"btn waves-effect waves-green "} onClick={() => {this.handleSubmitCommandPic(this.props.data.id_nodo,"B")}} >Take pic B</button>
                  :<button className={"btn waves-effect waves-greenpress "} >Take pic B</button>
                }

              </div>
              */}
              {/*<button id="prioritario-a" class="button" onClick={() => {this.handleSubmitCommandLightOn(this.props.data.id_nodo)}} >Light on</button>*/}

            </div>
          </div>
        </div>
        <Viewer1
          visible={this.state.visible1}
          onClose={() => {this.setState({ visible1: false });}}
          images={[{src: this.state.pathpicsA, alt: this.state.pathpicsA}]}
        />
          <Viewer2
          visible={this.state.visible2}
          onClose={() => {this.setState({ visible2: false });}}
          images={[{src: this.state.pathpicsB, alt: this.state.pathpicsB}]}
        />
      </div>
    </>
  )
}

};
