import React, { Component, useEffect } from "react";
import "./SelectorDate.css";
import "materialize-css/dist/css/materialize.min.css";

export class SelectorDate extends Component {

    constructor(props) {
        super(props)
        if (this.props.checked) {
            this.state = { selectedOption: this.props.checked.toString() }
        } else {
            this.state = {
                selectedOption: "3"
            }
        }
    }

    onChangeValue(e) {
        let date = new Date();
        let interval = "h";
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        if (e.target.value == 1) {
        } else if (e.target.value == 2) {
            interval = "d";
            date.setDate(date.getDate() - 7)
        } else if (e.target.value == 3) {
            interval = "d";
            date.setMonth(date.getMonth() - 1)
        } else if (e.target.value == 4) {
            interval = "m";
            date.setMonth(date.getMonth() - 6)
        } else if (e.target.value == 5) {
            interval = "y";
            date.setDate(1)
            date.setMonth(0)
        } else if (e.target.value == 6) {
            interval = "1y";
            date.setFullYear(date.getFullYear() - 1)
        } else if (e.target.value == 7) {
            interval = "2y";
            date.setFullYear(date.getFullYear() - 2)
        }
        this.setState({
            selectedOption: e.target.value,
            interval:interval })

        if (this.props.onChange) {
            this.props.onChange(date, interval)
        }
    }

    componentDidUpdate() {
        if (this.props.checked) {
            this.state.selectedOption = this.props.checked.toString();
        }
    }

    render() {
        return (<>
            <div className={"row selector-date-select"}>
                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="1" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "1"} />
                        <span className={"selector-date-radio-text"} >1D</span>
                    </label>

                </div>

                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="2" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "2"} />
                        <span>1W</span>
                    </label>

                </div>

                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="3" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "3"} />
                        <span>1M</span>
                    </label>

                </div>

                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="4" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "4"} />
                        <span>6M</span>
                    </label>
                </div>

                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="6" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "6"} />
                        <span>1Y</span>
                    </label>
                </div>

                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="5" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "5"} />
                        <span>YTD</span>
                    </label>
                </div>


                  {/*<div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="7" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "7"} />
                        <span>2Y</span>
                    </label>

                </div>*/}

            </div>
        </>
        );
    }
}
