import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";


export class Auth {

    getAreas(){
        //let auth = localStorage.getItem('auth');
        let auth = sessionStorage.getItem('auth');
        if (auth != null) auth = JSON.parse(auth)
        if (auth != null && auth.status) {
            return auth.data.areas
        }
        return {};
    }

    getData() {
      //let auth = localStorage.getItem('auth');
      let auth = sessionStorage.getItem('auth');
        if (auth != null) auth = JSON.parse(auth)
        if (auth != null && auth.status) {
            return auth.data
        }
        return {};
    }

    getToken() {
      //let auth = localStorage.getItem('auth');
      let auth = sessionStorage.getItem('auth');
        if (auth != null) auth = JSON.parse(auth)
        if (auth != null && auth.status) {
            return auth.token
        }
        return "";
    }

    validate() {
      //let auth = localStorage.getItem('auth');
      let auth = sessionStorage.getItem('auth');
        if (auth != null) auth = JSON.parse(auth)
        if (auth != null && auth.status) {
            return true
        }
        return false;
    }

    logout() {
        //localStorage.clear("auth")
        sessionStorage.clear("auth")
    }

    restrics(parametro){
      let authData = this.getData();
      let restrictions = authData.restrictions;
      let arrayRest = restrictions.split(",");
      return arrayRest.includes(parametro);
    }

    authoriza(parametro){
      let authData = this.getData();
      let aut = authData.authorizations;
      let arrayaut = aut.split(",");
      return arrayaut.includes(parametro);
    }

    render() {
        return <Redirect to='/login' />
    }
}
