import React, { Component } from "react";
import "./Fleet.css"
import { FleetAPi }                 from "../../../../../../api/FleetApi";
import { GraphMultiLine }           from "../../../../../modulos/GraphMultiLine/GraphMultiLine";
import { GraphBar }                 from "../../../../../modulos/GraphBar/GraphBar";
import { GraphBarAvailability }     from "../../../../../modulos/GraphBarAvailability/GraphBarAvailability";
import { BoxTime }                  from "../../../../../modulos/BoxTime/BoxTime";
import { BarTitle }                 from "../../../../../modulos/BarTitle/BarTitle";
import { SelectorDate }             from "../../../../../modulos/SelectorDate/SelectorDate";
import { Availability }             from "../../../../../modulos/Availability/Availability";
import { BarTitleCycleAnalytics }   from "../../../../../modulos/BarTitle/BarTitle";
import { GraficoCycleTime }         from "../../../../../modulos/GraficoCycleTime/GraficoCycleTime";
import { Auth } from "../../../../../../helpers/auth";

const auth = new Auth();
const fleetAPi = new FleetAPi();

export class Fleet extends Component {
    subtitle = ["Fleet analytics", "Unit analytics"]
    databarCycleTitleStatus = [
      "?",              // 1
      "Queue to load",  // 2
      "Loading",        // 3
      "?",              // 4
      "To dump",        // 5
      "?",              // 6
      "Queue To dump",  // 7
      "Dumping",        // 8
      "?",              // 9
      "To load",        //10
      "?",              //11
      "?",              //12
      "Total",          //13
    ]
    data = [{
        name: "truck1",
        data: [{ x: 1, y: "2" }, { x: 2, y: 4 }, { x: 3, y: 8 }, { x: 4, y: 4 }]
    }, {
        name: "truck2",
        data: [{ x: 1, y: 4 }, { x: 2, y: 3 }, { x: 3, y: 2 }, { x: 4, y: 8 }]
    }];
    databar = [{
        name: "hour1",
        data: [{ x: "truck1", y: 3 }, { x: "truck2", y: 4 }, { x: "truck3", y: 8 }, { x: "truck4", y: 4 }]
    }, {
        name: "hour2",
        data: [{ x: "truck1", y: 4 }, { x: "truck2", y: 3 }, { x: "truck3", y: 2 }, { x: "truck4", y: 8 }]
    }];
    constructor(props) {
        super(props);
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.state = {
            data: false,
            intervalSelected: "d",
            dataTruckPosition: [],
            dateSelected: date,
            view: 1,
            selectedRow: {},
            truckRoute: [],
			      runingTime: 0,
            queuingtoload: 0,
            queuingtodump: 0,
            fuellingstop: 0,
            efficiency: 0,
            availabar: [],
        }
    }

    componentWillMount() {
      this.updateData()
    }

    updateData(date = null, interval = "d") {;
      if(date==null){
        date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMonth(date.getMonth() - 1)
      }
        fleetAPi.getData(date || this.state.dateSelected, interval || this.state.intervalSelected).then((response) => {
            if (response.status) {
                let trucksum = {}
                let availability = []
                let cycle = [[], [], [], [], [], [], [], [], [], [], [], [], []];
				        let runingTimeAux = 0;
                let queuingtoloadAux = 0;
                let queuingtodumpAux = 0;
                let fuellingstopAux = 0;
                let efficiencyAux = 0;
                let countCycles = 0;
                let count = 0;
                let graph_ava_avg = []
                for (const valor_avg of response.data.graph_ava_avg){
                  graph_ava_avg.push ({
                    name:valor_avg.modelo,
                    value:[valor_avg.availability, Math.round((100 - valor_avg.availability)*100)/100]
                  })
                }
                response.data["graph_ava_avg_serie"] = graph_ava_avg
                response.data["maxcycle"] = 0;
                let valcycle = 0;
                let lista_shop = []
                let lista_parking = []
                let lista_availability = []
                let serie_availability = []
                for (const truck of response.data.trucks) {
                    lista_shop.push({
                      x:truck.ID_CAEX,
                      y:truck.availability_truckshop})
                    lista_parking.push({
                      x:truck.ID_CAEX,
                      y:truck.availability_parking
                    })
                    lista_availability.push({
                      x:truck.ID_CAEX,
                      y:truck.availability})
                }
                serie_availability.push({
                  data:lista_availability,
                  name:"Available"
                })
                serie_availability.push({
                  data:lista_shop,
                  name:"Truckshop"
                })
                serie_availability.push({
                  data:lista_parking,
                  name:"Other (parking)"
                })

                for (const truck of response.data.trucks) {
                    trucksum["Cycles"] = truck.Cycles;
                    trucksum["avg_tt"] = truck.avg_tt;
                    trucksum["avg_payload"] = truck.avg_payload;
                    trucksum["time_load"] = truck.time_load;
                    trucksum["time_runing"] = truck.time_runing;
                    trucksum["time_dump"] = truck.time_dump;
                    trucksum["time_service"] = truck.time_service;
                    trucksum["availability"] = truck.availability;
                    trucksum["Efficiency"] = truck.Efficiency;

                    count++;
                    countCycles += truck.Cycles;
                    queuingtodumpAux += truck.time_dump;
                    runingTimeAux += truck.time_runing;
                    queuingtoloadAux += truck.time_load;
                    fuellingstopAux += truck.time_service;
                    efficiencyAux += truck.Efficiency * truck.Cycles;
                    availability.push({
                        name: truck.ID_CAEX,
                        value: [truck.availability, 100 - truck.availability]
                    })

                    let suma = 0;
                    for (let i in cycle) {
                        valcycle = (Math.trunc(truck["dt" + (parseInt(i) + 1)] * 100) / 100);
                        cycle[i].push({
                            x: truck.ID_CAEX,
                            y: valcycle
                        })

                        let dt = "dt" + (parseInt(i) + 1);
                        if( dt == "dt2" || dt == "dt3" || dt == "dt5" || dt == "dt7" || dt == "dt8" ||  dt == "dt10" ){
                          suma += valcycle;
                          if (suma > response.data.maxcycle) {
                              response.data.maxcycle = suma;
                          }
                        }
                    }

                    if (response.data.maxcycle==0){
                      response.data.maxcycle=10;
                    }

                }
                let efficiencyAVG = 0;
                if (count > 0) { efficiencyAVG = Math.round(efficiencyAux / countCycles ) }
                this.setState({
                  runingTime: runingTimeAux,
                  queuingtoload: queuingtoloadAux,
                  queuingtodump: queuingtodumpAux,
                  fuellingstop: fuellingstopAux,
                  efficiency: efficiencyAVG
                });

                response.data["trucksum"] = trucksum;
                response.data["availability"] = availability;
                response.data["cycle"] = [];
                let serieSuma = []
                for (let i in cycle) {
                  if ((parseInt(i) + 1) == 2 || parseInt(i) + 1 == 3 ||
                    parseInt(i) + 1 == 5 || parseInt(i) + 1 == 7 ||
                    parseInt(i) + 1 == 8 || parseInt(i) + 1 == 10 ||
                    parseInt(i) + 1 == 13) {
                    if (parseInt(i) + 1 == 13){
                      response.data["cycle"].push({
                        name: this.databarCycleTitleStatus[i],
                        type: "line",
                        data: cycle[i]
                      });
                    }else{
                      response.data["cycle"].push({
                        name: this.databarCycleTitleStatus[i],
                        type: 'column',
                        data: cycle[i]
                      });
                    }
                  }
                }

                //Restricciones para GINGIN
                if (auth.restrics('FAE-GIN')){
                  response.data.cycle.splice(0, 1);
                  response.data.cycle.splice(2, 1);
                }

                let trucks = [];
                let areasLoad = [];
                let areasDump = [];
                let areasPayload = [];
                let data = {};
                let maxpayload = 0;

                for (const g of response.data.graphline) {
                    if (!data[g.type]) data[g.type] = {}
                    if (!data[g.type][g.area]) data[g.type][g.area] = {}
                    data[g.type][g.area][g.Tipo_CAEX] = Math.round(g.value * 100) / 100
                    if (trucks.indexOf(g.Tipo_CAEX) < 0) trucks.push(g.Tipo_CAEX)
                    if (g.type == "load") areasLoad.push(g.area)
                    if (g.type == "dump") areasDump.push(g.area)
                    if (g.type == "payload") {
                        if (g.value > maxpayload) {
                            maxpayload = g.value;
                        }
                        areasPayload.push(g.area)
                    }
                }

                for (const type in data) {
                    for (const area in data[type]) {
                        for (const truck of trucks) {
                            if (!data[type][area][truck])
                                data[type][area][truck] = 0
                        }
                    }
                }
                let graphloadn = {}
                let graphdumpn = {}
                let graphpayloadn = {}
                for (const truck of trucks) {
                    for (const type in data) {
                        if (type == "load") {
                            for (const area of areasLoad.sort()) {
                                if (Object.keys(data[type]).indexOf(area) >= 0) {
                                    if (!graphloadn[truck]) graphloadn[truck] = []
                                    graphloadn[truck].push(data[type][area][truck])
                                }
                            }
                        } else if (type == "dump") {
                            for (const area of areasDump.sort()) {
                                if (Object.keys(data[type]).indexOf(area) >= 0) {
                                    if (!graphdumpn[truck]) graphdumpn[truck] = []
                                    graphdumpn[truck].push(data[type][area][truck])
                                }
                            }
                        } else if (type == "payload") {
                            for (const area of areasPayload.sort()) {
                                if (Object.keys(data[type]).indexOf(area) >= 0) {
                                    if (!graphpayloadn[truck]) graphpayloadn[truck] = []
                                    graphpayloadn[truck].push(Math.round(data[type][area][truck]))
                                }
                            }
                        }
                    }
                }
                response.data["trucksum"]["count"] = trucks.length;
                response.data["graphdump"] = []
                for (let len of Object.keys(graphdumpn).sort()) {
                    response.data.graphdump.push({
                        name: len,
                        data: graphdumpn[len]
                    })
                }
                response.data["graphload"] = []
                for (let len of Object.keys(graphloadn).sort()) {
                    response.data.graphload.push({
                        name: len,
                        data: graphloadn[len]
                    })
                }
                response.data["grappayload"] = []
                for (let len of Object.keys(graphpayloadn).sort()) {
                    response.data.grappayload.push({
                        name: len,
                        data: graphpayloadn[len]
                    })
                }
                response.data["maxpayload"] = maxpayload
                response.data["graphareasload"] = areasLoad.sort()
                response.data["graphareasdump"] = areasDump.sort()
                response.data["graphareasPayload"] = areasPayload.sort()
                response.data["graphavalability"] = serie_availability.sort()
                this.setState({ data: response.data });
            }
        });
    }



    datatableSelect(data) {
        this.setState({ view: 2, selectedRow: data })
    }

    onChangeSelectValue(date, interval) {
        this.setState({ dateSelected: date })
        this.updateData(date, interval);
    }

    boardreturn(e) {
        this.setState({ view: 1 })
    }

    onChangeValueDate(date, interval) {
        this.setState({
            dateSelected: date,
            intervalSelected: interval
        })
        this.updateData(date, interval);
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))
          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }
        return (hour).toLocaleString('en') + "h " + minutes + "m"
    }

    onClickbtnReturn(event) {
        if (this.props.btnreturn) {
            this.props.btnreturn(event)
        }
    }

    onClickbtnFleetReturn(event) {
        if (this.props.btnfleetreturn) {
            this.props.btnfleetreturn(event)
        }
    }

    onClickbtnUnitReturn(event) {
        if (this.props.btnunitreturn) {
            this.props.btnunitreturn(event)
        }
    }

    render() {
        return <div className={"row fleet"}>
            <div className={"col s12 fleet-title"}>
              <BarTitle
                onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
                onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
                title={"FLEET"}
                subtitle={this.subtitle}
                pagSelect = "Fleet"
              />
            </div>
            <div className={"col s6 fleet-iz"}>
                <div className={"row fleet-iz-row"}>
                    <div className={"col s12 fleet-margin-bottom fleet-selectordate"}>
                        <SelectorDate onChange={(d, i) => { this.onChangeValueDate(d, i) }} />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-boxtime"}>
                        <BoxTime
                          efficiency={(this.state.efficiency ? Math.round(this.state.efficiency) + " %" : "0 %")}
                          runingTime={this.MinuteDecimalToString(this.state.runingTime)}
                          queuingToLoad={this.MinuteDecimalToString(this.state.queuingtoload)}
                          queuingToDump={this.MinuteDecimalToString(this.state.queuingtodump)}
                          fuelingstop={this.MinuteDecimalToString(this.state.fuellingstop)}
                        />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-graphMultiLine-load"}>
                        <GraphMultiLine
                          backgounrd="#2b1872"
                          title="Queue to load (avg.)"
                          subTitle="Loading zone"
                          categories={(this.state.data.graphareasload ? this.state.data.graphareasload : [])}
                          series={(this.state.data.graphload ? this.state.data.graphload : [])}
                        />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-graphMultiLine-dump"}>
                        <GraphMultiLine title="Queue to dump (avg.)" subTitle="Dumping zone" categories={(this.state.data.graphareasdump ? this.state.data.graphareasdump : [])} series={(this.state.data.graphdump ? this.state.data.graphdump : [])} />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-raphBar"}>
                      <Availability series={this.state.data.graph_ava_avg_serie} />
                    </div>
                </div>
            </div>
            <div className={"col s6 fleet-der"}>
                <div className={"row fleet-der-row"}>
                    <div className={"col s12 fleet-margin-bottom fleet-cycle"}>
                      <GraficoCycleTime
                        backgounrd="#101d30"
                        legendMargin={10}
                        legendOffsetY={20}
                        max={this.state.data.maxcycle}
                        stacked={true}
                        legndPosition="right"
                        title="Minutes"
                        subTitle="Truck type/recorrido type"
                        height="230%"
                        series={(this.state.data.cycle ? this.state.data.cycle : [])}
                        />
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-Availability-title"}>
                        Availability
                    </div>
                    <div className={"col s12 fleet-margin-bottom fleet-cycle"}>
                        <GraphBarAvailability
                          backgounrd="#1b1b1b"
                          legendMargin={20}
                          legendOffsetY={20}
                          max={66.5}
                          stacked={true}
                          legndPosition="right"
                          title=""
                          subTitle="Truck type/recorrido type"
                          height="230%"
                          series={(this.state.data.graphavalability ? this.state.data.graphavalability : [])}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
};
