// JAL 20220322 Se agrega funcion getBoardByIdNodoGinGin

import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();
export class UnitAPi {

    contructor() {

    }

    getTable(date) {

        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/table/` + date.toJSON(), {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())
    }

    getMapTruckRoute(date) {
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/map/route/` + date.toJSON(), {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())
    }

    getMRTByIdNodo(idnodo) {

        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/mrt/` + idnodo, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())

    }

    getBoardByIdNodoGinGin(idnodo, interval) {
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/boardgingin/` + idnodo + "/" + interval, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())
    }

    getBoardByIdNodo(idnodo, date, interval) {

        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/board/` + idnodo + "/" + interval + "/" + date.toJSON(), {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())

    }

    getBoardTableCycleTime(idnodo, date ) {
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/cycletable/` + idnodo + "/" + date.toJSON(), {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())
    }

    getBoardTableCycleTimeGinGin(idnodo, interval ) {
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/cycletablegingin/` + idnodo + "/" + interval, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json())
    }

    getPayloadGraph() {
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/payload/graph/`, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) => response.json());
    }

    getDataGraphByNodo(id_nodo) {
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/unit/graphbyidnodo/` + id_nodo, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        })
        .then((response) => response.json())
        .catch((e) => { console.log("UnitAPI getDataGraphByNodo Error: ",e) })
    }

}
