import React, { Component } from "react";
import { Monitor } from "../../../../modulos/Monitor/Monitor";
import { Map } from "../../../../modulos/Map/Map";
import { Graphome } from "../../../../modulos/GraficoHome/GraficoHome";
import "./style.css"
import { SettingsAPi } from "../../../../../api/SettingsAPI";
import { Redirect } from "react-router";

const settingsapi = new SettingsAPi();

export class Tolvacreate extends Component {

  constructor(props) {
      super(props);
      this.state = {
        Id_Caex:'',
        Modelo:'',
        angulo_descarga:'',
        redirect: false,
        listaTipoCaex:new Array([]) //Previene error Unhandled Rejection (TypeError) new Array([])
      }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    const datos = {
      Id_Caex: data.get('Id_Caex'),
      id_tipocaex: data.get('select_tipocaex'),
      angulo_descarga: data.get('angulo_descarga'),
      id:data.get('id'),
    };
    let datosjason = JSON.stringify(datos);
    settingsapi.CreateTolva(datosjason).then(response => {
        alert ("Se han actualizado correctamente los datos.")
        this.setState({
          redirect: true
        });
    }).catch(err => {
        alert ("Ha ocurrido un error al actualizar los datos.")
    })
  }

  componentWillMount() {
    if (!this.refreshdata) {
      settingsapi.getReadTipoCaex().then(response => {
         this.setState({
          listaTipoCaex: response
        })
      })
    }
  }

  renderOptionTipoCaex(){
    return(
      this.state.listaTipoCaex.map((value, index) => {
          return (<option value={value.id}>{value.modelo}</option>)
      })
    )
  }

  componentWillUnmount() {
      clearInterval(this.refreshdata)
  }

  render() {
    if (this.state.redirect){
      return <Redirect to='/settings' />
    }

    return (
      <>
      <div class="user-detail">
        <center><h2>Truck create</h2></center>
          <form onSubmit={this.handleSubmit} id="formTrucks">
                <p><label>Id truck</label>
                <input type="text" placeholder="Enter identifier" name="Id_Caex"  value={this.state.Id_Caex}  onChange={this.handleInputChange}   required  /></p>
                <p><label>Model</label>
                  <select className="browser-default" value ={this.state.select_tipocaex} name="select_tipocaex" onChange={this.handleInputChange}>
                    <option value="" disabled selected>Choose your option</option>
                    {this.renderOptionTipoCaex()}
                  </select>
                </p>
                <p><label>Angle</label>
                <input type="text" placeholder="Enter angle" name="angulo_descarga"   value={this.state.angulo_descarga}  onChange={this.handleInputChange}  required /></p>
                <input type="hidden" name="id" value={this.props.id} />
                <center><input class="btn waves-effect waves-green" type="submit" value="Submit"  /></center>
          </form>
      </div>
      </>)
  }
}
