import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();
export class HomeAPi {

    contructor() {

    }

    getMRT(){

        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/mrt`, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) =>  response.json())
    }

    getGpsPoint(){

        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/gpspoint`, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) =>  response.json())
    }

    getPics(id_nodo){
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/mrtpics/`+ id_nodo, {
            method: "GET",
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': auth.getToken()
            }
        }).then((response) =>  response.json())
    }
}
