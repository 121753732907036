import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Payload.css"
import Chart from "react-apexcharts";

export class Payload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: {
              fill: {
                colors: undefined,
                opacity: 0.9,
                type: ['solid', 'gradient'],
                gradient: {
                  shade: 'dark',
                  type: "vertical",
                  shadeIntensity: 0.5,
                  gradientToColors: undefined,
                  inverseColors: false,
                  opacityFrom: 1,
                  opacityTo: 0.6,
                  stops: [0, 60, 100],
                  colorStops: []
                },
              },
                xaxis: {
                  type: 'numeric',
                  tickPlacement: 'on',
                  tickAmount: 'dataPoints',
                  title: {
                    text: "CYCLE",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "#FFFFFF",
                      fontSize: '12px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      colors: "#ffffff"
                    },
                    formatter: function(value) {
                      return Math.round(value)
                    },
                  },
                },
                yaxis:[{
                    max: (props.max_series ? Math.round(props.max_series) : 300 ),
                    show: true,
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        text: 'Tonnes'
                    },
                    labels: {
                        style: {
                            colors: "#ffffff"
                        }
                    }
                },{
                  max: (props.max_series ? Math.round(props.max_series) : 300 ),
                  show: false,
                },],
                chart: {
                    height: 100,
                    stacked: false,
                    toolbar: {
                        show: true
                    }
                },
                stroke: {
                  curve: 'smooth',
                  width: [3, 3 , 0, 0, 0, 0, 0],
                  dashArray: [0, 0, 0, 0, 0, 0, 0],
                  colors:['#572EEE','#A994F6','#803096',],
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1],
                  background: {
                    opacity: 0.0,
                  },
                },
                tooltip: {
                  enabled: true,
                },
                colors: ['#572EEE','#A994F6','#803096',],
                legend: {
                    show: true,
                    position: "right",
                    offsetY: 30,
                    labels: {
                        colors: "#ffffff"
                    },
                    itemMargin: {
                        vertical: 0,
                    },
                    inverseOrder: false,
                    itemMargin: {
                        vertical: 9,
                    },
                },
            }
        }
    }

    componentDidUpdate() {
      if(this.props.max_series != null && this.props.max_series != false && Math.trunc(this.state.options.yaxis[0].max) != Math.trunc(this.props.max_series) ) {
        this.setState({
          options: {
              xaxis: {
                type: 'numeric',
                tickAmount: 'dataPoints',
                title: {
                  text: "CYCLE",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: "#FFFFFF",
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
                labels: {
                  style: {
                    colors: "#ffffff"
                  }
                }
              },
              yaxis:[{
                  max: (this.props.max_series ? Math.round(this.props.max_series) : 300 ),
                  show: true,
                  title: {
                      style: {
                          color: "#ffffff"
                      },
                      text: 'Tonnes'
                  },
                  labels: {
                      style: {
                          colors: "#ffffff"
                      }
                  }
              },{
                max: (this.props.max_series ? Math.round(this.props.max_series) : 300 ),
                show: false,
              },],
              chart: {
                  height: 100,
                  stacked: false,
                  toolbar: {
                      show: true
                  }
              },
              stroke: {
                curve: 'smooth',
                width: [3, 3 , 0, 0, 0, 0, 0],
                dashArray: [0, 0, 0, 0, 0, 0, 0],
                colors: ['#572EEE','#A994F6','#803096',],
              },
              dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                background: {
                  opacity: 0.0,
                },
              },
              tooltip: {
                enabled: false,
              },
              colors: ['#572EEE','#A994F6','#803096',],
              legend: {
                  show: true,
                  position: "right",
                  offsetY: 30,
                  labels: {
                      colors: "#ffffff"
                  },
                  itemMargin: {
                      vertical: 0,
                  },
                  inverseOrder: false,
                  itemMargin: {
                      vertical: 9,
                  },
              },
          }
        })
      }

    }

    render() {
        return (<>
            <div className={"row payload"}>
                <div className={"col s12 payload-graph"} style={{ background: "#191f27" }}>
                    <Chart
                        options={this.state.options}
                        series={[{
                            name: "Target payload",
                            type: this.props.type_2,
                            data: this.props.series_2
                        },{
                            name: "Payload",
                            type: this.props.type_1,
                            data: this.props.series_1
                        },
                        ]}
                        height = {320}
                    />
                </div>
            </div>
        </>
        )
    }
}
