import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoxPayload.css"

export class BoxPayload extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
        <>
            <div className={"row boxPayload-time"}>
                <div className={" col s1 offset boxPayload-time-info"}>
                    <div className={"row"}>
                        <div className={" col s12 offset boxPayload-time-info-number"}>
                            <span> {this.props.totaltonnes}</span>
                        </div>
                        <div className={" col s12 offset boxPayload-time-info-title"}>
                            <span>Total tonnes loaded</span>
                        </div>
                    </div>
                </div>
                <div className={" col s1 offset boxPayload-time-info"}>
                    <div className={"row"}>
                        <div className={" col s12 offset boxPayload-time-info-number"}>
                            <span> {this.props.totalload}</span>
                        </div>
                        <div className={" col s12 offset boxPayload-time-info-title"}>
                            <span>Average payload / Target payload</span>
                        </div>
                    </div>
                </div>
                <div className={" col s1 offset boxPayload-time-info"}>
                    <div className={"row"}>
                        <div className={" col s12 offset boxPayload-time-info-number"}>
                            <span> {this.props.tonnesbehind}</span>
                        </div>
                        <div className={" col s12 offset boxPayload-time-info-title"}>
                            <span>Tonnes behind schedule</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
