import React from "react";


const ProgressBar = (props) => {
  const { completed, showvalue } = props;

  const containerStyles = {
    height: 35,
    width: '100%',
    backgroundColor: "#4E4E4E",
    borderRadius: 5,
    borderColor: "white",
    border: "1px solid white",
    marginTop: 10,
    textAlign: 'center'
  }

  const fillerStyles = {
    height: '33px',
    width: `${completed}%`,
    backgroundColor: '#91F342',
    borderRadius: 'inherit',
    position: 'relative',
    top: '0px',
    textAlign: 'center'
  }

  const labelStyles = {
    //padding: 37,
    color: 'black',
    fontWeight: 'bold',
    fontSize: 'large',
    position: 'relative',
    textAlign: 'center',
    top: '-29px',
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
      <div style={labelStyles}>{`${showvalue}`}</div>

    </div>
  );
};

export default ProgressBar;
