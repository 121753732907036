import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./GraphMultiLine.css"
import Chart from "react-apexcharts";
import { Auth } from "../../../helpers/auth";
import notAvailable_img from "../../../assets/com/notavailable.png";
const auth = new Auth();

export class GraphMultiLine extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                colors: [
                  "#203864", "#A9D18E", "#272EEE", "#BAE3F3", "#8C1834",
                  "#803092","#203864", "#A994F6", "#00B050", "#2E75B6",
                  "#CC0000", "#1B998B", "#803092","#203864", "#A994F6",
                  ],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                legend: {
                    show: true,
                    position: 'right',
                    offsetY: 12,
                    labels: {
                        colors: "#ffffff"
                    },

                },
                xaxis: {
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        offsetY: -3,
                        text: 'Zone'
                    },
                    categories: props.categories,
                },
                yaxis: {
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        text: 'Minutes'
                    }
                },
                fill: {
                    opacity: 0.7
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            }
        }
    }
    componentDidUpdate() {
        if (this.state.options.xaxis.categories.length != this.props.categories.length) {
            this.setState({
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    legend: {
                        show: true,
                        position: 'right',
                        offsetY: 12,
                        labels: {
                            colors: "#ffffff"
                        },

                    },
                    xaxis: {
                        categories: this.props.categories,
                    },
                    yaxis: {
                        title: {
                            style: {
                                color: "#ffffff"
                            },
                            text: 'Minutes'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    }
                }
            })
        }
    }
    render() {
        return (<>
            <div className={"row graphmultiline"} >
                <div className={"col s6 graphmultiline-avg "} style={{ background: (this.props.backgounrd ? this.props.backgounrd : "#172a4b") }}>
                    <div className={"row "}>
                        <div className={"col s12 graphmultiline-avg-title"}>
                          {/*{(!auth.restrics('CA-GQL')? this.props.title || "no title" : "" )}*/}
                          {this.props.title || "no title"}

                        </div>
                    </div>
                </div>
                <div className={"col s6 graphmultiline-graph"}>
                    <div className={"row graphmultiline-graph-row"}>

                          {(!auth.restrics('CA-GQL')
                            ?
                            <div className={"col graphmultiline-graph-col"} style={{ background: "#191f27" }}>
                            <Chart
                                options={this.state.options}
                                series={this.props.series}
                                type="bar"
                                height="100%"
                                width="100%"
                            />
                          </div>
                          :
                          <div className={"col graphmultiline-graph-col"} style={{ background: "#191f27" }}>
                          <div id="row_top"><img src={notAvailable_img} ></img></div>
                          <div id="row_bottom">
                            <Chart
                                options={this.state.options}
                                series={this.props.series}
                                type="bar"
                                height="120%"
                                width="100%"
                            />
                          </div>
                          </div>
                          )}





                    </div>
                </div>

            </div>
        </>
        )
    }
}
/*


*/
