

export class LoginAPi {

    contructor() {
    }

    loginHttp(user, password) {
    /*  console.log(JSON.stringify({
          "user": user,
          "password": password
      }))*/
        //console.log(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/login`)
        return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/login`, {
            method: "POST",
            body: JSON.stringify({
                "user": user,
                "password": password
            }),
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'authorization': "this.token"
            }
        }).then((response) => response.json())
    }
}
