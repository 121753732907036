import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./GraphWearByTry.css"
import Chart from "react-apexcharts";

export class GraphWearByTry extends Component {

  constructor(props) {
          super(props);
          this.state = {
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 0,
                  columnWidth: '10%',
                  barHeight: '100%',
                  distributed: false,
                  rangeBarOverlap: true,
                  rangeBarGroupRows: false,
                  colors: {
                    ranges: [{
                      from: 0,
                      to: 0,
                      color: undefined
                    }],
                    backgroundBarColors: [],
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: 0,
                  },
                  dataLabels: {
                    position: 'center',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                  }
                }
              },
              fill: {
                  colors: ['#2E75B6'],
              },
              chart: {
                height: 350,
                type: 'line',
              },
              stroke: {
                width: [0, 4],
                dashArray: [0, 8, 5],
                colors: ['#572EEE']
              },
              title: {
                text: ''
              },
              dataLabels: {
                style: {
                  colors: ['#2E75B6','#572EEE']
                },
                dropShadow: {
                    enabled: true,
                    left: 2,
                    top: 2,
                    opacity: 0.5
                },
                enabled: true,
                offsetX: 0,
                offsetY: 0,
                enabledOnSeries: [0,1],
                distributed: false,
                textAnchor: 'middle',
              },
              legend: {
                  show: false,
                  position: "bottom",
                  customLegendItems: this.props.legenda,
                  markers: {
                    fillColors: [ '#2E75B6','#572EEE'],
                    strokeDashArray: [0,1],
                    shape: "square",
                  },
                  labels: {
                    colors: '#FFFFFF',
                    useSeriesColors: false,
                  },
              },
              labels: [],
              xaxis: {
              },
              yaxis: [{
                min:0,
                max:25,
                title: {
                  text: 'Thickness (cm)',
                  style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    fontFamily:  undefined,
                    color:  '#ffffff'
                  },
                },
              }, {
                min:0,
                max:25,
                opposite: true,
                title: {
                  text: ''
                }
              }]
            },
          };
        }

        componentDidUpdate() {
          if(this.state.options != undefined){
            if(this.props.labels != undefined ){
              if(this.state.options.labels != this.props.labels){
                this.setState({
                  options: {
                    labels: ( this.props.labels ? this.props.labels:[]),
                  }
                })
              }
            }
          }
        }

        render() {
            return (
              <div id = "chart"  style={{ background: "#191f27" }}>>
                <div class="title_weargraph">Wear by tray</div>
                <Chart
                  options = {this.state.options}
                  series = {this.props.series}
                  type = "line"
                  height ={350}
                />
              </div>
            );
        }

}
