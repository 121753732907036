import React, { Component } from "react";
import "materialize-css/dist/css/materialize.min.css";
import "./Menu.css";
import M              from "materialize-css";
import LockIcon       from '@material-ui/icons/Lock';
import ExitToAppIcon  from '@material-ui/icons/ExitToApp';
import bg             from "../../assets/login/bg.jpg";
import Slider         from "react-slick";
import { Redirect }   from "react-router-dom";
import { Auth }       from "../../helpers/auth";

const auth = new Auth();

export class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select: -1
        }
    }

    mauseOver(menu) {
        if (menu == "map") {
            this.setState({ select: 0 })
        } else if (menu == "analytic") {
            this.setState({ select: 1 })
        } else if (menu == "asset") {
            this.setState({ select: 2 })
        } else if (menu == "payload") {
            this.setState({ select: 3 })
        } else if (menu == "reporting") {
            this.setState({ select: 4 })
        }else if (menu == "photography") {
            this.setState({ select: 5 })
        }else if (menu == "photolist") {
            this.setState({ select: 6  })
        }
    }

    onclick(menu) {
        if (menu == "map") {
          this.setState({ redirect: true })
          this.setState({ select: 0 })
        } else if (menu == "analytic") {
          this.setState({ redirect: true })
          this.setState({ select: 1 })
        } else if (menu == "asset") {
          this.setState({ redirect: true })
          this.setState({ select: 2 })
        } else if (menu == "payload") {
          this.setState({ redirect: true })
          //  this.setState({ select: 3 })
        } else if (menu == "reporting") {
          //   this.setState({ select: 4 })
        } else if (menu == "photography") {
          this.setState({ redirect: true })
          this.setState({ select: 5 })
        } else if (menu == "photolist") {
          this.setState({ redirect: true })
          this.setState({ select: 6 })
        }
    }

    logout(e) {
        auth.logout();
        window.location.reload();
    }

    render() {
        if (this.state.redirect) {
            if (this.state.select == 0) {
                return <Redirect to='/' />
            }else if (this.state.select == 1) {
              return <Redirect to='cycleanalytics' />
            }else if (this.state.select == 2) {
              return <Redirect to='asset' />
            }else if (this.state.select == 3) {
              return <Redirect to='payload' />
            }else if (this.state.select == 5) {
              return <Redirect to='rtmpics' />
            }else if (this.state.select == 6) {
              return <Redirect to='listpics' />
            }

        }

        return (
                <>
                {(auth.restrics('FAE-MEL')?
                  <div className={"Menu"}>
                    <img className={"bg"} src={bg} alt={"Background app"} />
                    <div className="row">
                      <div className="col s11"></div>
                      <div className="col s1">
                        <a className={"menu-boton"} onClick={() => this.logout()} >  <ExitToAppIcon fontSize="large" /></a>
                      </div>
                    </div>

                    <div className={"menu-table-div"}>
                      <table className={"menu-table"}>
                        <tr className={"menu-tr"}>
                          <td className={"menu-td pointer map " + (this.state.select == 5 ? "selected" : "")} onMouseOver={() => { this.mauseOver("photography") }} onClick={() => { this.onclick("photography") }}>
                            <div className={"imagen-map-conentedor"}>
                              <div className={"menu-content pointer "}>
                                Live Cam
                              </div>
                            </div>
                          </td>
                          <td className={"menu-td pointer cycle " + (this.state.select == 6 ? "selected" : "")} onMouseOver={() => { this.mauseOver("photolist") }} onClick={() => { this.onclick("photolist") }}>
                            <i className={"material-icons prefix"}></i>
                            <div className={"menu-content pointer"} >
                              Photo List
                            </div>
                          </td>
                          <td className={"menu-td pointer asset " + (this.state.select == 2 ? "selected" : "")} onMouseOver={() => { this.mauseOver("asset") }} onClick={() => { this.onclick("asset") }}>
                            <i className={"material-icons prefix"}></i>
                            <div className={"menu-content pointer"}>Asset Management</div>
                          </td>

                          <td className={"menu-td pointer menu-payload " + (this.state.select == 3 ? "selected" : "")} onMouseOver={() => { this.mauseOver("payload") }} onClick={() => { this.onclick("") }}>
                            <i className={"material-icons prefix"}><LockIcon /></i>
                            <div className={"menu-content pointer"}>Payload</div>
                          </td>
                          <td className={"menu-td reporting " + (this.state.select == 4 ? "selected" : "")} onMouseOver={() => { this.mauseOver("reporting") }} onClick={() => { this.onclick("") }}>
                            <i className={"material-icons prefix"}><LockIcon /></i>
                            <div className={"menu-content"}>
                              Reporting
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  :
                  <div className={"Menu"}>
                    <img className={"bg"} src={bg} alt={"Background app"} />
                    <a className={"menu-boton"} onClick={() => this.logout()} >  <ExitToAppIcon fontSize="large" /></a>
                    <div className={"menu-table-div"}>
                      <table className={"menu-table"}>
                        <tr className={"menu-tr"}>
                          <td className={"menu-td pointer map " + (this.state.select == 0 ? "selected" : "")} onMouseOver={() => { this.mauseOver("map") }} onClick={() => { this.onclick("map") }}>
                            <div className={"imagen-map-conentedor"}>
                              <div className={"menu-content pointer "}>
                                Map
                              </div>
                            </div>
                          </td>
                          <td className={"menu-td pointer cycle " + (this.state.select == 1 ? "selected" : "")} onMouseOver={() => { this.mauseOver("analytic") }} onClick={() => { this.onclick("analytic") }}>
                            <i className={"material-icons prefix"}></i>
                            <div className={"menu-content pointer"} >
                              Cycle Analytics
                            </div>
                          </td>
                          {(auth.restrics('FAE-GIN')
                            ?
                            <td className={"menu-td pointer asset " + (this.state.select == 2 ? "selected" : "")} onMouseOver={() => { this.mauseOver("asset") }} onClick={() => { this.onclick("asset") }}>
                              <i className={"material-icons prefix"}></i>
                              <div className={"menu-content pointer"}>Asset Management</div>
                            </td>
                            :
                            <td className={"menu-td pointer asset " + (this.state.select == 2 ? "selected" : "")} onMouseOver={() => { this.mauseOver("asset") }} onClick={() => { this.onclick("asset") }}>
                              <i className={"material-icons prefix"}></i>
                              <div className={"menu-content pointer"}>Asset Management</div>
                            </td>
                          )}
                          <td className={"menu-td pointer menu-payload " + (this.state.select == 3 ? "selected" : "")} onMouseOver={() => { this.mauseOver("payload") }} onClick={() => { this.onclick("payload") }}>
                            <i className={"material-icons prefix"}></i>
                            <div className={"menu-content pointer "}>Payload</div>
                          </td>
                          <td className={"menu-td reporting " + (this.state.select == 4 ? "selected" : "")} onMouseOver={() => { this.mauseOver("reporting") }} onClick={() => { this.onclick("reporting") }}>
                            <i className={"material-icons prefix"}><LockIcon /></i>
                            <div className={"menu-content"}>
                              Reporting
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )}



                </>
              );
          }
      }
