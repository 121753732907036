import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();

export class PayloadAPi {

  contructor() { }

  getPayloadGraph() {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/payload/graph`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }


}
