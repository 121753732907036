import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BarTitleRtmpics.css"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export class BarTitleRtmpics extends Component {
  constructor(props) {
    super(props)
  }

  onClickBtn(e) {
    if (this.props.btnReturn) {
      this.props.btnReturn(e)
    }
  }

  componentWillMount() {
    //console.log("this.props.pagSelect : " , this.props.pagSelect);
  }

  render() {
    return (<>
      <div className={"row bar"}>
        {(this.props.btnReturn ?
          <div className={"col s1 bar-button"}>
            <a className={"btn bar-button-a"} onClick={(e) => { this.onClickBtn(e) }}>
              <i className={"bar-button-a-i material-icons "}><ArrowBackIosIcon /></i>
            </a>
          </div>
          :<div className={"col s1 offset bar-button"}><a className={"main-item white-text "}></a></div>
        )}
        <div className={"col s1 bar-title"}>{this.props.title}</div>
        <div className={"col s1 bar-title"}>{this.props.subtitle}</div>
      </div>
    </>)
  }
}
