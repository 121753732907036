import React, { Component } from "react";
import { Monitor } from "../../../modulos/Monitor/Monitor";
import { Map } from "../../../modulos/Map/Map";
import { Graphome } from "../../../modulos/GraficoHome/GraficoHome";
import "./Settings.css"
import { SettingsAPi } from "../../../../api/SettingsAPI";
import { Redirect } from "react-router";
import { Home } from "../Home/Home";
import { Auth } from "../../../../helpers/auth";
import DownloadLink from "react-download-link";

const auth = new Auth();
const settingsapi = new SettingsAPi();

export class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoaded: false,
          redirectAreaU: false,
          areaUid: 0,
          tolvaUid: 0,
          redirectTolvaU: false,
          redirectAreaC: false,
          redirectTolvaC: false,
          redirectShownodo:false,
          data: [],
          areaData: false,
          tolvaData: false,
          Vmax: "",
          Vmin: "",
          timer4: "",
          timer5: "",
          hora_inicio_jornada: "",
          Op_hours: "",
          target_tot_ciclos: "",
          id_config: "",
          id_faena: "",
          threshold_angle: "",
          threshold_impact: "",
          timeoutfoto:"",
          timerwaitingpics:""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitAreasC = this.handleSubmitAreasC.bind(this);
        this.handleSubmitTolvasC = this.handleSubmitTolvasC.bind(this);
        this.handleSubmitAreasU = this.handleSubmitAreasU.bind(this);
        this.handleSubmitTolvasU = this.handleSubmitTolvasU.bind(this);
        this.handleSubmitShowNodo = this.handleSubmitShowNodo.bind(this);
        this.handleSubmitCommandSendWifi = this.handleSubmitCommandSendWifi.bind(this);
    }

    handleSubmitShowNodo(id) {
      //alert (id);
      this.setState({
        redirectShownodo: true
      })
    }

    handleSubmitAreasC(id) {
      //alert (id);
      this.setState({
        redirectAreaC: true
      })
    }

    handleSubmitTolvasC(id) {
      //alert (id);
      this.setState({
        redirectTolvaC: true
      })
    }

    handleSubmitAreasU(id) {
      //alert (id);
      this.setState({
        redirectAreaU: true,
        areaUid: id
      })
    }

    handleSubmitTolvasU(id) {

      this.setState({
        redirectAreaC: false,
        redirectTolvaC: false,
        redirectAreaU: false,
        redirectTolvaU: true,
        tolvaUid: id
      })

    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    }

    handleSubmitCommandNodo(event) {
      event.preventDefault();
      const data = new FormData(event.target);
      const datos = {
        id_config: data.get('id_config'),
        id_faena: data.get('id_faena')
      };
      let datosjason = JSON.stringify(datos);
      settingsapi.UpdateNodoAreaSettings(datosjason).then(response => {
          alert ("Se han actualizado correctamente los datos.")
      }).catch(err => {
          alert ("Ha ocurrido un error al actualizar los datos.")
          console.log(err)
      })

    }

    handleSubmitCommandSendRT(id_nodo) {
      const datos = {
        id_nodo: id_nodo
      };
      let datosjason = JSON.stringify(datos);
      settingsapi.CommandSendTramaRt(datosjason).then(response => {

        var url = process.env.PUBLIC_URL + '/docs/' + response.pathfile;
        //var url = 'https:\\nexusmine.cloud' + '/docs/' + response.pathfile;
        //console.log("-->", url)
        window.open(url, '_blank');
      }).catch(err => {
          alert ("Ha ocurrido un error al consulatar datos.")
          console.log(err)
      })
    }

    handleSubmitCommandSendCiclo(id_nodo) {
      const datos = {
        id_nodo: id_nodo
      };
      let datosjason = JSON.stringify(datos);
      settingsapi.CommandSendCiclo(datosjason).then(response => {
        var url = process.env.PUBLIC_URL + '/docs/' + response.pathfile;
        //var url = 'https:\\nexusmine.cloud' + '/docs/' + response.pathfile;
        //console.log("-->", url)
        window.open(url, '_blank');
      }).catch(err => {
          alert ("Ha ocurrido un error al consultar datos.")
          console.log(err)
      })
    }

    handleSubmitCommandSendWifi(id_nodo) {
      const datos = {
        id_nodo: id_nodo
      };
      let datosjason = JSON.stringify(datos);
      settingsapi.CommandSendWifi(datosjason).then(response => {
        alert ("Comando Enviado.");
        //console.log(response);
      }).catch(err => {
          alert ("Ha ocurrido un error al actualizar los datos.")
          console.log(err)
      })
    }

    handleSubmit(event) {
      event.preventDefault();
      const data = new FormData(event.target);
      const datos = {
        Vmax: data.get('Vmax'),
        Vmin: data.get('Vmin'),
        timer4: data.get('timer4'),
        timer5: data.get('timer5'),
        hora_inicio_jornada: data.get('hora_inicio_jornada'),
        Op_hours: data.get('Op_hours'),
        target_tot_ciclos: data.get('target_tot_ciclos'),
        id_config: data.get('id_config'),
        id_faena: data.get('id_faena'),
        threshold_angle: data.get('threshold_angle'),
        threshold_impact: data.get('threshold_impact')
      };
      let datosjason = JSON.stringify(datos);
      settingsapi.UpdateSettings(datosjason).then(response => {
          alert ("Se han actualizado correctamente los datos.")
      }).catch(err => {
          alert ("Ha ocurrido un error al actualizar los datos.")
          console.log(err)
      })
    }

    PhothohandleSubmit(event) {
      event.preventDefault();
      const data = new FormData(event.target);
      const datos = {
        id_config: data.get('id_config'),
        id_faena: data.get('id_faena'),
        timeoutfoto:data.get('timeoutfoto'),
        timerwaitingpics:data.get('timerwaitingpics'),
      };
      let datosjason = JSON.stringify(datos);
      settingsapi.UpdateSettings(datosjason).then(response => {
          alert ("Se han actualizado correctamente los datos.")
      }).catch(err => {
          alert ("Ha ocurrido un error al actualizar los datos.")
          console.log(err)
      })
    }

    componentWillMount() {

      let dataauth = auth.getData();
      this.setState({
        id_faena: dataauth.perfil_id_faena
      })

        if (!this.refreshdata) {
            settingsapi.getSETTINGS().then(response => {
              if(response.status !=false){
                this.setState({
                  isLoaded: true,
                  data: response,
                  Vmax: response.Vmax,
                  Vmin: response.Vmin,
                  timer4: response.timer4,
                  timer5: response.timer5,
                  hora_inicio_jornada: response.hora_inicio_jornada,
                  Op_hours: response.Op_hours,
                  target_tot_ciclos: response.target_tot_ciclos,
                  id_config: response.id_config,
                  threshold_angle: response.threshold_angle,
                  threshold_impact: response.threshold_impact,
                  timeoutfoto:response.timeoutfoto,
                  timerwaitingpics:response.timerwaitingpics
                });
              }
              settingsapi.getSETTINGSArea().then(response => {
                if(response.status != false){
                  this.setState({
                    areaData: response
                    });
                }
                settingsapi.getSETTINGSTolva().then(response => {
                  if(response.status != false ){
                    this.setState({
                      tolvaData: response
                    });
                  }
                })
              })
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    renderArea(){
      const AreaTD = ({ Nombre_area, descripcion, tipo_area, latitud, longitud, diametro, id }) => (
        <tr >
            <td>{Nombre_area}</td>
            <td>{descripcion}</td>
            <td>{tipo_area}</td>
            <td>{latitud}</td>
            <td>{longitud}</td>
            <td>{diametro}</td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitAreasU(id)}}>Edit</button></td>
        </tr>
      );
      if (this.state.areaData != false){
        return(
          this.state.areaData.map((dta) => (
            <AreaTD
              id={dta.id}
              Nombre_area={dta.Nombre_area}
              descripcion={dta.descripcion}
              tipo_area={dta.tipo_area}
              latitud={dta.latitud}
              longitud={dta.longitud}
              diametro={dta.diametro}
            />
          ))
        )
      }else{
        return(
          <tr><td>No associated areas.</td></tr>
        )
      }
    }

    renderTolva(){
      const TolvaTD = ({ Id_Caex, modelo, angulo_descarga, id }) => (
        <tr >
            <td>{Id_Caex}</td>
            <td>{modelo}</td>
            <td>{angulo_descarga}</td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasU(id)}}>Edit</button></td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitCommandSendRT(id)}}>TramaRT</button></td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitCommandSendCiclo(id)}}>Ciclo</button></td>
       </tr>
      );
      if (this.state.tolvaData != false){
        return(
          this.state.tolvaData.map((dta) => (
            <TolvaTD
              id={dta.id}
              Id_Caex={dta.Id_Caex}
              modelo={dta.modelo}
              angulo_descarga={dta.angulo_descarga}
            />
          ))
        )
      }else{
        return(
          <tr><td>No associated trucks.</td></tr>
        )
      }
    }

    renderTolvaAng(){
      const TolvaTD = ({ Id_Caex, modelo, id }) => (
        <tr >
            <td>{Id_Caex}</td>
            <td>{modelo}</td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasU(id)}}>Edit</button></td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitCommandSendRT(id)}}>TramaRT</button></td>
       </tr>
      );
      if (this.state.tolvaData != false){
        return(
          this.state.tolvaData.map((dta) => (
            <TolvaTD
              id={dta.id}
              Id_Caex={dta.Id_Caex}
              modelo={dta.modelo}
              angulo_descarga={0}
            />
          ))
        )
      }else{
        return(
          <tr><td>No associated trucks.</td></tr>
        )
      }
    }

    renderTolvaWFI(){
      const TolvaTD = ({ Id_Caex, modelo, id }) => (
        <tr >
            <td>{Id_Caex}</td>
            <td>{modelo}</td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasU(id)}}>Edit</button></td>
            <td class="col_edit"><button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitCommandSendWifi(id)}}>Activate</button></td>
       </tr>
      );
      if (this.state.tolvaData != false){
        return(
          this.state.tolvaData.map((dta) => (
            <TolvaTD
              id={dta.id}
              Id_Caex={dta.Id_Caex}
              modelo={dta.modelo}
              angulo_descarga={0}
            />
          ))
        )
      }else{
        return(
          <tr><td>No associated trucks.</td></tr>
        )
      }
    }

    renderSetting(){
      if (this.state.isLoaded == true) {
        return(
          <div>

    {auth.restrics('FAE-GIN')
      ?<div>
      <h2>Site configuration Data </h2>
      <div class="user-detail">
          <form onSubmit={this.handleSubmit} id="formConfig">
                <p><label>Maximum speed (km/h)</label>
                <input type="text" placeholder="Enter maximum speed" name="Vmax" value={this.state.Vmax} onChange={this.handleInputChange} required /></p>
                <p><label>Motion detection threshold (km/h)</label>
                <input type="text" placeholder="Enter threshold" name="Vmin" value={this.state.Vmin} onChange={this.handleInputChange} required /></p>
                <p><label>Maximum stop time (min) </label>
                <input type="text" placeholder="Enter time" name="timer4" value={this.state.timer4} onChange={this.handleInputChange} required /></p>
                <p><label>Maximum fueling stop time  (min)</label>
                <input type="text" placeholder="Enter time" name="timer5" value={this.state.timer5} onChange={this.handleInputChange} required /></p>
                <p><label>Start working day (hh:mm:ss)</label>
                <input type="text" placeholder="Enter hour" name="hora_inicio_jornada" value={this.state.hora_inicio_jornada} onChange={this.handleInputChange} required /></p>
                <p><label>Working hours (hh)</label>
                <input type="text" placeholder="Enter number of hour" name="Op_hours" value={this.state.Op_hours} onChange={this.handleInputChange} required /></p>
                <p><label>Treshold Impact</label>
                <input type="text" placeholder="Treshold Impact" name="threshold_impact" value={this.state.threshold_impact} onChange={this.handleInputChange} required /></p>
                <p><label>Treshold angle</label>
                <input type="text" placeholder="Treshold Impact" name="threshold_angle" value={this.state.threshold_angle} onChange={this.handleInputChange} required /></p>
                <p><label>Target cycles per day</label>
                <input id="target_tot_ciclos" type="text" placeholder="Enter number of cycles" name="target_tot_ciclos" value={this.state.target_tot_ciclos}  onChange={this.handleInputChange} /></p>
                <input type="hidden" id="id_config" name="id_config" value={this.state.id_config} />
                <input type="hidden" id="id_faena" name="id_faena" value={this.state.id_faena} />
                <center>
                  <input type="submit" value="Save"  />
                </center>
          </form>
          </div>
    </div>
      :<div></div>
  }


  {auth.restrics('FAE-MEL')
    ?
    <div>
      <h2>Site Configuration Data </h2>
      <div class="user-detail">
          <form onSubmit={this.PhothohandleSubmit} id="formConfig">
            <p><label>Waiting Time new pics(min)</label>
            <input type="text" placeholder="Waiting Time new pics" name="timerwaitingpics" value={this.state.timerwaitingpics} onChange={this.handleInputChange} required /></p>
            <p><label>Time out take pics (sec)</label>
            <input type="text" placeholder="Time out take pics" name="timeoutfoto" value={this.state.timeoutfoto} onChange={this.handleInputChange} required /></p>
            <input type="hidden" id="id_config" name="id_config" value={this.state.id_config} />
            <input type="hidden" id="id_faena" name="id_faena" value={this.state.id_faena} />
            <center><input type="submit" value="Save"  /></center>
          </form>
      </div>
    </div>
    :<div></div>
  }





          </div>
        )
      }else{
        return(
          <div style={{margin:'20px'}}>
            <h2>Site configuration Data </h2>
            <div class="user-detail">
              <form onSubmit={this.handleSubmit} id="formConfig">
                Configuration not registered
              </form>
            </div>
          </div>
        )
      }
    }

    render() {
      if (this.state.redirectTolvaU){
        return <Redirect to={{pathname: "/tolvaupdate", state: { id: this.state.tolvaUid }}}  />
      }else if (this.state.redirectAreaU) {
          return <Redirect to={{pathname: "/areaupdate", state: { id: this.state.areaUid }}}  />
      }else if (this.state.redirectAreaC){
        return <Redirect to={{pathname: "/areacreate", state: { id: this.state.id_faena }}}  />
      }if (this.state.redirectTolvaC){
        return <Redirect to={{pathname: "/tolvacreate", state: { id: this.state.id_faena }}}  />
      }if (this.state.redirectShownodo){
        return <Redirect to={{pathname: "/shownodo", state: { id: this.state.id_faena }}}  />
      }
        return (
            <>
            <div style={{margin:'20px'}}>
            {this.renderSetting()}
            <table>
            <tbody></tbody>
             </table>


             {(auth.authoriza('SYS-DEV')
               ?
               <div>
               <h2>Save data to node</h2>
               <div class="user-detail">
                  <form onSubmit={this.handleSubmitCommandNodo} id="formConfig">
                    <input type="hidden" id="id_config" name="id_config" value={this.state.id_config} />
                    <input type="hidden" id="id_faena" name="id_faena" value={this.state.id_faena} />
                    <center><input class="btn waves-effect waves-green" type="submit" value="Submit"  /></center>
                  </form>
                </div>
                </div>
               :""
             )}

             {!auth.restrics('NOT-NIF')?
               <div>
               <h2>Nodo information</h2>
               <div class="user-detail">
                  <form id="formShowNodo">
                    <input type="hidden" id="id_faena" name="id_faena" value={this.state.id_faena} />
                    <center>
                      <button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitShowNodo(this.state.id_faena)}}>Nodo information</button>
                    </center>
                  </form>
                </div>
                </div>
              :<div></div>
            }

            {!auth.restrics('NOT-ARE')
              ?
              <div>
                <h2>Area configuration</h2>
                <button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitAreasC(this.state.id_faena)}}>Create Area</button>
                  <div class="datagrid">
                    <table >
                      <thead>
                        <tr>
                            <th>Area Name</th>
                            <th>Description</th>
                            <th>Area Type</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>Diameter</th>
                            <th>Edit</th>
                        </tr>
                      </thead>
                    <tbody>
                      {this.renderArea()}
                      </tbody>
                    </table>
                  </div>
              </div>
              :
              <div></div>
            }

            {auth.authoriza('AUT-WFI')
              ?
              <div>
                <h2>Trucks configuration</h2>
                <button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasC(this.state.id_faena)}}>Create Truck</button>
                  <div class="datagrid">
                    <table>
                      <thead>
                        <tr>
                            <th>Id Truck</th>
                            <th>Model</th>
                            <th>Edit</th>
                            <th>Comunication</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderTolvaWFI()}
                      </tbody>
                    </table>
                  </div>
              </div>
              :<div></div>
            }

            {auth.authoriza('AUT-FLE')
              ?
              <div>
                <h2>Trucks configuration</h2>
                <button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasC(this.state.id_faena)}}>Create Truck</button>
                  <div class="datagrid">
                    <table>
                      <thead>
                        <tr>
                          <th>Id Truck</th>
                          <th>Model</th>
                          <th>Angle</th>
                          <th>Edit</th>
                          <th>File</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderTolva()}
                      </tbody>
                    </table>
                  </div>
              </div>
              :<div></div>
            }

            {auth.restrics('NOT-ANG')
                ?
                <div>
                  <h2>Trucks configuration</h2>
                  <button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasC(this.state.id_faena)}}>Create Truck</button>
                    <div class="datagrid">
                      <table>
                        <thead>
                          <tr>
                              <th>Id Truck</th>
                              <th>Model</th>
                              <th>Edit</th>
                              <th>File</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderTolvaAng()}
                        </tbody>
                      </table>
                    </div>
                </div>
                :<div>
                {/*
                <div>
                  <h2>Trucks configuration</h2>
                  <button className={"btn waves-effect waves-green"} onClick={(res) => {this.handleSubmitTolvasC(this.state.id_faena)}}>Create Truck</button>
                    <div class="datagrid">
                      <table>
                        <thead>
                          <tr>
                              <th>Id Truck</th>
                              <th>Model</th>
                              <th>Angle</th>
                              <th>Edit</th>
                              <th>File</th>
                              <th>File</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderTolva()}
                        </tbody>
                      </table>
                    </div>
                </div>*/}
                </div>
              }
              <div class="footerSetting"></div>
          </div>
        </>)
    }
};
