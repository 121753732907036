import React, { Component } from "react";
import { Monitor } from "../../../modulos/Monitor/Monitor";
import { MonitorGinGin } from "../../../modulos/MonitorGINGIN/Monitor";
import { Map } from "../../../modulos/MapRtm/Map";
import "./RTM.css"
import { HomeAPi } from "../../../../api/HomeAPI";
import { Redirect } from "react-router";
import { Auth } from "../../../../helpers/auth";

const auth = new Auth();
const homeapi = new HomeAPi();
export class RTM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new Array([]), //Previene error Unhandled Rejection (TypeError) new Array([])
      route: true
    }
    this.getDataTruck = this.getDataTruck.bind(this);
  }

  componentWillMount() {
      this.getDataTruck();
  }

  getDataTruck() {
    if (!this.refreshdata) {
      homeapi.getMRT().then(response => {
        if (response.status) this.setState({ data: response.data});
          this.refreshdata = setInterval(() => {
            homeapi.getMRT().then(response => {
              if (response.status) this.setState({ data: response.data});
            }).catch(err => {
              console.log(err)
          })
        }, 10000);
      }).catch(err => {
        console.log(err)
      })
    }

  }

  componentWillUnmount() {
    clearInterval(this.refreshdata)
  }

  renderMonitor(){
    if(!auth.restrics('FAE-GIN')){
      return (
        this.state.data.map((value, index) => {
          return (
              <td className={"home-monitor"}>
                <Monitor value={value} className={"home-monitor-barra-tr-monitor"}/>
              </td>
          );
        }));
    }else{
      return (
        this.state.data.map((value, index) => {
          return(
            <td className={"home-monitor"}>
              <MonitorGinGin value={value} className={"home-monitor-barra-tr-monitor"}/>
            </td>
          );
        }));
    }
  }

  render() {
    return (
      <>
        <div className={"home-component"}>
          <div className={"row home-component-row"}>
            <div className={"col s12 home-mapRtm"}>
              <Map
                trucks={this.state.data}
                route={this.state.route}
              />
            </div>
            <div className="col s12 home-monitor-barra">
              <table className={"home-monitor-barra-table"} >
                <tr className={"home-monitor-barra-tr"}>
                    {this.renderMonitor()}
                </tr>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
};

/*
<div className="col s12 home-monitor-barra">
    <div className={"row "}>
        {this.state.data.map((value, index) => {
            return <div className={"col s11"}> <Monitor value={value} className={"home-monitor-barra-tr-monitor"} /></div>
        })}
    </div>
</div>
*/
