import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Availability.css"
import Chart from "react-apexcharts";

export class Availability extends Component {
    constructor(props) {
        super(props)
        this.state = {
            animation: {
                duration: 0,
            },
            colors: ['#548235', '#24ef7c'],
            labels: ['Availability', 'Unavailable'],
            hover: {
                animationDuration: 0,
            },
            responsiveAnimationDuration: 0,

            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                color: undefined,
                                offsetY:  0,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                                color: undefined,
                                offsetY: 0,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            total: {
                                show: true,
                                showAlways: false,
                                label: 'Total',
                                fontSize: '22px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                color: 'white',
                                formatter: function (w) {
                                    return w.globals.seriesTotals[0] + " %"
                                }
                            }
                        }
                    }
                }
            },
            title: {
                display: true,
                align: "center",
                text: (props.title ? props.title : ""),
                style: {
                    color: "white"
                }
            },

        }
    }

    onClickBtn(e) {
        if (this.props.onClickbtnReturn) {
            this.props.onClickbtnReturn(e)
        }
    }

    render() {
        return (<>
            <div className={"row aval"}>
                <div className={"col s6 offset aval-overview"}><div className={"aval-overview-color"}>Occupancy - average</div></div>
                <div className={"col s12 col-aval-graph"}>
                    <table className={"aval-graph-table"} >
                        <tr className={"aval-graph-tr"}>
                            {(this.props.series ? this.props.series.map((serie) => {
                                return <td className={"aval-graph-td"}><Chart
                                    options={Object.assign(Object.assign({}, this.state), {
                                        title: {
                                            display: true,
                                            align: "center",
                                            text: serie.name,
                                            style: {
                                                color: "white"
                                            }
                                        },
                                    })}
                                    type="donut"
                                    series={serie.value}
                                    width="90%"
                                    height="80%"
                                /> </td>
                            }) : null)}
                        </tr>
                    </table>
                </div>
            </div>
        </>
        )
    }
}
