import React, { Component } from "react";
import "./ListPics.css"
import { HomeAPi } from "../../../../api/HomeAPI";
import { Table1 } from "./modules/Table1/Table1";
import { Table2 } from "./modules/Table2/Table2";
//import { Board } from "./modules/Board/Board";
import { CycleAnalyticsAPi } from "../../../../api/CycleAnalyticsApi";

const homeapi = new HomeAPi();
const cycleapi = new CycleAnalyticsAPi();

export class ListPics extends Component {
    columns = [
        {
            name: 'id',
            selector: 'id_nodo',
            sortable: true,
            omit: true,
            center:true
        },
        {
            name: 'Truck #',
            selector: row => row.tolva_id_caex,
            sortable: true,
            center:true,
        },
    ];
    data = [ ];

    constructor(props) {
        super(props);
        this.state = {
            data: this.data,
            view: 'table1',
            selectedRow: {},
        }
    }

    componentWillMount() {
      this.updateData();
   }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    updateData() {
      const date = new Date();
      cycleapi.getTable(date).then((response) => {
        if (response.status){
          this.setState({ data: response.data[0] });
        }
      }).catch((e) => { console.log(e) });
    }

    datatableSelect1(data) {
      this.setState({ view: 'table2', selectedRow: data })
    }

    datatableSelect2(data) {
      console.log("data2 " , data);
      this.setState({ view: 'table2', selectedRow: data })
    }

    boardreturn(e) {
        this.setState({ view: 'table1' })
    }

    unitreturn(e) {
      this.setState ({ view: 'table1'})
    }

    fleetreturn(e) {
      this.setState ({ view: 'table1'})
    }

    boardreturn(e) {
        this.setState({ view: 'table1' })
    }

    renderSwitch() {
      switch(this.state.view) {
        case 'table2':
        return (
                <Table2
                  idNodo = {this.state.selectedRow.id_nodo}
                  btnreturn={(e) => { this.boardreturn(e) }}
                  onRowClicked={(e) => { this.datatableSelect2(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
                />
              )
        case 'table1':
          return (
                  <Table1
                    data={this.state.data}
                    columns={this.columns}
                    onRowClicked={(e) => { this.datatableSelect1(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
                  />
                )
        default:
          return 'foo';
      }
    }

    render() {
      return (
        <div>
          <div>{this.renderSwitch()}</div>
        </div>
      );
    };
}
