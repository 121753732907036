import { Component } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import M from "materialize-css";
import "./Table.css";

import { BoxCycleAnalytics }      from "../../../../../modulos/BoxCycleAnalytics/BoxCycleAnalytics"
import { BarTitle }               from "../../../../../modulos/BarTitle/BarTitle";
import { Payload }                from "../../../../../modulos/Payload/Payload"
import { GraficoPayload }         from "../../../../../modulos/GraficoPayload/GraficoPayload"
import { UnitAPi }                from "../../../../../../api/UnitAPI";
import { PayloadAPi }             from "../../../../../../api/PayloadAPI";

const unitapi = new UnitAPi();
const payloadapi = new PayloadAPi();

export class Table extends Component {
  subtitle = ["Fleet analytics", "Unit analytics"]

  constructor(props) {
      super(props)
      const date = new Date();
      this.state = {
        data: false,
        dateSelected: date,
        selectedOption: "1",
        data_labels: false,
        series_payload: false
      }
      createTheme("darkk", {
          text: {
              primary: '#59677a',
              secondary: '#59677a',
          },
          background: {
              default: '#191f27',
          },
          context: {
              background: '#cb4b16',
              text: '#FFFFFF',
          },
          divider: {
              default: '#4d4a52',
          },
          action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
          },
      })
    }

    onClickbtnReturn(event) {
        if (this.props.btnreturn) {
            this.props.btnreturn(event)
        }
    }

    onClickbtnFleetReturn(event) {
        if (this.props.btnfleetreturn) {
            this.props.btnfleetreturn(event)
        }
    }

    onClickbtnUnitReturn(event) {
        if (this.props.btnunitreturn) {
            this.props.btnunitreturn(event)
        }
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))
          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }
        return (hour).toLocaleString('en') + "h " + minutes + "m"
    }

    updateData(date = null, interval = "h") {
        payloadapi.getPayloadGraph().then((response) => {
          if (response.status) {
            let ltruck = [];
            let lavg_payload = [];
            let ltarget_payload = [];
            for(const g of response.data){
                ltruck.push(g.truck);
                lavg_payload.push(g.average_payload);
                ltarget_payload.push( g.target_payload / 1000 )
            }

            this.setState({
                data_labels: ltruck,
                series_payload: [{
                  name: 'Average payload',
                  type: 'column',
                  data: lavg_payload
                },
                {
                  name: 'Target payload',
                  type: 'line',
                  data: ltarget_payload
                }]
              })

          }
        });

        /*unitapi.getBoardByIdNodo(2, this.state.dateSelected,  interval).then((response) => {
            if (response.status) {
                console.log(response.data)
                let payload = [];
                let tt = [];
                let date_x;
                let graph = {}
                for (const g of response.data.graph) {
                    graph[new Date(g.date).getTime()] = g
                }
                for (const gk of Object.keys(graph).sort()) {
                    console.log(this.state.intervalSelected)
                    if (this.state.intervalSelected == "h") {
                        date_x = new Date(graph[gk].date).toLocaleTimeString().substring(0, 5)
                    } else if (this.state.intervalSelected == "d") {
                        date_x = new Date(graph[gk].date).toDateString().split(" ")
                        date_x = date_x[1] + "  " + date_x[2];
                    } else if ((this.state.intervalSelected == "m")||
                               (this.state.intervalSelected == "y")||
                               (this.state.intervalSelected == "1y")||
                               (this.state.intervalSelected == "2y")){
                        date_x = new Date(graph[gk].date).toDateString().split(" ")
                        date_x = date_x[1] + ", " + date_x[3]
                    }
                    //  console.log(date_x)
                    payload.push({
                        x: date_x,
                        y: Math.round(graph[gk].payload)
                    })
                    tt.push({
                        x: date_x,
                        y: Math.round(graph[gk].tt)
                    })
                }
                if (response.status) this.setState({
                    data: response.data,
                    graph_payload: payload,
                    graph_tt: tt
                });
            }
        });*/
    }

    componentWillMount() {
        if (!this.state.refreshdata) this.updateData()
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshdata)
    }

    datatableSelect(e) {
        if (this.props.onRowClicked) {
          this.props.onRowClicked(e)
        }
    }

    render() {
      return (
        <div className={"table-component"}>
          <div className={"row table-component-row"}>
            <div className={"col s12 fleet-title"}>
              <BarTitle
                onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
                onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
                title={"FLEET"}
                subtitle={this.subtitle}
                pagSelect = "Fleet"
              />
            </div>
            <div className={"col s12 board-margin-bottom board-box-time "}>
                <BoxCycleAnalytics
                  TruckInOperation={ this.props.TruckInOperation}
                  MinutesInOperation={this.MinuteDecimalToString(this.props.MinutesInOperation)}
                  NCycles={this.props.NCycles}
                  SpeedingAlarms={this.props.SpeedingAlarms}
                  KmToday={this.props.KmToday?this.props.KmToday:0}
                />
            </div>
            <div className={"col s12 board-margin-bottom board-payload"}>
              <GraficoPayload
                legenda = {["Average payload", "Target payload"]}
                series = {this.state.series_payload ? this.state.series_payload: [] }
                labels = {this.state.data_labels ? this.state.data_labels: [] }
              />
            </div>
            <div className={"col s12 table-datatable"}>
                <DataTable
                    pagination
                    paginationPerPage="20"
                    theme="darkk"
                    columns={this.props.columns}
                    data={this.props.data}
                    selectableRows
                    onRowClicked={(e) => { this.datatableSelect(e) }}
                    pointerOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    noHeader
                    highlightOnHover
                    customStyles={{
                        rows: {
                            style: {
                              backgroundColor: '#191f27',
                              fontSize: '18px',
                              color: 'white',
                                minHeight: '30px', // override the row height
                                '&:not(:last-of-type)': {
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: '1px',
                                    borderBottomColor: '#8497B0',
                                },
                            }
                        },

                        pagination: {
                      		style: {
                      			color: '#8497B0',
                      			fontSize: '13px',
                      			minHeight: '56px',
                      			backgroundColor: '#191f27',
                      			borderTopStyle: 'solid',
                      			borderTopWidth: '1px',
                      			borderTopColor: '#8497B0',
                      		},
                      		pageButtonsStyle: {
                      			borderRadius: '50%',
                      			height: '40px',
                      			width: '40px',
                      			padding: '8px',
                      			margin: 'px',
                      			cursor: 'pointer',
                      			transition: '0.4s',
                      			color: '#8497B0',
                      			fill: '#8497B0',
                      			backgroundColor: 'transparent',
                      			'&:disabled': {
                      				cursor: 'unset',
                      				color: '#8497B0',
                      				fill: '#8497B0',
                      			},
                      			'&:hover:not(:disabled)': {
                      				backgroundColor: '#FFFFFF',
                      			},
                      			'&:focus': {
                      				outline: 'none',
                      				backgroundColor: '#FFFFFF',
                      			},
                      		},
                      	},


                        headRow: {
                          style: {
                            backgroundColor: '#191f27',
                            minHeight: '30px',
                            borderBottomWidth: '1px',
                            borderBottomColor: '#8497B0',
                            borderBottomStyle: 'solid',
                          },
                          denseStyle: {
                            minHeight: '32px',
                          },
                        },
                        headCells: {
                            style: {
                                fontSize: '16px',
                                color: 'white',
                                backgroundColor:'#191f27',
                                paddingLeft: '10px', // override the cell padding for head cells
                                paddingRight: '2px',
                            },
                            activeSortStyle: {
                                color: 'gray',
                                '&:focus': {
                                  outline: 'none',
                                },
                                '&:hover:not(:focus)': {
                                  color: 'gray',
                                },
                            },
                            inactiveSortStyle: {
                                '&:focus': {
                                  outline: 'none',
                                  color: 'green',
                                },
                                '&:hover': {
                                  color: 'gray',
                                },
                            },
                        },
                        cells: {
                            style: {
                                paddingLeft: '8px', // override the cell padding for data cells
                                paddingRight: '8px',
                            },
                        },
                        table: {
                            style: {
                                minHeight: '600px',
                                maxHeight: '600px'
                            }
                        }, noData: {
                            style: {
                                minHeight: '600px',
                                maxHeight: '600px'
                            }
                        }
                    }}
                />
            </div>
          </div>
        </div>
      );
    }
}
