import React from "react";
import { Login } from "./components/Login/Login";
import { Dashboard } from "./components/Dashboard/Dashboard";
import "materialize-css/dist/css/materialize.min.css";
import "./App.css";

//import "./components/Login/Login.css";
//import bg from "./assets/login/bg.jpg";
//import M from "materialize-css";

import { BrowserRouter as NativeRouter, Route, Redirect, Switch } from "react-router-dom";

//Icons
//import { AccountCircle, VpnKey } from "@material-ui/icons";



const App = () => {
    return (
        <>
            <NativeRouter>
                <div className="main-nexus">
                    <Route path="/login" component={Login} />
                    <Route path="/" component={Dashboard} />
                </div>
            </NativeRouter>

        </>
    );
};

export { App };
