import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();

export class AlarmsApi {

  contructor() {

  }

  getTableAlarms(id_user) {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/alarms/table/`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }

  getTableAlarmsRms(id_user) {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/alarms/tableRms/`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }

}
