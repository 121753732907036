import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./GraphBar.css"
import Chart from "react-apexcharts";

export class GraphBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: {
                grid: {
                    show: (props.grid != null ? props.grid : true),
                },
                chart: {
                    type: 'bar',
                    stacked: (props.stacked != null ? props.stacked : false),
                    toolbar: {
                        show: true
                    }
                },
                stroke: {
                    curve: 'smooth',
                },
                legend: {
                    show: true,
                    position: "right",
                    offsetY: (props.legendOffsetY ? props.legendOffsetY : 0),
                    labels: {
                        colors: "#ffffff"
                    },
                    itemMargin: {
                        vertical: (props.legendMargin ? props.legendMargin : 0),
                    },
                    inverseOrder: true,
                },
                xaxis: {
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        text: props.titleH
                    },
                    categories: (props.categories ? props.categories : []),
                },
                yaxis: [{
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        text: props.title
                    },
                    show: (props.yaxis != null ? props.yaxis : true),
                    max: (props.max ? Math.ceil(props.max + 1) : null),
                }],
                fill: {
                    opacity: 0.6
                },
                plotOptions: {
                    bar: {
                      columnWidth: '50%',
                        dataLabels: {
                            orientation: (props.orientationLabel ? props.orientationLabel : ""),
                            position: (props.positionLabel ? props.positionLabel : "center"), // top, center, bottom
                        },
                    }
                },
                colors: [
                    "#9D82EA","#8C1834", "#2E75B6","#047054", "#9D82EA",
                    "#203864","#203864", "#A994F6", "#00B050", "#2E75B6",
                    "#CC0000", "#1B998B", "#803092","#203864", "#A994F6",
                  ],
                dataLabels: {
                  background: {
                    opacity: 0.1,
                  },
                    enabled: true,
                    formatter: function (val) {
                        if (val == 0) {
                            return ""
                        }
                        return val;
                    },
                    offsetY: 10,
                    style: {
                        fontSize: '12px'
                    },

                },
            }
        }
    }
    componentDidUpdate() {
        if ((this.props.categories != undefined && this.state.options.xaxis.categories.length != this.props.categories.length) ||
            (this.props.max != undefined && Math.ceil(this.state.options.yaxis[0].max) != Math.ceil(this.props.max + 1))) {

            this.setState({
                options: {
                    grid: {
                        show: (this.props.grid != null ? this.props.grid : true),
                    },
                    chart: {
                        type: 'bar',
                        stacked: (this.props.stacked ? this.props.stacked : false),
                        toolbar: {
                            show: true
                        }
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    legend: {
                        show: true,
                        position: (this.props.legndPosition ? this.props.legndPosition : "left"),
                        offsetY: (this.props.legendOffsetY ? this.props.legendOffsetY : 0),
                        labels: {
                            colors: "#ffffff"
                        },
                        itemMargin: {
                            vertical: (this.props.legendMargin ? this.props.legendMargin : 0),
                        },
                    },
                    yaxis: [{
                        title: {
                            style: {
                                color: "#ffffff"
                            },
                            text: this.props.title
                        },
                        show: (this.props.yaxis != null ? this.props.yaxis : true),
                        max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
                    }],
                    xaxis: {
                        categories: (this.props.categories ? this.props.categories : []),
                    },
                    plotOptions: {
                        bar: {
                          columnWidth: '50%',
                            dataLabels: {
                                orientation: (this.props.orientationLabel ? this.props.orientationLabel : ""),
                                position: (this.props.positionLabel ? this.props.positionLabel : "center"), // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            if (val == 0) {
                                return ""
                            }
                            return val;
                        },
                        offsetY: 10,
                        style: {
                            fontSize: '12px'
                        }
                    },
                }
            });
        }
    }

    render() {
        return (<>
            <div className={"row graphbar"}>
                <div className={"col s12 graphbar-title"} style={{ background: "#191f27" }}>
                    {this.props.title2 || ""}
                </div>
                <div className={"col s12 graphbar-graph-row-col"}  style={{ background: "#191f27" }}>
                    <Chart
                        options={this.state.options}
                        series={this.props.series}
                        type="bar"
                        height={this.props.subTitle || this.props.height ? (this.props.height ? this.props.height : "115%") : "100%"}
                        width="100%"
                    />
                </div>
            </div>
        </>
        )
    }
}
