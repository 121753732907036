import React, { Component, useEffect } from "react";
import "./SelectorDateGinGin.css";
import "materialize-css/dist/css/materialize.min.css";

export class SelectorDateGinGin extends Component {

    constructor(props) {
        super(props)
        if (this.props.checked) {
            this.state = { selectedOption: this.props.checked.toString() }
        } else {
            this.state = {
                selectedOption: "1"
            }
        }
    }

    onChangeValue(e) {
        let date = new Date();
        let interval = "1d";
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        if (e.target.value == 1) {//OPCION 1 DIA
            interval="1d";
        } else if (e.target.value == 2) {//OPCION 2 DIAS
            interval = "-1d";
        } else if (e.target.value == 3) {//OPCION 1 SEMANA
            interval = "1w";
        } else if (e.target.value == 4) {//OPCION 1 MES
            interval = "1m";
        }
        this.setState({
            selectedOption: e.target.value,
            interval:interval })
        if (this.props.onChange) {
            this.props.onChange(date, interval)
        }
    }

    componentDidUpdate() {
        if (this.props.checked) {
            this.state.selectedOption = this.props.checked.toString();
        }
    }

    render() {
        return (<>
            <div className={"row selector-date-select"}>
                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="1" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "1"} />
                        <span className={"selector-date-radio-text"} >1D</span>
                    </label>

                </div>
                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="2" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "2"} />
                        <span className={"selector-date-radio-text"} >-1D</span>
                    </label>
                </div>
                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="3" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "3"} />
                        <span>1W</span>
                    </label>
                </div>
                <div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="4" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "4"} />
                        <span>1M</span>
                    </label>
                </div>
                  {/*<div className={"col s1 selector-date-date-range"} >
                    <label>
                        <input className={"selector-date-radio"} name="group1" type="radio" value="7" onChange={(e) => { this.onChangeValue(e) }} checked={this.state.selectedOption === "7"} />
                        <span>2Y</span>
                    </label>

                </div>*/}

            </div>
        </>
        );
    }
}
