import React, { Component } from "react";
import "./Asset.css"
import { HomeAPi }            from "../../../../api/HomeAPI";
import { Table }              from "./modules/Table/Table";
import { Board }              from "./modules/Board/Board";
import { UnitAPi }            from "../../../../api/UnitAPI";
import { CycleAnalyticsAPi }  from "../../../../api/CycleAnalyticsApi";
import { AssetsAPi }  from "../../../../api/AssetsAPi";

const homeapi = new HomeAPi();
const unitapi = new UnitAPi();
const cycleapi = new CycleAnalyticsAPi();
const assetsapi = new AssetsAPi();

export class Asset extends Component {
  columns = [
      {
          name: 'id',
          selector: 'id_nodo',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.tolva_id_caex,
          sortable: true,
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.tolva_id_caex > 300,
              style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'green',
                '&:hover': { cursor: 'pointer', },
              },
            },
          ],
      },
      {
          name: 'Battery level (%)',
          selector: 'Battery_level',
          sortable: true,
          center:true
      },
      {
          name: 'Thickness',
          selector: 'Thickness',
          sortable: true,
          center:true
      },
      {
          name: 'Next maintenance',
          selector: 'wear_next_maintenance',
          sortable: true,
          center:true
      },
  ];
  data = [ ];

  constructor(props){
      super(props);
      this.state = {
          data: this.data,
          view: 'table',
          selectedRow: {},
          batery_level: 0,
          sat_in_use: 0,
          avg_payload: 0
      }
  }

  componentWillMount() {
    this.updateData();
  }

  componentWillUnmount(){
    clearInterval(this.refreshdata)
  }

  updateData() {
    let datosTable = [];
    cycleapi.getBox().then((response) => {
      if (response.status){
        const date = new Date();
        assetsapi.getDataWear().then((response) => {
          this.setState({ data: response.data[0] });
          });
      }

      this.refreshdata = setInterval(() => {
        cycleapi.getBox().then((response) => {
          if (response.status){
            const date = new Date();
            assetsapi.getDataWear().then((response) => {
              this.setState({ data: response.data[0] });
              });
          }
        }).catch((e) => { console.log(e) });
      }, 50000);
    }).catch((e) => { console.log(e) });

  }

  boardreturn(e) {
      this.setState({ view: 'table' })
  }

  unitreturn(e) {
    this.setState ({ view: 'table'})
  }

  renderSwitch() {
    switch(this.state.view) {
      case 'board':
        return (
          <Board
            btnreturn={(e) => { this.boardreturn(e) }}
            btnunitreturn={(e) => { this.unitreturn(e) }}
            data={this.state.selectedRow}
          />);
      case 'table':
      return (
              <Table
                btnreturn={(e) => { this.boardreturn(e) }}
                btnunitreturn={(e) => { this.unitreturn(e) }}
                data={this.state.data}
                columns={this.columns}
                onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
              />
            )
      default:
        return 'foo';
    }
  }

  datatableSelect(data) {
      this.setState({ view: 'board', selectedRow: data })
  }

  boardreturn(e) {
      this.setState({ view: 'table' })
  }

  unitreturn(e) {
    this.setState ({ view: 'table'})
  }

  render() {
    return (
      <div>
        <div>{this.renderSwitch()}</div>
      </div>
    );
  };

}
