import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Board.css"
import { BarTitleAssets }     from "../../../../../modulos/BarTitleAssets/BarTitleAssets";
import { TableWear }          from "../../../../../modulos/TableWear/TableWear"
import { TolvaWear }          from "../../../../../modulos/TolvaWear/TolvaWear"
import { AssetsAPi }          from "../../../../../../api/AssetsAPi";

const assetsapi = new AssetsAPi();

export class Board extends Component {

  constructor(props) {
    super(props);
    this.state = {
      monitor: false,
      refreshdata: false,
    }
  }

  componentWillMount() {
      if (!this.state.refreshdata) {
        this.updateData();
        assetsapi.getTableMesh(this.props.data.id_nodo).then(response => {
          if (response.status){
            let registro = []
            let datos = response.data[0];
            for (let i = 1; i <= 9; i++) {
              registro.push({
                  position: "P" + i,
                  sensor: datos["P" + i + "_idMac"],
                  battery: (datos["P" + i + "_comm"] == 1 ? datos["P" + i + "_battery"] + "%" : "-"),
                  battery_status: (datos["P" + i + "_battery"] > datos.battery_threshold ? "ok" :"warning"),
                  connection_status: (datos["P" + i + "_comm"] == 1 ? "on" : "off"),
                  barvalue: ((100 * parseInt(datos["P" + i + "_wear"])) / datos.thickness_max ),
                  showvalue: (parseInt(datos["P" + i + "_wear"]) > 0 ? datos["P" + i + "_wear"] + "mm" : "Fault" ),
                  thicknessstatus: ( parseInt(datos.thickness_threshold) < parseInt(datos["P" + i + "_wear"]) ? "ok" : "alarm" )
              })
            }
            this.setState({ monitor: registro })
          }
        }).catch((e) => { console.log(e) });
        }
    }

  componentWillUnmount() {
      clearInterval(this.state.refreshdata)
  }

  updateData() {

    this.refreshdata = setInterval(() => {
      assetsapi.getTableMesh(this.props.data.id_nodo).then(response => {
        if (response.status){
          let registro = []
          let datos = response.data[0];
          for (let i = 1; i <= 9; i++) {
            registro.push({
                position: "P" + i,
                sensor: datos[0]["P" + i + "_idMac"],
                battery: (datos[0]["P" + i + "_comm"] == 1 ? datos[0]["P" + i + "_battery"] + "%" : "-"),
                battery_status: (datos[0]["P" + i + "_battery"] > datos[0].battery_threshold ? "ok" :"warning"),
                connection_status: (datos[0]["P" + i + "_comm"] == 1 ? "on" : "off"),
                barvalue: ((100 * parseInt(datos[0]["P" + i + "_wear"])) / datos[0].thickness_max ),
                showvalue: (parseInt(datos[0]["P" + i + "_wear"]) > 0 ? datos[0]["P" + i + "_wear"] + "mm" : "Fault" ),
                thicknessstatus: ( parseInt(datos[0].thickness_threshold) < parseInt(datos[0]["P" + i + "_wear"]) ? "ok" : "alarm" )
            })
          }
          this.setState({ monitor: registro })
        }
      }).catch((e) => { console.log(e) });
    }, 5000);
  }

  onClickbtnReturn(event) {
      if (this.props.btnreturn) {
          this.props.btnreturn(event)
      }
  }

  onClickbtnFleetReturn(event) {
      if (this.props.btnfleetreturn) {
          this.props.btnfleetreturn(event)
      }
  }

  onClickbtnUnitReturn(event) {
      if (this.props.btnunitreturn) {
          this.props.btnunitreturn(event)
      }
  }


  render() {
    return (<>
      <div className={"row"}>
        <div className={"row unitpayload-board_1"}>
          <div className={"col s12 unitpayload-BarTitle"}>
            <BarTitleAssets
              onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }}
              onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
              onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
              btnReturn title={this.props.data.tolva_id_caex}
              pagSelect= "Assets"
            />
          </div>
        </div>
        <div className={"row unitpayload-board_2"}>
          <div className={"col s6 tolva_box-iz "}>
            <div className={"row"}>
              <TableWear
                data = {this.state.monitor}
                />
            </div>
          </div>
          <div className={"col s6 tolva_box-der "}>
            <div className={"row"}>
              <TolvaWear
                data = {this.state.monitor}
                />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
