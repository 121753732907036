import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Board.css"
import { BarTitle }     from "../../../../../modulos/BarTitle/BarTitle";
import { SelectorDate } from "../../../../../modulos/SelectorDate/SelectorDate"
import { BoxTime }      from "../../../../../modulos/BoxTime/BoxTime"
import { BoxSpeed }     from "../../../../../modulos/BoxSpeed/BoxSpeed"
import { Donut }        from "../../../../../modulos/Donut/Donut"
import { Payload }      from "../../../../../modulos/Payload/Payload"
import { Cycle }        from "../../../../../modulos/Cycle/Cycle"
import { Monitor }      from "../../../../../modulos/Monitor/Monitor"
import { UnitAPi }      from "../../../../../../api/UnitAPI";
import DataTable, { createTheme } from "react-data-table-component";

const unitapi = new UnitAPi();

export class Board extends Component {

    columns = [
      {
          id: 'id',
          name: 'Cycle',
          selector: row => row.idCycle,
          sortable: true,
          center:true,
          style: {
              backgroundColor: '#323f4f',
          },
      },
        {
          id: 'toload',
            name: 'To load',
            selector: row => row.toload,
            format: row => this.FormatTimeTable(row.toload),
            sortable: true,
            center:true,
            conditionalCellStyles: [
              {
                when: row => row.toload > 500,
                style: {
                  color: 'red',
                  '&:hover': { cursor: 'pointer', },
                },
              },
            ],
        },
        {
          id: 'queuetoload',
            name: 'Queue to load',
            selector: row => row.queuetoload,
            format: row => this.FormatTimeTable(row.queuetoload),
            sortable: true,
            center:true
        },
        {
          id: 'loading',
            name: 'Loading',
            selector: row => row.loading,
            format: row => this.FormatTimeTable(row.loading),
            sortable: true,
            center:true
        },
        {
          id: 'todump',
            name: 'To dump',
            selector: row => row.todump,
            format: row => this.FormatTimeTable(row.todump),
            sortable: true,
            center:true,
            conditionalCellStyles: [
              {
                when: row => row.todump > 800,
                style: {
                  color: 'red',
                  '&:hover': { cursor: 'pointer', },
                },
              },
            ],
        },
        {
          id: 'queuetodump',
            name: 'Queue to dump',
            selector: row => row.queuetodump,
            format: row => this.FormatTimeTable(row.queuetodump),
            sortable: true,
            center:true
        },
        {
          id: 'dumping',
            name: 'Dumping',
            selector: row => row.dumping,
            format: row => this.FormatTimeTable(row.dumping),
            sortable: true,
            center:true
        },
        {
          id: 'totalcycletimesec',
            name: 'Total Cycle time (sec)',
            selector: row => row.totalcycletimesec,
            format: row => (row.totalcycletimesec).toLocaleString('en'),
            sortable: true,
            center:true
        },
        {
          id: 'totalcycletimemin',
            name: 'Total Cycle time (min)',
            selector: row => row.totalcycletimemin,
            sortable: true,
            center:true
        },
        {
          id: 'efficiency',
            name: 'Efficiency (%)',
            selector: row => row.efficiency,
            format: row => Number.parseFloat(row.efficiency).toFixed(2),
            sortable: true,
            center:true,
            conditionalCellStyles: [
              {
                when: row => row.efficiency > 70,
                style: {
                  color: '#15ff63',
                  '&:hover': { cursor: 'pointer', },
                },
              },
            ],
        },
    ];

    constructor(props) {
        super(props)
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMonth(date.getMonth() - 1)
        this.state = {
            monitor: false,
            dateSelected: date,
            intervalSelected: "d",
            graph_key: [],
            graph_payload: [],
            graph_tt: [],
            data: false,
            refreshdata: false,
            tabletimedata: []
        }
        createTheme("darkk", {
            text: {
                primary: '#59677a',
                secondary: '#59677a',
            },
            background: {
                default: '#191f27',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#4d4a52',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        })
    }

    componentWillMount() {
        if (!this.state.refreshdata) {
          this.updateData();
          }
      }

    componentWillUnmount() {
        clearInterval(this.state.refreshdata)
    }

    updateData(date = null, interval = "d") {
        unitapi.getBoardByIdNodo(this.props.data.id_nodo, date || this.state.dateSelected, interval).then((response) => {
            if (response.status ) {
                let payload = [];
                let tt = [];
                let date_x;
                let graph = {}

                if(response.data.length != 0){
                  for (const g of response.data.graph) {
                      graph[new Date(g.date).getTime()] = g
                  }
                }

                for (const gk of Object.keys(graph).sort()) {
                    if (this.state.intervalSelected == "h") {
                        date_x = new Date(graph[gk].date).toLocaleTimeString().substring(0, 5)
                    } else if (this.state.intervalSelected == "d") {
                        date_x = new Date(graph[gk].date).toDateString().split(" ")
                        date_x = date_x[1] + "  " + date_x[2];
                    } else if ((this.state.intervalSelected == "m")||
                               (this.state.intervalSelected == "y")||
                               (this.state.intervalSelected == "1y")||
                               (this.state.intervalSelected == "2y")){
                        date_x = new Date(graph[gk].date).toDateString().split(" ")
                        date_x = date_x[1] + ", " + date_x[3]
                    }
                    payload.push({
                        x: date_x,
                        y: Math.round(graph[gk].payload)
                    })
                    tt.push({
                        x: date_x,
                        y: Math.round(graph[gk].tt)
                    })
                }
                if (response.status) this.setState({
                    data: response.data,
                    graph_payload: payload,
                    graph_tt: tt
                });
                unitapi.getBoardTableCycleTime(this.props.data.id_nodo, this.state.dateSelected ).then(responseTable => {
                      if (responseTable.status) {
                        this.setState({ tabletimedata : responseTable.data })
                      }

                })
            }
        });
    }

    onClickbtnReturn(event) {
        if (this.props.btnreturn) {
            this.props.btnreturn(event)
        }
    }

    onClickbtnFleetReturn(event) {
        if (this.props.btnfleetreturn) {
            this.props.btnfleetreturn(event)
        }
    }

    onClickbtnUnitReturn(event) {
        if (this.props.btnunitreturn) {
            this.props.btnunitreturn(event)
        }
    }

    onChangeValue(date, interval) {
        this.setState({
            dateSelected: date,
            intervalSelected: interval
        })
        this.updateData(date, interval);
    }

    FormatTimeTable(sec) {
        let hour: number = 0
        let minutes: number =  0
        let seconds: number = 0

        if(sec != null){
          hour = Math.trunc(sec / 3600)
          minutes = Math.trunc((sec - (hour * 3600))/60)
          seconds = Math.trunc(sec-((hour*3600)+(minutes*60)))
        }

        let hh = ''
        if (hour < 10 ) {
          hh = '0' + hour.toString();
        }else {
          hh = (hour).toLocaleString('en')
        }

        let mm = ''
        if (minutes < 10 ) {
          mm = '0' + minutes.toString()
        }else {
          mm = (minutes).toLocaleString('en')
        }

        let ss = ''
        if (seconds < 10 ) {
          ss = '0' + seconds.toString()
        }else {
          ss = (seconds).toLocaleString('en')
        }

        return hh + ":" + mm + ":" + ss
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))
          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }

        return (hour).toLocaleString('en') + "h " + minutes + "m"
    }

    render() {
        return (<>
            <div className={"row board-row"}>
                <div className={"col s12 board-margin-bottom board-bar-title"}>
                    <BarTitle
                      onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }}
                      onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
                      onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
                      btnReturn title={this.props.data.tolva_id_caex}
                      subtitle={this.subtitle}
                      pagSelect= "Unit"
                    />
                </div>
                <div className={"col s12 box-componente-down"}>
                    <div className={"row "}>
                        <div className={"col s6 box-iz "}>
                            <div className={"row"}>
                                <div className={"col s12 board-margin-bottom board-selector-date"}>
                                    <SelectorDate onChange={(d, i) => { this.onChangeValue(d, i) }} />
                                </div>
                                <div className={"col s12 board-margin-bottom board-box-time "}>
                                    <BoxTime
                                      efficiency={(this.state.data.Efficiency ? Math.round(this.state.data.Efficiency) + " %" : "0 %")}
                                      runingTime={this.MinuteDecimalToString(this.state.data.time_runing)}
                                      queuingToLoad={this.MinuteDecimalToString(this.state.data.time_load)}
                                      queuingToDump={this.MinuteDecimalToString(this.state.data.time_dump)}
                                      fuelingstop={this.MinuteDecimalToString(this.state.data.time_service)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col s6 box-der"}>
                            <div className={"row box-der-row"}>
                                <div className={"col s12 board-donut board-donut-r "}>
                                    <Donut title="Availability (%)" series={[(this.state.data ? Math.round(this.state.data.availability) : 0), (this.state.data ? Math.round(this.state.data.availability_truckshop) : 0), (this.state.data ? Math.round(this.state.data.availability_parking) : 0)]} labels={["Avaliable", "Truckshop", "Others (Parking)"]} />
                                </div>
                            </div>
                        </div>
                        <div className={"col s12 fleet-margin-bottom TimeonEach"}>
                            Time on each stage (seconds)
                        </div>
                        <div className={"col s12 table-datatable"}>
                          <DataTable
                            pagination
                            paginationPerPage="20"
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            theme="darkk"
                            noHeader
                              columns={this.columns}
                                data={this.state.tabletimedata}
                                customStyles={{
                                    rows: {
                                        style: {
                                          backgroundColor: '#1A2028',
                                          fontSize: '18px',
                                          color: 'white',
                                            minHeight: '30px', // override the row height
                                            '&:not(:last-of-type)': {
                                                borderBottomStyle: 'solid',
                                                borderBottomWidth: '1px',
                                                borderBottomColor: 'black',
                                            },
                                        }
                                    },

                                    pagination: {
                                      style: {
                                        color: '#8497B0',
                                        fontSize: '13px',
                                        minHeight: '56px',
                                        backgroundColor: '#1A2028',
                                        borderTopStyle: 'solid',
                                        borderTopWidth: '1px',
                                        borderTopColor: '#8497B0',
                                      },
                                      pageButtonsStyle: {
                                        borderRadius: '50%',
                                        height: '40px',
                                        width: '40px',
                                        padding: '8px',
                                        margin: 'px',
                                        cursor: 'pointer',
                                        transition: '0.4s',
                                        color: '#8497B0',
                                        fill: '#8497B0',
                                        backgroundColor: 'transparent',
                                        '&:disabled': {
                                          cursor: 'unset',
                                          color: '#8497B0',
                                          fill: '#8497B0',
                                        },
                                        '&:hover:not(:disabled)': {
                                          backgroundColor: '#FFFFFF',
                                        },
                                        '&:focus': {
                                          outline: 'none',
                                          backgroundColor: '#FFFFFF',
                                        },
                                      },
                                    },
                                    headRow: {
                                      style: {
                                        backgroundColor: '#8497B0',
                                        minHeight: '30px',
                                        borderBottomWidth: '2px',
                                        borderBottomColor: 'black',
                                        borderBottomStyle: 'solid',
                                      },
                                      denseStyle: {
                                        minHeight: '32px',
                                      },
                                    },
                                    headCells: {
                                        style: {
                                            fontSize: '16px',
                                            color: 'white',
                                            backgroundColor:'#323f4f',
                                            paddingLeft: '10px', // override the cell padding for head cells
                                            paddingRight: '2px',
                                            borderRightStyle: 'solid',
				                                    borderRightWidth: '2px',
				                                    borderRightColor: 'black',
                                        },
                                        activeSortStyle: {
                                            color: 'gray',
                                            '&:focus': {
                                              outline: 'none',
                                            },
                                            '&:hover:not(:focus)': {
                                              color: 'gray',
                                            },
                                        },
                                        inactiveSortStyle: {
                                            '&:focus': {
                                              outline: 'none',
                                              color: 'green',
                                            },
                                            '&:hover': {
                                              color: 'gray',
                                            },
                                        },
                                    },
                                    cells: {
                                        style: {
                                            paddingLeft: '8px', // override the cell padding for data cells
                                            paddingRight: '8px',
                                            borderRightStyle: 'solid',
				                                    borderRightWidth: '2px',
				                                    borderRightColor: 'black',
                                            borderBottomStyle: 'solid',
                                            borderBottomWidth: '1px',
                                            borderBottomColor: 'black',
                                        },
                                    },
                                    table: {
                                        style: {
                                            minHeight: '600px',
                                            maxHeight: '600px'
                                        }
                                    }, noData: {
                                        style: {
                                            minHeight: '600px',
                                            maxHeight: '600px'
                                        }
                                    }
                                }}
                          />
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
