import React, { Component, useEffect } from "react";
import "./RowWear.css";
import "materialize-css/dist/css/materialize.min.css";
import ProgressBar from "./ProgressBar";
import ProgressBarDisabled from "./ProgressBarDisabled";
export class RowWear extends Component{

  constructor(props) {
      super(props)
  }

  render() {
    if(this.props.sensor != '' ){
      return (<>
        <div className={"row rw_row"} >
          <div className={" col s2 rw_cell"}>
              <div className={"rw_cell_box rw_position"}>{this.props.position}</div>
          </div>
          <div className={" col s2 rw_cell"}>
              <div className={"rw_cell_box rw_sensor"}>{this.props.sensor}</div>
          </div>
          <div className={" col s3 rw_cell"}>
            <div className={"row"}>
              <div className={"rw_cell_box rw_connection"} style={{ background: this.props.connection_status == "off" ? '#C00000' : '#91f342' }} >&nbsp;</div>
            </div>
          </div>
          <div className={" col s3 rw_cell"}>
            <div className={"row"}>
              {
                (this.props.connection_status == "on") ?
                  <ProgressBar
                    completed={this.props.barvalue}
                    showvalue={this.props.showvalue}
                  /> :
                  <ProgressBar
                    completed={0}
                    showvalue={'Fault'}
                  />
              }
            </div>
          </div>
          <div className={" col s2 rw_cell"}>
            <div className={"row"}>
              <div className={"rw_cell_box rw_batery"} style={{ background: this.props.battery_status == "warning" || this.props.battery == '-' ? '#C00000' : '#1d1c1c' }} >{this.props.battery}</div>
            </div>
          </div>
        </div>
        </>)
    }else{
      return (<>
        <div className={"row rw_row"} >
          <div className={" col s2 rw_cell"}>
              <div className={"rw_cell_box rw_position"}>{this.props.position}</div>
          </div>
          <div className={" col s2 rw_cell"}>
              <div className={"rw_cell_box rw_sensor"}>N/A</div>
          </div>
          <div className={" col s3 rw_cell"}>
            <div className={"row"}>
              <div className={"rw_cell_box rw_connection"} style={{ background: '#4E4E4E' }} >&nbsp;</div>
            </div>
          </div>
          <div className={" col s3 rw_cell"}>
            <div className={"row"}>
              <ProgressBarDisabled
                completed={0}
                showvalue={''}
              />
            </div>
          </div>
          <div className={" col s2 rw_cell"}>
            <div className={"row"}>
              <div className={"rw_cell_box rw_batery"} style={{ background: '#4E4E4E' }} >-</div>
            </div>
          </div>
        </div>
        </>)
    }


    }
}
