import React, { Component } from "react";
import "./Nodos.css"
import { Redirect } from "react-router";
import { SettingsAPi } from "../../../../../api/SettingsAPI";

const settingsapi = new SettingsAPi();

export class Nodos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataNodo:false,
      redirect: false
    }
  }

  componentWillMount() {
    this.getDataNodos();
  }

  componentWillUnmount() {
      clearInterval(this.refreshdata)
  }

  getDataNodos() {
    if (!this.refreshdata) {
      settingsapi.getDataNodoByFaena(this.props.id).then(response => {
        if (response.status) this.setState({ dataNodo: response.data});
          this.refreshdata = setInterval(() => {
            settingsapi.getDataNodoByFaena(this.props.id).then(response => {
              if (response.status){
                this.setState({ dataNodo: response.data});
                //console.log("response.data : ", response.data)
              }
            }).catch(err => {
              console.log(err)
          })
        }, 10000);
      }).catch(err => {
        console.log(err)
      })
    }
  }


  renderNodo(){
    const NodoTD = ({ created_At,Id_CAEX, Gps_lat, Gps_lon, Impact1, Impact2, Impact3, Impact4, Impact5, Sat_InUse,Sat_SNR_avg, speed,voltaje }) => (
      <tr >
          <td>{created_At}</td>
          <td>{Id_CAEX}</td>
          <td>{Gps_lat}</td>
          <td>{Gps_lon}</td>
          <td>{Impact1}</td>
          <td>{Impact2}</td>
          <td>{Impact3}</td>
          <td>{Impact4}</td>
          <td>{Sat_InUse}</td>
          <td>{Sat_SNR_avg}</td>
          <td>{speed}</td>
          <td>{voltaje}</td>
      </tr>
    );

    if (this.state.dataNodo != false){
      return(
        this.state.dataNodo.map((dta) => (
          <NodoTD
            id={dta.Id_trama}
            created_At={dta.created_At}
            Id_CAEX={dta.Id_CAEX}
            Gps_lat={dta.Gps_lat}
            Gps_lon={dta.Gps_lon}
            Impact1={dta.Impact1}
            Impact2={dta.Impact2}
            Impact3={dta.Impact3}
            Impact4={dta.Impact4}
            Impact5={dta.Impact5}
            Sat_InUse={dta.Sat_InUse}
            Sat_SNR_avg={dta.Sat_SNR_avg}
            speed={dta.speed}
            voltaje={dta.voltaje}
          />
        ))
      )
    }else{
      return(
        <tr><td>No nodo exist.</td></tr>
      )
    }
  }



  render() {
    if (this.state.redirect){
      return <Redirect to='/settings' />
    }

    return (
      <>
      <div class="user-detail">
      <h2>Nodo parameters</h2>
        <div class="datagrid">
          <table >
            <thead>
              <tr>
                  <th>TS</th>
                  <th>CAEX</th>
                  <th>Latitud</th>
                  <th>Longitud</th>
                  <th>Impact1</th>
                  <th>Impact2</th>
                  <th>Impact3</th>
                  <th>Impact4</th>
                  <th>Sat_InUse</th>
                  <th>Sat_SNR_avg</th>
                  <th>speed</th>
                  <th>voltaje</th>
              </tr>
            </thead>
          <tbody>
            {this.renderNodo()}
            </tbody>
          </table>
        </div>

      </div>
      </>)
  }
}
