import env from "react-dotenv";
import { Auth } from "../helpers/auth";

const auth = new Auth();

export class AssetsAPi {

  contructor() {

  }

  getTableMesh(id_nodo) {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/assets/mesh/` + id_nodo, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }

  getDataWear() {
      return fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/assets/wear/`, {
          method: "GET",
          headers: {
              "Accept": 'application/json',
              'Content-Type': 'application/json',
              'authorization': auth.getToken()
          }
      }).then((response) => response.json())
  }

}
