import { Component } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import M from "materialize-css";
import "./Table.css";
import { BoxCycleAnalyticsGinGin } from "../../../../../modulos/BoxCycleAnalyticsGinGin/BoxCycleAnalyticsGinGin"
import { BarTitle } from "../../../../../modulos/BarTitle/BarTitle";
import { BarTitleCycleAnalytics } from "../../../../../modulos/BarTitle/BarTitle";

export class TableGINGIN extends Component {
subtitle = ["Fleet analytics", "Unit analytics"]
    constructor(props) {
        super(props)
        this.state = {
            selectedOption: "1"
        }
        createTheme("darkk", {
            text: {
                primary: '#59677a',
                secondary: '#59677a',
            },
            background: {
                default: '#191f27',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#4d4a52',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        })
    }

    datatableSelect(e) {
        if (this.props.onRowClicked) {
          this.props.onRowClicked(e)
        /*if(e.Cycles!=0){
            this.props.onRowClicked(e)
          }else{
            M.toast({ html: "¡Caex " + e.tolva_id_caex + " does not register cycles!" })
          }*/
        }
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))
          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }
        return (hour).toLocaleString('en') + "h " + minutes + "m"
    }

    onClickbtnReturn(event) {
        if (this.props.btnreturn) {
            this.props.btnreturn(event)
        }
    }

    onClickbtnFleetReturn(event) {
        if (this.props.btnfleetreturn) {
            this.props.btnfleetreturn(event)
        }
    }

    onClickbtnUnitReturn(event) {
        if (this.props.btnunitreturn) {
            this.props.btnunitreturn(event)
        }
    }

    render() {
        return (
            <>
                <div className={"table-component"}>
                    <div className={"row table-component-row"}>
                      <div className={"col s12 fleet-title"}>
                        <BarTitle
                          onClickbtnFleetReturn={(e) => { this.onClickbtnFleetReturn(e) }}
                          onClickbtnUnitReturn={(e) => { this.onClickbtnUnitReturn(e) }}
                          title={"UNIT"}
                          subtitle={this.subtitle}
                          pagSelect = "Unit"
                        />
                      </div>
                        <div className={"col s12 board-margin-bottom board-box-time "}>
                            <BoxCycleAnalyticsGinGin
                              TruckInOperation={this.props.TruckInOperation}
                              NCycles={this.props.NCycles}
                              KmToday={this.props.KmToday?this.props.KmToday:0}
                              Payload={this.props.Payload}
                              MinutesInOperation_GINGIN={this.MinuteDecimalToString(this.props.MinutesInOperation_GINGIN)}
                              MinutesInTruckshoop_GINGIN={this.MinuteDecimalToString(this.props.MinutesInTruckshoop_GINGIN)}
                              MinutesInService_GINGIN={this.MinuteDecimalToString(this.props.MinutesInService_GINGIN)}
                              MinutesInIdle_GINGIN={this.MinuteDecimalToString(this.props.MinutesInIdle_GINGIN)}
                            />
                        </div>
                        <div className={"col s12 table-datatable"}>
                            <DataTable
                                pagination
                                paginationPerPage="20"
                                theme="darkk"
                                columns={this.props.columns}
                                data={this.props.data}
                                selectableRows
                                onRowClicked={(e) => { this.datatableSelect(e) }}
                                pointerOnHover
                                paginationComponentOptions={{ noRowsPerPage: true }}
                                noHeader
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        style: {
                                          backgroundColor: '#1A2028',
                                          fontSize: '16px',
                                          color: 'white',
                                            minHeight: '30px', // override the row height
                                            '&:not(:last-of-type)': {
                                                borderBottomStyle: 'solid',
                                                borderBottomWidth: '1px',
                                                borderBottomColor: '#8497B0',
                                            },
                                        }
                                    },
                                    pagination: {
                                  		style: {
                                  			color: '#8497B0',
                                  			fontSize: '13px',
                                  			minHeight: '56px',
                                  			backgroundColor: '#1A2028',
                                  			borderTopStyle: 'solid',
                                  			borderTopWidth: '1px',
                                  			borderTopColor: '#8497B0',
                                  		},
                                  		pageButtonsStyle: {
                                  			borderRadius: '50%',
                                  			height: '40px',
                                  			width: '40px',
                                  			padding: '8px',
                                  			margin: '0px',
                                  			cursor: 'pointer',
                                  			transition: '0.4s',
                                  			color: '#8497B0',
                                  			fill: '#8497B0',
                                  			backgroundColor: 'transparent',
                                  			'&:disabled': {
                                  				cursor: 'unset',
                                  				color: '#8497B0',
                                  				fill: '#8497B0',
                                  			},
                                  			'&:hover:not(:disabled)': {
                                  				backgroundColor: '#FFFFFF',
                                  			},
                                  			'&:focus': {
                                  				outline: 'none',
                                  				backgroundColor: '#FFFFFF',
                                  			},
                                  		},
                                  	},


                                    headRow: {
                                      style: {
                                        backgroundColor: '#8497B0',
                                        minHeight: '30px',
                                        borderBottomWidth: '1px',
                                        borderBottomColor: '#8497B0',
                                        borderBottomStyle: 'solid',
                                      },
                                      denseStyle: {
                                        minHeight: '32px',
                                      },
                                    },
                                    headCells: {
                                        style: {

                                            fontSize: '12px',
                                            color: 'white',
                                            backgroundColor:'#191f27',
                                            paddingLeft: '10px', // override the cell padding for head cells
                                            paddingRight: '0px',
                                        },
                                        activeSortStyle: {
                                            color: 'gray',
                                            '&:focus': {
                                              outline: 'none',
                                            },
                                            '&:hover:not(:focus)': {
                                              color: 'gray',
                                            },
                                        },
                                        inactiveSortStyle: {
                                            '&:focus': {
                                              outline: 'none',
                                              color: 'green',
                                            },
                                            '&:hover': {
                                              color: 'gray',
                                            },
                                        },
                                    },
                                    cells: {
                                        style: {
                                            paddingLeft: '0px', // override the cell padding for data cells
                                            paddingRight: '0px',
                                        },
                                    },
                                    table: {
                                        style: {
                                            minHeight: '600px',
                                            maxHeight: '600px'
                                        }
                                    }, noData: {
                                        style: {
                                            minHeight: '600px',
                                            maxHeight: '600px'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
