import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Cycle.css"

import Chart from "react-apexcharts";
export class Cycle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            series: [{
                name: "payload",
                data: props.series
            }],
            xaxis: {
                type: "datetime"
            },
            options: {
                xaxis: {
                    labels: {
                        style: {
                            colors: "#ffffff"
                        }
                    }
                },
                yaxis: {
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        text: 'N° of Cycles'
                    },
                    labels: {
                        style: {
                            colors: "#ffffff"
                        }
                    }
                },
                chart: {
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: true
                    }
                },
                stroke: {
                    curve: 'smooth',
                }
            }
        }
    }

    render() {
        return (<>
            <div className={"row cycle"}>
                <div className={"col s12 cycle-title"}>
                    Cycle
                </div>
                <div className={"col s6 cycle-graph"}>
                    <Chart
                        options={this.state.options}
                        series={[{
                            name: "Cycle",
                            data: this.props.series
                        }]}
                        type="line"
                        height="102%"
                        width="100%"
                    />
                </div>
                <div className={"col s6 cycle-avg"}>
                    <div className={"row cycle-avg-row"}>
                        <div className={"col s12 cycle-avg-value"}>
                            {this.props.averageCycleTime}
                        </div>
                        <div className={"col s12 cycle-avg-title"}>
                            Average Cycle time
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
