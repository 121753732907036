import { Component } from "react";

import DataTable, { createTheme } from "react-data-table-component";
import M from "materialize-css";

import { SelectorDate } from "../../../../../modulos/SelectorDate/SelectorDate";
import "./Table.css";
import { Map } from "../../../../../modulos/Map/Map";

export class Table extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedOption: "1"
        }
        createTheme("darkk", {
            text: {
                primary: '#59677a',
                secondary: '#59677a',
            },
            background: {
                default: '#191f27',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#4d4a52',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        })
    }

    datatableSelect(e) {
        if (this.props.onRowClicked) {
            this.props.onRowClicked(e)
        }
    }

    onChangeValue(date) {
        this.setState({ selectedOption: date })
        if (this.props.onChangeSelectValue) {
            this.props.onChangeSelectValue(date)
        }
    }

    render() {
        return (
            <>
                <div className={"table-component"}>

                    <div className={"row table-component-row"}>
                        <div className={"col s12 table-all-date-range"}>
                            <SelectorDate onChange={(e) => { this.onChangeValue(e) }} />
                        </div>
                        <div className={"col s12 table-datatable"}>
                            <DataTable
                                pagination
                                paginationPerPage="8"
                                theme="darkk"
                                columns={this.props.columns}
                                data={this.props.data}
                                selectableRows
                                onRowClicked={(e) => { this.datatableSelect(e) }}
                                pointerOnHover
                                paginationComponentOptions={{ noRowsPerPage: true }}
                                noHeader
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        style: {
                                            minHeight: '30px', // override the row height
                                        }
                                    },
                                    headCells: {
                                        style: {
                                            paddingLeft: '25px', // override the cell padding for head cells
                                            paddingRight: '8px',
                                        },
                                    },
                                    cells: {
                                        style: {
                                            paddingLeft: '8px', // override the cell padding for data cells
                                            paddingRight: '8px',
                                        },
                                    },
                                    table: {
                                        style: {
                                            minHeight: '300px',
                                            maxHeight: '300px'
                                        }
                                    }, noData: {
                                        style: {
                                            minHeight: '365px',
                                            maxHeight: '300px'
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className={"col s6 table-map1"}>
                            <div className={"row "}>
                                <div className={"col s12 table-map1-title"}>
                                    Mine view
                                </div>
                                <div className={"col s12 "}>
                                    <Map trucks={this.props.mapPositionTruck || []} />
                                </div>
                            </div>
                        </div>

                        <div className={"col s6 table-map2"}>
                            <div className={"row "}>
                                <div className={"col s12 table-map2-title"}>
                                    Shovel view
                                </div>
                                <div className={"col s12 "}>
                                    <Map route trucks={this.props.mapTruckRoute || []} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}