import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoxTime.css"
import { Auth } from "../../../helpers/auth";

const auth = new Auth();

export class BoxTime extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<>
            <div className={"row box-time"}>
                <div className={" col s1 offset box-time-info-one"}>
                    <div className={"row"}>
                        <div className={" col s12 offset box-time-info-one-number"}>
                            <span> {this.props.efficiency}</span>
                        </div>
                        <div className={" col s12 offset box-time-info-one-title"}>
                            <span>Efficiency</span>
                        </div>
                    </div>
                </div>

                <div className={" col s1 offset box-time-info"}>
                  <div className={"row"}>
                    <div className={" col s12 offset box-time-info-number-chico"}>
                      <span>{this.props.runingTime}</span>
                    </div>
                    <div className={" col s12 offset box-time-info-title-chico"}>
                      <span>Running Time</span>
                    </div>
                  </div>
                </div>

                <div className={" col s1 offset box-time-info"}>
                  <div className={"row"}>
                      <div className={" col s12 offset box-time-info-number-chico"}>
                          <span> {this.props.queuingToLoad}</span>
                      </div>
                      <div className={" col s12 offset box-time-info-title-chico"}>
                          <span>Queue to load</span>
                      </div>
                  </div>
                </div>

                <div className={" col s1 offset box-time-info"}>
                  <div className={"row"}>
                      <div className={" col s12 offset box-time-info-number-chico"}>
                          <span> {this.props.queuingToDump}</span>
                      </div>
                      <div className={" col s12 offset box-time-info-title-chico"}>
                          <span>Queue to dump</span>
                      </div>
                  </div>
                </div>

                <div className={" col s1 offset box-time-info"}>
                      <div className={" col s12 offset box-time-info-number-chico"}>
                          <span> {this.props.fuelingstop}</span>
                      </div>
                      <div className={" col s12 offset box-time-info-title-chico"}>
                          <span>Fuelling stop</span>
                      </div>

                </div>

            </div>
        </>
        )
    }
}
