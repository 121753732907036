import React, { Component, useEffect } from "react";
import "./TolvaWear.css";
import "materialize-css/dist/css/materialize.min.css";
import TolvaWearPath  from "../../../assets/asset/AssetTolva.png";
import p1_verde       from "../../../assets/asset/AssetTolva_p1_verde.png";
import p2_verde       from "../../../assets/asset/AssetTolva_p2_verde.png";
import p3_verde       from "../../../assets/asset/AssetTolva_p3_verde.png";
import p4_verde       from "../../../assets/asset/AssetTolva_p4_verde.png";
import p5_verde       from "../../../assets/asset/AssetTolva_p5_verde.png";
import p6_verde       from "../../../assets/asset/AssetTolva_p6_verde.png";
import p7_verde       from "../../../assets/asset/AssetTolva_p7_verde.png";
import p8_verde       from "../../../assets/asset/AssetTolva_p8_verde.png";
import p9_verde       from "../../../assets/asset/AssetTolva_p9_verde.png";
import p1_rojo        from "../../../assets/asset/AssetTolva_p1_rojo.png";
import p2_rojo        from "../../../assets/asset/AssetTolva_p2_rojo.png";
import p3_rojo        from "../../../assets/asset/AssetTolva_p3_rojo.png";
import p4_rojo        from "../../../assets/asset/AssetTolva_p4_rojo.png";
import p5_rojo        from "../../../assets/asset/AssetTolva_p5_rojo.png";
import p6_rojo        from "../../../assets/asset/AssetTolva_p6_rojo.png";
import p7_rojo        from "../../../assets/asset/AssetTolva_p7_rojo.png";
import p8_rojo        from "../../../assets/asset/AssetTolva_p8_rojo.png";
import p9_rojo        from "../../../assets/asset/AssetTolva_p9_rojo.png";
import p1_gris        from "../../../assets/asset/AssetTolva_p1_gris.png";
import p2_gris        from "../../../assets/asset/AssetTolva_p2_gris.png";
import p3_gris        from "../../../assets/asset/AssetTolva_p3_gris.png";
import p4_gris        from "../../../assets/asset/AssetTolva_p4_gris.png";
import p5_gris        from "../../../assets/asset/AssetTolva_p5_gris.png";
import p6_gris        from "../../../assets/asset/AssetTolva_p6_gris.png";
import p7_gris        from "../../../assets/asset/AssetTolva_p7_gris.png";
import p8_gris        from "../../../assets/asset/AssetTolva_p8_gris.png";
import p9_gris        from "../../../assets/asset/AssetTolva_p9_gris.png";

export class TolvaWear extends Component{

  constructor(props) {
      super(props)
      console.log(this.props.data);
  }


  ctrLED1(){
    if(this.props.data[0].sensor != '' ){
      return (<><div id="ledp1">{this.props.data[0].connection_status == 'off' || this.props.data[0].showvalue == '0' || this.props.data[0].showvalue == 'Fault' || this.props.data[0].thicknessstatus == 'alarm' || this.props.data[0].battery_status == 'warning' ? <img class='p1_rojo' src={p1_rojo} ></img>:<img class='p1_verde' src={p1_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp1"><img class='p1_gris' src={p1_gris} ></img></div></>);
    }
  }

  ctrLED2(){
    if(this.props.data[1].sensor != '' ){
      return (<><div id="ledp2">{this.props.data[1].connection_status == 'off' || this.props.data[1].showvalue == '0' || this.props.data[1].showvalue == 'Fault' || this.props.data[1].thicknessstatus == 'alarm' || this.props.data[1].battery_status == 'warning' ? <img class='p2_rojo' src={p2_rojo} ></img>:<img class='p2_verde' src={p2_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp2"><img class='p2_gris' src={p2_gris} ></img></div></>);
    }
  }

  ctrLED3(){
    if(this.props.data[2].sensor != '' ){
      return (<><div id="ledp3">{this.props.data[2].connection_status == 'off' || this.props.data[2].showvalue == '0' || this.props.data[2].showvalue == 'Fault' || this.props.data[2].thicknessstatus == 'alarm' || this.props.data[2].battery_status == 'warning' ? <img class='p3_rojo' src={p3_rojo} ></img>:<img class='p3_verde' src={p3_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp3"><img class='p3_gris' src={p3_gris} ></img></div></>);
    }
  }

  ctrLED4(){
    if(this.props.data[3].sensor != '' ){
      return (<><div id="ledp4">{this.props.data[3].connection_status == 'off' || this.props.data[3].showvalue == '0' || this.props.data[3].showvalue == 'Fault' || this.props.data[3].thicknessstatus == 'alarm' || this.props.data[3].battery_status == 'warning' ? <img class='p4_rojo' src={p4_rojo} ></img>:<img class='p4_verde' src={p4_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp4"><img class='p4_gris' src={p4_gris} ></img></div></>);
    }
  }

  ctrLED5(){
    if(this.props.data[4].sensor != '' ){
      return (<><div id="ledp5">{this.props.data[4].connection_status == 'off' || this.props.data[4].showvalue == '0' || this.props.data[4].showvalue == 'Fault' || this.props.data[4].thicknessstatus == 'alarm' || this.props.data[4].battery_status == 'warning' ? <img class='p5_rojo' src={p5_rojo} ></img>:<img class='p1_verde' src={p5_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp5"><img class='p5_gris' src={p5_gris} ></img></div></>);
    }
  }

  ctrLED6(){
    if(this.props.data[5].sensor != '' ){
      return (<><div id="ledp6">{this.props.data[5].connection_status == 'off' || this.props.data[5].showvalue == '0' || this.props.data[0].showvalue == 'Fault' || this.props.data[5].thicknessstatus == 'alarm' || this.props.data[0].battery_status == 'warning' ? <img class='p6_rojo' src={p6_rojo} ></img>:<img class='p6_verde' src={p6_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp6"><img class='p6_gris' src={p6_gris} ></img></div></>);
    }
  }

  ctrLED7(){
    if(this.props.data[6].sensor != '' ){
      return (<><div id="ledp7">{this.props.data[6].connection_status == 'off' || this.props.data[6].showvalue == '0' || this.props.data[6].showvalue == 'Fault' || this.props.data[6].thicknessstatus == 'alarm' || this.props.data[6].battery_status == 'warning' ? <img class='p7_rojo' src={p7_rojo} ></img>:<img class='p7_verde' src={p7_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp7"><img class='p7_gris' src={p7_gris} ></img></div></>);
    }
  }

  ctrLED8(){
    if(this.props.data[7].sensor != '' ){
      return (<><div id="ledp8">{this.props.data[7].connection_status == 'off' || this.props.data[7].showvalue == '0' || this.props.data[7].showvalue == 'Fault' || this.props.data[7].thicknessstatus == 'alarm' || this.props.data[7].battery_status == 'warning' ? <img class='p8_rojo' src={p8_rojo} ></img>:<img class='p8_verde' src={p8_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp8"><img class='p8_gris' src={p8_gris} ></img></div></>);
    }
  }

  ctrLED9(){
    if(this.props.data[8].sensor != '' ){
      return (<><div id="ledp9">{this.props.data[0].connection_status == 'off' || this.props.data[8].showvalue == '0' || this.props.data[8].showvalue == 'Fault' || this.props.data[8].thicknessstatus == 'alarm' || this.props.data[8     ].battery_status == 'warning' ? <img class='p9_rojo' src={p9_rojo} ></img>:<img class='p9_verde' src={p9_verde} ></img> }</div></>);
    }else{
      return (<><div id="ledp9"><img class='p9_gris' src={p9_gris} ></img></div></>);
    }
  }


  renderAux(){
    if(this.props.data){
      return(
        <div>
          <div id="Tolva"><img class="imgtolva" src={TolvaWearPath} alt={"Nexus"} ></img></div>
          <div>{this.ctrLED1()}</div>
          <div>{this.ctrLED2()}</div>
          <div>{this.ctrLED3()}</div>
          <div>{this.ctrLED4()}</div>
          <div>{this.ctrLED5()}</div>
          <div>{this.ctrLED6()}</div>
          <div>{this.ctrLED7()}</div>
          <div>{this.ctrLED8()}</div>
          <div>{this.ctrLED9()}</div>
        </div>
      )
    }
  }

  render() {
    return (
      <>
        {this.renderAux()}
      </>
    )
  }

}
