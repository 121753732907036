import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Donut.css"
import { Pie } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export class Donut extends Component {



    options = {
        animation: {
            duration: 0,
        },
        hover: {
            animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        tooltipItems: {
            enabled: true
        },
        plugins: {
          datalabels: {
              formatter: function(value, context) {
                return (value == 0 ? '' : value + '%');
              },
              arc: true,
              render: 'label',
              display: true,
              color: 'white',
              labels: {
                title: {
                  font: {
                    weight: 'bold'
                  },
                },
              },
            },
            font: {
                weight: 'bold'
            },
            legend: {
                position: "left",
                labels: {
                    display: true,
                    padding: 10,
                    color: "white",

                }
            },
            title: {
                display: false,
                position: "top",
                align: "left",
                text: "Availability (%)",
                color: "#7F60F2",
            },
        },
        maintainAspectRatio: false
    }

  constructor(props) {
     super(props)
    Chart.register(ChartDataLabels);
  }

    render() {
        return (<>
            <div className={"donut"}>
                <div className={"donut-title"}>
                    Availability / Utilisation (%)
                </div>
                <div className={"canvas"} >
                  <Pie
                    data={{
                      colors: ['#527f35', '#8497b0', '#767171'],
                      labels: this.props.labels,
                      datasets: [
                        {
                          label: 'Availability',
                          data: this.props.series,
                          backgroundColor: [
                            'rgba(128, 48, 150, 1)',
                            'rgba(169, 148, 246, 1)',
                            'rgba(87, 46, 238, 1)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                          ],
                          borderColor: [
                            'rgba(128, 48, 150, 1)',
                            'rgba(169, 148, 246, 1)',
                            'rgba(87, 46, 238, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                          ],
                          borderWidth: 0,
                          offset: 0,
                          spacing: 0,
                        }
                      ]
                    }}
                    options={this.options}
                  />
                </div>


            </div>
        </>
        )
    }
}
