import React, { Component, useEffect } from "react";
import "./TableWear.css";
import "materialize-css/dist/css/materialize.min.css";
import { RowWear }  from "./RowWear"
import M from "materialize-css";

export class TableWear extends Component{

  constructor(props) {
      super(props)
  }

  renderAux(){
    if(this.props.data != false ){
      return(
        this.props.data.map((item, idx) => (
          <RowWear
            key={idx}
            position = {item.position}
            sensor = {item.sensor}
            battery = {item.battery}
            battery_status = {item.battery_status}
            connection_status = {item.connection_status}
            barvalue = {item.barvalue}
            showvalue = {item.showvalue}
          />
        ))
      )

    }else{
      return(  <div class="row">
                <div class="col s12 m12">
                  <div class="card black darken-1">
                    <div class="card-content white-text">
                      <span class="card-title">Information:</span>
                      <p>No data found!</p>
                    </div>
                  </div>
                </div>
              </div>
            )
    }

  }


  render() {
      return (<>
        <div className={"row rw_row_wear "} >
          <div className={"row rw_row"} >
            <div className={" col s2 rw_cell_table"}>
              <div className={"row"}>Position</div>
            </div>
            <div className={" col s2 rw_cell_table"}>
              <div className={"row"}>Sensor ID</div>
            </div>
            <div className={" col s3 rw_cell_table"}>
              <div className={"row"}>Connection</div>
            </div>
            <div className={" col s3 rw_cell_table"}>
              <div className={"row"}>Thickness</div>
            </div>
            <div className={" col s2 rw_cell_table"}>
              <div className={"row"}>Battery</div>
            </div>
          </div>
        </div>
        <div className={"row"} >
          { this.renderAux() }
        </div>
        </>)
    }
}
