import React, { Component, useEffect } from "react";
import "./Monitor.css";
import { Gage } from "./Gage";
import { BarMonitor } from "./BarMonitor";


//Charts and Graphics
import Chart from "react-apexcharts";
import JustGage from "justgage";


export class Monitor extends Component {

    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    HourtoSeconds(Stringtime = "") {
        if (typeof (Stringtime) == "string" && Stringtime.indexOf(":") >= 2) {
            Stringtime = Stringtime.split(":");
            return (parseInt(Stringtime[0]) * 60 * 60) + (parseInt(Stringtime[1]) * 60) + (parseInt(Stringtime[2]))
        }
        return 0
    }



    timeInCycle() {
      //console.log("TIME: ", new Date().toISOString() )
      //console.log("trama_created_at: ", this.props.value.trama_created_at )
      //console.log("DIF: ", ((new Date().getTime() / 1000) - (new Date(this.props.value.trama_created_at).getTime() / 1000)))
      //console.log("OFF: ", new Date(this.props.value.trama_created_at).getTimezoneOffset() )

      if(this.props.value.trama_timestamp && this.props.value.trama_cycleStart){
        return Math.abs(this.HourtoSeconds(this.props.value.trama_timestamp) - this.HourtoSeconds(this.props.value.trama_cycleStart)) + " sec"
        }
      else {
        return '0 sec'
      }
    }

    render() {
        return (
            <>

                <div className={"row monitor"}>
                    <div className={"col s12 monitor-row-col-top"}>
                        <div className={"row monitor-row"}>
                            <div className={"col s6 nexus-general-information"}>

                                <div className={"row nexus-general-information-row"}>
                                    <div className={"col s12 nexus-general-title nexus-line-botton"}>
                                        General Information
                                    </div>
                                    <div className={"col s6 nexus-key nexus-line-botton"}>
                                        Truck #
                                    </div>
                                    <div className={"col s6 nexus-value nexus-line-botton"}>
                                      {this.props.value.tolva_id_caex?this.props.value.tolva_id_caex:''}
                                    </div>
                                    <div className={"col s6 nexus-key nexus-line-botton"}>
                                        KM today
                                    </div>
                                    <div className={"col s6 nexus-value nexus-line-botton"}>
                                      {this.props.value.trama_distCycle?this.props.value.trama_distCycle:'0'}
                                    </div>
                                    <div className={"col s6 nexus-key"}>
                                        Cycles #
                                    </div>
                                    <div className={"col s6 nexus-value"}>
                                        {this.props.value.trama_number_cycle?this.props.value.trama_number_cycle:'0'}
                                    </div>
                                </div>

                            </div>
                            <div className={"col s6 nexus-status"}>
                                <div className={"row nexus-status-row"}  >
                                    <div className={"col s12 nexus-status-text2"}>
                                        <div className={"col s6 nexus-status-text-key nexus-key"}>Status</div>
                                        <div className={"col s6 nexus-status-text nexus-value"}>
                                            {((((new Date().getTime() / 1000) - (new Date(this.props.value.trama_created_at).getTime() / 1000)) <= 300) ? "Connected" : "Not Connected")}
                                        </div>
                                    </div>
                                    <div className={"col s12 nexus-status-text2"}>
                                        <div className={"col s6 nexus-status-text-key nexus-key"}>State</div>
                                        <div className={"col s6 nexus-status-text nexus-value"}>
                                            {this.props.value.estado_nombre?this.props.value.estado_nombre:''}
                                        </div>
                                    </div>
                                    <div className={"col s12 nexus-status-text2"}>
                                        <div className={"col s6 nexus-status-text-key nexus-key"}>Time in cycle</div>
                                        <div className={"col s6 nexus-status-text nexus-value"}>
                                            {this.timeInCycle()}
                                        </div>
                                    </div>
                                    <div className={"col s12 nexus-status-text2"}>
                                        <div className={"col s6 nexus-status-text-key nexus-key"}>Area</div>
                                        <div className={"col s6 nexus-status-text nexus-value"}>
                                            {this.props.value.area_nombre?this.props.value.area_nombre:''}
                                        </div>
                                    </div>
                                    <div className={"col s12 nexus-status-text2"}>
                                        <div className={"col s6 nexus-status-text-key nexus-key"}>Alarm</div>
                                        <div className={"col s6 nexus-status-text nexus-value"}>
                                            {this.props.value.alarma_nombre?this.props.value.alarma_nombre:''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col s12 monitor-row-col-botton"}>
                        <div className={"row monitor-row"}>
                            <div className={"col s6 nexus-payload"}>
                                <div className={"row nexus-payload-row"}  >
                                    <div className={"col s6 nexus-key nexus-line-botton"}>
                                        TOTAL PAYLOAD
                                    </div>
                                    <div className={"col s6 nexus-value nexus-line-botton"}>
                                        {this.props.value.trama_peso_carga?this.props.value.trama_peso_carga:'0'}
                                    </div>
                                    <div className={"col s12 nexus-payload-bar"}>
                                        {<BarMonitor value={this.props.value} />}
                                    </div>
                                </div>
                            </div>
                            <div className={"col s6 nexus-time"} >
                                <div className={"row nexus-time-row"}  >
                                    <div className={"col s12 nexus-time-title nexus-time-title-r"}>
                                        Time Between
                                </div>
                                    <div className={"col s12 nexus-time-value"}>
                                        {this.props.value.trama_time_between_inpact_1_and_inpact_2?this.props.value.trama_time_between_inpact_1_and_inpact_2:'0'} sec
                                </div>
                                    <div className={"col s12 nexus-time-sub-title"}>
                                        L1-L2
                                        </div>
                                    <div className={"col s12 nexus-time-value"}>
                                        {this.props.value.trama_time_between_inpact_2_and_inpact_3?this.props.value.trama_time_between_inpact_2_and_inpact_3:'0'} sec
                                </div>
                                    <div className={"col s12 nexus-time-sub-title"}>
                                        L2-L3
                                    </div>
                                    <div className={"col s12 nexus-time-value"}>
                                        {this.props.value.trama_time_between_inpact_3_and_inpact_4?this.props.value.trama_time_between_inpact_3_and_inpact_4:'0'} sec
                                </div>
                                    <div className={"col s12 nexus-time-sub-title"}>
                                        L3-L4
                                    </div>
                                </div>
                            </div>
                            <div className={"col s6 nexus-gage"}>
                                <div className={"row nexus-gage-row"}  >
                                    <div className={"col s6 nexus-gage-graph-title"}>
                                        {this.props.value.trama_angulo?this.props.value.trama_angulo:'0'}°
                                    </div>
                                    <div className={"col s6 nexus-gage-graph-title"}>
                                        {this.props.value.trama_velocidad?this.props.value.trama_velocidad:'0'}km/h
                                    </div>
                                    <div className={"col s6 nexus-gage-graph"}>
                                        <Gage value={this.props.value.trama_angulo?this.props.value.trama_angulo:'0'} />
                                    </div>
                                    <div className={"col s6 nexus-gage-graph"}>
                                        <Gage value={this.props.value.trama_velocidad?this.props.value.trama_velocidad:'0'} />
                                    </div>
                                    <div className={"col s12 nexus-gage-line-top"} />
                                    <div className={"col s6 nexus-gage-title"}>
                                        Satellite in use
                                    </div>
                                    <div className={"col s6 nexus-gage-value  "}>
                                        {this.props.value.trama_satelites_in_use?this.props.value.trama_satelites_in_use:'0'}
                                    </div>
                                    <div className={"col s6 nexus-gage-title"}>
                                        Satellite SNR
                                    </div>
                                    <div className={"col s6 nexus-gage-value"}>
                                        {this.props.value.trama_sat_snr_avg?this.props.value.trama_sat_snr_avg:'0'}
                                    </div>
                                    <div className={"col s6 nexus-gage-title"}>
                                        RMS
                                    </div>
                                    <div className={"col s6 nexus-gage-value"}>
                                        {this.props.value.trama_raiz_cuadrada_media_aceleracion?this.props.value.trama_raiz_cuadrada_media_aceleracion:'0'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
