import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./LogAlarms.css"
import { AlarmsApi }        from "../../../api/AlarmsApi";
import DataTable, { createTheme } from "react-data-table-component";
const alarmsApi = new AlarmsApi();

export class LogAlarms extends Component {

  columns = [
      {
          name: 'Id',
          selector: 'Id',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.Id_CAEX,
          sortable: true,
          width: "58px",
          center:true,
      },
      {
          name: 'Time',
          selector: 'date',
          width: "60px",
          sortable: true,
          center:true
      },
      {
          name: 'Value',
          selector: row => row.Nombre_alarm,
          sortable: true,
          width: "54px",
      },
  ];

  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      customStyles:{
          rows: {
              style: {
                backgroundColor: '#1A2028',
                fontSize: '12px',
                fontFamily: 'Sans-serif',
                color: 'white',
                  minHeight: '20px', // override the row height
                  '&:not(:last-of-type)': {
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                      borderBottomColor: '#8497B0',
                  },
              }
          },
          pagination: {
            style: {
              color: '#1A2028',
              fontSize: '1px',
              fontFamily: 'Sans-serif',
              minHeight: '56px',
              minWidth: '172px',
              maxWidth: '172px',
              backgroundColor: '#1A2028',
              borderTopStyle: 'solid',
              borderTopWidth: '0px',
              borderTopColor: '#8497B0',
            },
            pageButtonsStyle: {
              borderRadius: '50%',
              height: '40px',
              width: '20px',
              padding: '0px',
              margin: '0px',
              cursor: 'pointer',
              transition: '0.4s',
              color: '#8497B0',
              fill: '#8497B0',
              backgroundColor: 'transparent',
              '&:disabled': {
                cursor: 'unset',
                color: '#8497B0',
                fill: '#8497B0',
              },
              '&:hover:not(:disabled)': {
                backgroundColor: '#1A2028',
              },
              '&:focus': {
                outline: 'none',
                backgroundColor: '#1A2028',
              },
            },
          },
          headRow: {
            style: {
              backgroundColor: '#8497B0',
              minHeight: '20px',
              borderBottomWidth: '1px',
              borderBottomColor: '#8497B0',
              borderBottomStyle: 'solid',
            },
            denseStyle: {
              minHeight: '32px',
            },
          },
          headCells: {
              style: {
                  fontSize: '12px',
                  fontFamily: 'Sans-serif',
                  color: 'white',
                  backgroundColor:'#191f27',
                  paddingLeft: '1px', // override the cell padding for head cells
                  paddingRight: '1px',
              },
              activeSortStyle: {
                  color: 'gray',
                  '&:focus': {
                    outline: 'none',
                  },
                  '&:hover:not(:focus)': {
                    color: 'gray',
                  },
              },
              inactiveSortStyle: {
                  '&:focus': {
                    outline: 'none',
                    color: 'green',
                  },
                  '&:hover': {
                    color: 'gray',
                  },
              },
          },
          cells: {
              style: {
                  paddingLeft: '4px', // override the cell padding for data cells
                  paddingRight: '4px',
              },
          },
          table: {
              style: {
                  minHeight: '292px',
                  maxHeight: '292px',
                  minWidth: '172px',
                  maxWidth: '172px'
              }
          }, noData: {
              style: {
                  minHeight: '380px',
                  maxHeight: '380px',
                  minWidth: '172px',
                  maxWidth: '172px'
              }
          }
      }
    }
    createTheme("darkk", {
        text: {
            primary: '#59677a',
            secondary: '#59677a',
        },
        background: {
            default: '#1A2028',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#4d4a52',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    })
  }

  componentDidUpdate() {
    if (!this.refreshdata) {
      alarmsApi.getTableAlarmsRms().then((response) => {
        if (response.status) {
          //console.log("response : ", response)
          this.setState({ dataTable: response.data[0]});
        }
      }).catch((e) => { console.log("updateData : ", e) });
      this.updateData();
    }

  }


  updateData() {
     this.refreshdata = setInterval(() => {
       alarmsApi.getTableAlarmsRms().then((response) => {
         if (response.status) {
           this.setState({ dataTable: response.data[0]});
         }
       }).catch((e) => { console.log("updateData : ", e) });
     }, 5000);
  }

  render() {
    return (
      <div className={"row"} style={{width:'172px',backgroundColor: '#1A2028'}} >
        <div style={{color:'#FFFFFF'}}>Daily Alarms</div>
        <DataTable
            pagination
            paginationPerPage="20"
            theme="darkk"
            columns={this.columns}
            data={this.state.dataTable}
            paginationComponentOptions={{ noRowsPerPage: true }}
            noHeader
            customStyles={this.state.customStyles}
          />
      </div>
    )
  }

}
