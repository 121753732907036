import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./GraphPayloadCycle.css"
import Chart from "react-apexcharts";
import { Payload3 }  from "../Payload3/Payload3"

export class GraphPayloadCycle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      series: [{
        name: 'Prueba 1',
        type: 'area',
        data: [220, 175, 180, 190, 160, 150, 165, 165, 170, 180, 190, 200]
      }, {
        name: 'Prueba 2',
        type: 'line',
        data: [175, 175, 175, 175, 175, 175, 175, 175, 175, 175, 175, 175]
      }],
      options: {
        chart: {
          height: 100,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: ''
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: []
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Tonnes',
          },
        }, {
          opposite: true,
          title: {
            text: ''
          }
        }]
      },
    };
  }

  componentDidUpdate() {

  }

  componentWillMount() {
    //console.log("GraphPayloadCycle , this.props.series_1", this.props.series_1);
    //console.log("GraphPayloadCycle , this.props.series_2", this.props.series_2);
  }

  render() {
    return (<>
      <div className={"row payload"} >
        <div className={"col s6 iz-payload-avg "}>
          <div className={"row payload-avg-row"}>
              <div id="#aux_payload-avg-title" className={"col s12 payload-avg-title"}>
                  Payload (avg.)
              </div>
              <div className={"col s12 payload-avg-value"}>
                  {this.props.payloadAvg} T
              </div>
          </div>
        </div>
        <div className={"col s6 der-grafico-barras "}>
          <Payload3
            series_1 = {this.props.series_1}
            series_2 = {this.props.series_2}
            max_series = {this.props.max_series}
            type_1 = 'area'
            type_2 = 'line'
          />
        </div>
      </div>
      </>)
    }
}
