import React, { useRef, useEffect, Component } from "react";
import "./SideNav.css";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css";
import IconCasa from "../../assets/SideNav/Iconocasa.png";
import IconMapa from "../../assets/SideNav/Iconomap.png";
import IconGra1 from "../../assets/SideNav/Iconograp1.png";
import IconGra2 from "../../assets/SideNav/Iconograp2.png";
import IconGra3 from "../../assets/SideNav/Iconograp3.png";
//Icons

import {
    Home as HomeIcon,
    Timeline as UnitIcon,
    ShowChart as FleetIcon,
    Description as ReportIcon,
    Settings as SettingsIcon,
    AccountCircle as AdminIcon,
    PhotoCamera as PhotoIcon,
    GpsFixed as RtmIcon,
    SaveAlt as PayloadIcon,
    Adjust as RMSIcon,
    Warning as AlarmIcon,
    PhotoAlbum as PhotoAlbum
}
    from "@material-ui/icons";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { render } from "@testing-library/react";
import { Auth } from "../../helpers/auth";

const auth = new Auth();

export class SideNav extends Component {


    // useEffect(() => {
    //     M.Sidenav.init(theSide.current, {edge:"left", onOpenStart: true});
    //     console.log("inicia el sideNav");
    // });

    logout(e) {
        auth.logout();
        window.location.reload();
    }

    render() {
        if (!auth.validate()) {
            return (auth.render())
        }
        return (
            <>
                {/* SideNav */}

                <div className={"left-sidebar"}>
                    <ul id={"nav-mobile"} className={"sidenav sidenav-fixed"} >
                      {/*PERFIL MINERA ESCONDIDA LIMITADA (MEL)*/}
                      {
                        (auth.restrics('FAE-MEL')?
                          <>
                            <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/menu"><i className={"material-icons icon-text white-text"}><HomeIcon /></i>Home</a>
                            </li>
                            <li className={"bold"}>
                                <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/asset"><i className={"material-icons icon-text white-text"}><img src={IconGra2} alt={"Nexus"} width="28" height="28"/></i>Asset management</a>
                            </li>
                            <li className={"bold"}>
                                <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/rtmpics"><i className={"material-icons icon-text white-text"}><PhotoIcon /></i>Live Cam</a>
                            </li>
                            <li className={"bold"}>
                                <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/listpics"><i className={"material-icons icon-text white-text"}><PhotoAlbum /></i>Photo List</a>
                            </li>
                            {
                              (auth.authoriza('SYS-SET')?
                                <li className={"bold"}>
                                    <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/settings"><i className={"material-icons icon-text white-text"}><SettingsIcon /></i>Settings</a>
                                </li>

                            :""
                            )}
                            {
                              (auth.authoriza('SYS-ADM')?
                                <li className={"bold"}>
                                    <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"}><i className={"material-icons icon-text white-text"}><AdminIcon /></i>Admin</a>
                                </li>

                            :""
                            )}
                            <li className={"bold"}>
                                <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} onClick={(e) => { this.logout(e) }}><i className={"material-icons icon-text white-text"}><ExitToAppIcon /></i>Logout</a>
                            </li>
                          </>
                          :""
                      )}

                      {/*PERFIL GINGIN */}
                      {
                        (auth.restrics('FAE-GIN')?
                        <>
                          <li className={"bold"}>
                              <a className={" main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/"><i className={"material-icons icon-text white-text"}><img src={IconMapa} alt={"Nexus"} width="28" height="28"/></i>Map</a>
                          </li>
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/cycleanalytics"><i className={"material-icons icon-text white-text"}><img src={IconGra1} alt={"Nexus"} width="28" height="28"/></i>Cycle Analytics</a>
                          </li>
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/asset"><i className={"material-icons icon-text white-text"}><img src={IconGra2} alt={"Nexus"} width="28" height="28"/></i>Asset management</a>
                          </li>
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/payload"><i className={"material-icons icon-text white-text"}><PayloadIcon /></i>Payload</a>
                          </li>
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/rtm"><i className={"material-icons icon-text white-text"}><RtmIcon /></i>RTM</a>
                          </li>
                          {
                            (auth.authoriza('SYS-SET')?
                              <li className={"bold"}>
                                  <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/settings"><i className={"material-icons icon-text white-text"}><SettingsIcon /></i>Settings</a>
                              </li>

                          :""
                          )}
                          {
                            (auth.authoriza('SYS-ADM')?
                              <li className={"bold"}>
                                  <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"}><i className={"material-icons icon-text white-text"}><AdminIcon /></i>Admin</a>
                              </li>

                          :""
                          )}
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} onClick={(e) => { this.logout(e) }}><i className={"material-icons icon-text white-text"}><ExitToAppIcon /></i>Logout</a>
                          </li>
                        </>
                      :""
                      )}

                      {/*PERFIL ADMINISTRADOR USUARIO ESPECIAL*/}
                      {
                        (auth.authoriza('SYS-ADM')?
                        <>
                        <li className={"bold"}>
                            <a className={" main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/"><i className={"material-icons icon-text white-text"}><img src={IconMapa} alt={"Nexus"} width="28" height="28"/></i>Map</a>
                        </li>
                        <li className={"bold"}>
                            <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/cycleanalytics"><i className={"material-icons icon-text white-text"}><img src={IconGra1} alt={"Nexus"} width="28" height="28"/></i>Cycle Analytics</a>
                        </li>
                        <li className={"bold"}>
                            <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/asset"><i className={"material-icons icon-text white-text"}><img src={IconGra2} alt={"Nexus"} width="28" height="28"/></i>Asset management</a>
                        </li>
                        {
                          (!auth.restrics('FAE-MEL')
                          ?
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/payload"><i className={"material-icons icon-text white-text"}><PayloadIcon /></i>Payload</a>
                          </li>
                          :
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/rms"><i className={"material-icons icon-text white-text"}><RMSIcon /></i>RMS</a>
                          </li>
                        )}

                        <li className={"bold"}>
                            <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/rtm"><i className={"material-icons icon-text white-text"}><RtmIcon /></i>RTM</a>
                        </li>
                        {(auth.authoriza('USR-PIC')
                          ?
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/rtmpics"><i className={"material-icons icon-text white-text"}><PhotoIcon /></i>Live Cam</a>
                          </li>
                          :""
                        )}
                        {(auth.authoriza('USR-PIC')
                          ?
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/listpics"><i className={"material-icons icon-text white-text"}><PhotoAlbum /></i>Photo List</a>
                          </li>
                          :""
                        )}
                        {(auth.authoriza('USR-ALR')
                          ?
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/alarms"><i className={"material-icons icon-text white-text"}><AlarmIcon /></i>Alarms</a>
                          </li>
                          :""
                        )}
                        {(auth.authoriza('USR-REP')
                        ?
                        <li className={"bold"}>
                            <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"}><i className={"material-icons icon-text white-text"}><ReportIcon /></i>Reporting</a>
                        </li>
                        :""
                        )}
                        {(auth.authoriza('SYS-SET')
                          ?
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} href="/settings"><i className={"material-icons icon-text white-text"}><SettingsIcon /></i>Settings</a>
                          </li>
                          :""
                        )}
                        {(auth.authoriza('SYS-ADM')
                          ?
                          <li className={"bold"}>
                              <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"}><i className={"material-icons icon-text white-text"}><AdminIcon /></i>Admin</a>
                          </li>
                          :""
                        )}
                        <li className={"bold"}>
                            <a className={"main-item white-text waves-effect waves-teal sidenav-menu-opcion"} onClick={(e) => { this.logout(e) }}><i className={"material-icons icon-text white-text"}><ExitToAppIcon /></i>Logout</a>
                        </li>
                        </>
                        :""
                        )
                      }

                    </ul>

                    {/* <a href="#" data-target="slide-out" className="sidenav-trigger">
                            <i className="material-icons">menu</i>
                </a> */}
                </div>
            </>
        );
    }
};
