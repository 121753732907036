import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoxCycleAnalytics.css"

export class BoxCycleAnalytics extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<>
          <div class="row cycle-analytics">
            <div class="col s2 trucks-in-operation">
              <div className={" col s12 offset box-time-info-number-b"}>
                  <span>{this.props.TruckInOperation}</span>
              </div>
              <div className={" col s12 offset box-time-info-title"}>
                  <span>Trucks in operation</span>
              </div>
             </div>
             <div class="col s2 hours-of-operation">
               <div className={" col s12 offset box-time-info-number-h"}>
                   <span>{this.props.MinutesInOperation}</span>
               </div>
               <div className={" col s12 offset box-time-info-title-h"}>
                   <span>Hours of operation</span>
               </div>
             </div>
             <div class="col s2 cycles-today">
               <div className={" col s12 offset box-time-info-number-b"}>
                   <span>{this.props.NCycles}</span>
               </div>
               <div className={" col s12 offset box-time-info-title"}>
                   <span>Cycles today</span>
               </div>
             </div>
             <div class="col s2 speeding-alarms">
               <div className={" col s12 offset box-time-info-number-b"}>
                   <span>{this.props.SpeedingAlarms}</span>
               </div>
               <div className={" col s12 offset box-time-info-title"}>
                   <span>Speeding alarms</span>
               </div>
             </div>
             <div class="col s2 km-today">
               <div className={" col s12 offset box-time-info-number-b"}>
                   <span>{this.props.KmToday}</span>
               </div>
               <div className={" col s12 offset box-time-info-title"}>
                   <span>KM today</span>
               </div>
             </div>
          </div>
        </>
        )
    }
}
