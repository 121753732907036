import { Component }                from "react";
import DataTable, { createTheme }   from "react-data-table-component";
import { BoxCycleAnalytics }        from "../../../../../modulos/BoxCycleAnalytics/BoxCycleAnalytics"
import { BarTitle }                 from "../../../../../modulos/BarTitle/BarTitle";
import { BarTitleRtmpicsList }      from "../../../../../modulos/BarTitleRtmpicsList/BarTitleRtmpicsList";
import { PicsAPI }                  from "../../../../../../api/PicsAPI";
import Viewer                       from 'react-viewer';
import M from "materialize-css";
import "./Table2.css";

const picsapi = new PicsAPI();
export class Table2 extends Component {

  columns = [
      {
          name: 'Id',
          selector: 'id',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.Id_CAEX,
          sortable: true,
          center:true,
      },
      {
          name: 'Date (DD-MM-YYY HH:MI:SS)',
          selector: row => row.created_at,
          sortable: true,
          center:true,
      },
      {
          name: 'File',
          selector: row => row.path,
          format: row => this.FormatTableFile(row.path),
          sortable: true,
          center:true,
      },
      {
          name: 'Type',
          selector: row => row.tipo,
          sortable: true,
          center:true,
      },
  ];

  FormatTableFile(path){
      if(path!=undefined){
        let letters: string = path;
        return letters.substr(11, 21)
      }else{
        return ""
      }
  }

  constructor(props) {
      super(props);
      this.state = {
          data: new Array([]),
          visible: false,
          pathpics: process.env.PUBLIC_URL + '/loading-bar.gif',
      }

      createTheme("darkk", {
          text: {
              primary: '#59677a',
              secondary: '#59677a',
          },
          background: {
              default: '#191f27',
          },
          context: {
              background: '#cb4b16',
              text: '#FFFFFF',
          },
          divider: {
              default: '#4d4a52',
          },
          action: {
              button: 'rgba(0,0,0,.54)',
              hover: 'rgba(0,0,0,.08)',
              disabled: 'rgba(0,0,0,.12)',
          },
      })
  }

  componentWillMount() {
    if (!this.state.refreshdata) {
        this.updateData();
    }
  }

  componentWillUnmount() {
      clearInterval(this.refreshdata)
  }

  updateData() {
    picsapi.getPhotoList(this.props.idNodo).then((response) => {
      if (response.status) {
        this.setState({ data: response.data});
      }
    }).catch((e) => { console.log("updateData : ", e) });
  }

  datatableSelect(e) {
    this.setState({
      visible: true,
      pathpics: process.env.PUBLIC_URL + '/pics/' + e.path,
      altimage: e.Id_CAEX + " " + this.FormatTableFile(e.path) + " ",
    });
  }

  onClickbtnReturn(event) {
      if (this.props.btnreturn) {
          this.props.btnreturn(event)
      }
  }

  render() {
    return(
      <>
        <div className={"table-component"}>
          <div className={"row table-component-row"}>
            <div className={"col s12 fleet-title"}>
              <BarTitleRtmpicsList
                onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }}
                btnReturn
                bartitle={"Photo list"}
                subtitle={"Select file"}
                pagSelect = "table"
              />
              <Viewer
                visible={this.state.visible}
                onClose={() => {this.setState({ visible: false });}}
                images={[{src: this.state.pathpics, alt: this.state.altimage }]}
              />
              <div className={"col s12 table-datatable"}>
                  <DataTable
                      pagination
                      paginationPerPage="20"
                      theme="darkk"
                      data={this.state.data}
                      columns={this.columns}
                      selectableRows
                      onRowClicked={(e) => {this.datatableSelect(e)}}
                      pointerOnHover
                      paginationComponentOptions={{ noRowsPerPage: true }}
                      noHeader
                      highlightOnHover
                      customStyles={{
                          rows: {
                              style: {
                                backgroundColor: '#1A2028',
                                fontSize: '18px',
                                color: 'white',
                                  minHeight: '30px', // override the row height
                                  '&:not(:last-of-type)': {
                                      borderBottomStyle: 'solid',
                                      borderBottomWidth: '1px',
                                      borderBottomColor: '#8497B0',
                                  },
                              }
                          },
                          pagination: {
                            style: {
                              color: '#8497B0',
                              fontSize: '13px',
                              minHeight: '56px',
                              backgroundColor: '#1A2028',
                              borderTopStyle: 'solid',
                              borderTopWidth: '1px',
                              borderTopColor: '#8497B0',
                            },
                            pageButtonsStyle: {
                              borderRadius: '50%',
                              height: '40px',
                              width: '40px',
                              padding: '8px',
                              margin: 'px',
                              cursor: 'pointer',
                              transition: '0.4s',
                              color: '#8497B0',
                              fill: '#8497B0',
                              backgroundColor: 'transparent',
                              '&:disabled': {
                                cursor: 'unset',
                                color: '#8497B0',
                                fill: '#8497B0',
                              },
                              '&:hover:not(:disabled)': {
                                backgroundColor: '#FFFFFF',
                              },
                              '&:focus': {
                                outline: 'none',
                                backgroundColor: '#FFFFFF',
                              },
                            },
                          },
                          headRow: {
                            style: {
                              backgroundColor: '#8497B0',
                              minHeight: '30px',
                              borderBottomWidth: '1px',
                              borderBottomColor: '#8497B0',
                              borderBottomStyle: 'solid',
                            },
                            denseStyle: {
                              minHeight: '32px',
                            },
                          },
                          headCells: {
                              style: {
                                  fontSize: '16px',
                                  color: 'white',
                                  backgroundColor:'#191f27',
                                  paddingLeft: '10px', // override the cell padding for head cells
                                  paddingRight: '2px',
                              },
                              activeSortStyle: {
                                  color: 'gray',
                                  '&:focus': {
                                    outline: 'none',
                                  },
                                  '&:hover:not(:focus)': {
                                    color: 'gray',
                                  },
                              },
                              inactiveSortStyle: {
                                  '&:focus': {
                                    outline: 'none',
                                    color: 'green',
                                  },
                                  '&:hover': {
                                    color: 'gray',
                                  },
                              },
                          },
                          cells: {
                              style: {
                                  paddingLeft: '8px', // override the cell padding for data cells
                                  paddingRight: '8px',
                              },
                          },
                          table: {
                              style: {
                                  minHeight: '600px',
                                  maxHeight: '600px'
                              }
                          }, noData: {
                              style: {
                                  minHeight: '600px',
                                  maxHeight: '600px'
                              }
                          }
                      }}
                  />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
