import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./GraficoCycleTime.css"
import Chart from "react-apexcharts";
import { Auth } from "../../../helpers/auth";

const auth = new Auth();

export class GraficoCycleTime extends Component {

  constructor(props) {
    super(props)
    this.state = {
        options: {
          stroke: {
              curve: 'smooth',
          },
          colors: [
              "#15FF63","#8C1834", "#2E75B6","#047054", "#9D82EA",
              "#203864","#1B998B", "#A994F6", "#1B998B", "#2E75B6",
              "#CC0000", "#1B998B", "#803092","#203864", "#A994F6",
            ],
          plotOptions: {
              bar: {
                dataLabels: {
                  position: 'center',
                  maxItems: 100,

                },
                columnWidth: '50%',
                  dataLabels: {
                      orientation: (""),
                      position: ( "center"), // top, center, bottom
                  },
              }
          },
          chart: {
            height: 350,
            type: 'line',
            stacked: true,
          },
          stroke: {
            width: [0, 0 , 0, 0, 0, 0, 0],
            dashArray: [0, 0, 0, 0, 0, 0, 0],
            colors: ['#572EEE'],
          },
          title: {
            text: ''
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4, 5, 6],
            background: {
              opacity: 0.0,
            },
              enabled: true,
              formatter: function (val) {
                  if (val == 0) {
                      return ""
                  }
                  return val;
              },
              style: {
                  fontSize: '12px'
              },

          },
          legend: {
              show: true,
              position: "right",
              offsetY: 30,
              labels: {
                  colors: "#ffffff"
              },
              itemMargin: {
                  vertical: 0,
              },
              inverseOrder: true,
              itemMargin: {
                  vertical: 9,
              },
          },
          xaxis: {

          },
          yaxis: [{
            title: {
              text: "Minutes",
              style: {
                color: '#ffffff',
              }
            },
              max: (props.max ? Math.ceil(props.max + 1) : 10),
              show: true,
            },
            {
              max: (props.max ? Math.ceil(props.max + 1) : 10),
              show: false,
            },
            {
              max: (props.max ? Math.ceil(props.max + 1) : 10),
              show: false,
            },
            {
              max: (props.max ? Math.ceil(props.max + 1) : 10),
              show: false,
            },
           {
             max: (props.max ? Math.ceil(props.max + 1) : 10),
             show: false,
          },
          {
            max: (props.max ? Math.ceil(props.max + 1) : 10),
            show: false,
          },
          {
            max: (props.max ? Math.ceil(props.max + 1) : 10),
            show: false,
            labels: {
              offsetY: 5,
            }
          }]
        },
    }
  }

  componentDidUpdate() {
    //console.log(this.props.max," ",this.state.options.yaxis[0].max);
    if (this.props.max != undefined && Math.ceil(this.state.options.yaxis[0].max) != Math.ceil(this.props.max + 1) && this.state.options.yaxis[0].max != null ) {
      this.setState({
        options: {
          stroke: {
              curve: 'smooth',
          },
          colors: [
              "#15FF63","#8C1834", "#2E75B6","#047054", "#9D82EA",
              "#203864","#1B998B", "#A994F6", "#00B050", "#2E75B6",
              "#CC0000", "#1B998B", "#803092","#203864", "#A994F6",
            ],
          plotOptions: {
              bar: {
                dataLabels: {
                  position: 'center',
                  maxItems: 100,

                },
                columnWidth: '50%',
                  dataLabels: {
                      orientation: (""),
                      position: ( "center"), // top, center, bottom
                  },
              }
          },
          chart: {
            height: 350,
            type: 'line',
            stacked: true,
          },
          stroke: {
            width: [0, 0 , 0, 0, 0, 0, 0],
            dashArray: [0, 0, 0, 0, 0, 0, 0],
            colors: ['#572EEE'],
          },
          title: {
            text: ''
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4, 5, 6],
            background: {
              opacity: 0.0,
            },
              enabled: true,
              formatter: function (val) {
                  if (val == 0) {
                      return ""
                  }
                  return val;
              },
              style: {
                  fontSize: '12px'
              },

          },
          legend: {
              show: true,
              position: "right",
              offsetY: 30,
              labels: {
                  colors: "#ffffff"
              },
              itemMargin: {
                  vertical: 0,
              },
              inverseOrder: true,
              itemMargin: {
                  vertical: 9,
              },
          },
          xaxis: {

          },
          yaxis: [{
            title: {
              text: "Minutes",
              style: {
                color: '#ffffff',
              }
            },
              max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
              show: true,
            },
            {
              max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
              show: false,
            },
            {
              max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
              show: false,
            },
            {
              max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
              show: false,
            },
           {
             max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
             show: false,
          },
          {
            max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
            show: false,
          },
          {
            max: (this.props.max ? Math.ceil(this.props.max + 1) : null),
            show: false,
            labels: {
              offsetY: 5,
            }
          }]
        },
      })
    }
  }


  render() {
    return (<>
      <div className={"row graphbar"}>
          <div className={"col s12 graphbar-title"} style={{ background: "#0c1526" }}>
              {/*{this.props.title2 || ""}*/}

          </div>
          {(!auth.restrics('CA-GCT')
            ?
            <div className={"col s12 fleet-margin-bottom fleet-cycle-title"}>Cycle time - average</div>
            :
            <div className={"col s12 fleet-margin-bottom fleet-cycle-title"}>&nbsp;</div>
          )}
          <div className={"col s12 graphbar-graph-row-col"} style={{ background: "#191f27" }}>
            {(!auth.restrics('CA-GCT')
                ?
                  <Chart
                    options={this.state.options}
                    series={this.props.series}
                    type="line"
                    height= { 350 }
                  />
                :<div style={{height:"350px"}} ></div>
            )}
          </div>
      </div>
      </>)
  }
}
