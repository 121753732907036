import React, { Component } from "react";
import "./CycleAnalytics.css"
import { HomeAPi } from "../../../../api/HomeAPI";
import { Table } from "./modules/Table/Table";
import { Board } from "./modules/Board/Board";
import { Fleet } from "./modules/Fleet/Fleet";
import { TableGINGIN } from "./modules/Table/TableGINGIN";
import { BoardGINGIN } from "./modules/Board/BoardGINGIN";
import { FleetGINGIN } from "./modules/Fleet/FleetGINGIN";
import { UnitAPi } from "../../../../api/UnitAPI";
import { CycleAnalyticsAPi } from "../../../../api/CycleAnalyticsApi";
import { Auth } from "../../../../helpers/auth";

const auth = new Auth();
const homeapi = new HomeAPi();
const unitapi = new UnitAPi();
const cycleapi = new CycleAnalyticsAPi();

export class CycleAnalytics extends Component {
    columns = [
        {
            name: 'id',
            selector: 'id_nodo',
            sortable: true,
            omit: true,
            center:true
        },
        {
            name: 'Truck #',
            selector: row => row.tolva_id_caex,
            sortable: true,
            center:true,
            conditionalCellStyles: [
              {
                when: row => row.tolva_id_caex > 300,
                style: {
                  backgroundColor: 'rgba(63, 195, 128, 0.9)',
                  color: 'green',
                  '&:hover': { cursor: 'pointer', },
                },
              },
            ],
        },
        {
            name: '# Cycles',
            selector: 'Cycles',
            sortable: true,
            center:true
        },
        {
            name: 'Avg. Cycles Time (hh:mm:ss)',
            selector: row => row.avg_tt_gingin,
            format: row => this.FormatTimeTable(row.avg_tt_gingin),
            sortable: true,
            center:true
        },
        {
            name: 'Occupancy (%)',
            selector: 'availability_gingin',
            sortable: true,
            center:true
        },
        {
            name: 'Battery level (%)',
            selector: 'batery_level',
            sortable: true,
            center:true
        },
        {
            name: 'Satellites connected  #',
            selector: 'sat_in_use',
            sortable: true,
            center:true
        },
    ];

    columnsGinGin = [
        {
            name: 'id',
            selector: 'id_nodo',
            sortable: true,
            omit: true,
            center:true
        },

        {
            name: 'Truck #',
            selector: row => row.tolva_id_caex,
            sortable: true,
            center:true,
            conditionalCellStyles: [
              {
                when: row => row.tolva_id_caex > 300,
                style: {
                  backgroundColor: 'rgba(63, 195, 128, 0.9)',
                  color: 'green',
                  '&:hover': { cursor: 'pointer', },
                },
              },
            ],
        },
        {
            name: 'Payload (tonnes)',
            selector: 'payload',
            format: row => (row.payload/1000),
            sortable: true,
            center:true
        },
        {
            name: 'Cycles #',
            selector: 'Cycles',
            sortable: true,
            center:true
        },
        {
            name: 'Km today (km)',
            selector: 'km_today',
            format: row => ( Math.round( ((row.km_today/1000)*100))/100 ) ,
            sortable: true,
            center:true
        },
        {
            name: 'Avg. Cycles Time',
            selector: row => row.avg_tt_gingin,
            format: row => this.MinuteDecimalToString(row.avg_tt_gingin),
            sortable: true,
            center:true
        },
        {
            name: 'Operation Time',
            selector: 'operation',
            format: row => this.MinuteDecimalToString(row.operation),
            sortable: true,
            center:true
        },
        {
            name: 'Truckshop Time',
            selector: 'truckshop',
            format: row => this.MinuteDecimalToString(row.truckshop),
            sortable: true,
            center:true
        },
        {
            name: 'Parking Time',
            selector: 'service',
            format: row => this.MinuteDecimalToString(row.service),
            sortable: true,
            center:true
        },
    ];

    data = [ ];

    constructor(props) {
        super(props);
        this.state = {
            TruckInOperation: 0,
            NCycles: 0,
            KmToday: 0,
            Payload: 0,
            MinutesInOperation: 0,
            MinutesInIdle_GINGIN:0,
            MinutesInService_GINGIN:0,
            MinutesInTruckshoop_GINGIN:0,
            SpeedingAlarms: 0,
            data: this.data,
            view: 'table',
            selectedRow: {},
            batery_level: 0,
            sat_in_use: 0
        }
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))
          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }
        return (hour).toLocaleString('en') + "h " + minutes + "m"
    }

    FormatTimeTable(sec) {
        let hour: number = 0
        let minutes: number =  0
        let seconds: number = 0

        if(sec != null){
          hour = Math.trunc(sec / 3600)
          minutes = Math.trunc((sec - (hour * 3600))/60)
          seconds = Math.trunc(sec-((hour*3600)+(minutes*60)))
        }

        let hh = ''
        if (hour < 10 ) {
          hh = '0' + hour.toString();
        }else {
          hh = (hour).toLocaleString('en')
        }

        let mm = ''
        if (minutes < 10 ) {
          mm = '0' + minutes.toString()
        }else {
          mm = (minutes).toLocaleString('en')
        }

        let ss = ''
        if (seconds < 10 ) {
          ss = '0' + seconds.toString()
        }else {
          ss = (seconds).toLocaleString('en')
        }

        return hh + ":" + mm + ":" + ss
    }

    componentWillMount() {
      this.updateData();
   }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    updateData() {
      let datosTable = [];

      if(!auth.restrics('FAE-GIN')){
        //Datos para faenas no GIN GIN
          cycleapi.getBox().then((response) => {
          if (response.status){
            this.setState({
              TruckInOperation: response.data[0].TruckInOperation ,
              MinutesInOperation: response.data[0].MinutesInOperation_GINGIN ,
              NCycles: response.data[0].NCycles ,
              SpeedingAlarms: response.data[0].SpeedingAlarms ,
              KmToday: response.data[0].KmToday
            });
            const date = new Date();
            cycleapi.getTable(date).then((response) => {
              this.setState({ data: response.data[0] });
              });
          }
          this.refreshdata = setInterval(() => {
            cycleapi.getBox().then((response) => {
              if (response.status){
                this.setState({
                  TruckInOperation: response.data[0].TruckInOperation ,
                  MinutesInOperation : response.data[0].MinutesInOperation_GINGIN ,
                  NCycles: response.data[0].NCycles ,
                  SpeedingAlarms: response.data[0].SpeedingAlarms ,
                  KmToday: response.data[0].KmToday
                });
                const date = new Date();
                cycleapi.getTable(date).then((response) => {
                  this.setState({ data: response.data[0] });
                  });
              }
            }).catch((e) => { console.log(e) });
          }, 5000);
        }).catch((e) => { console.log(e) });
      }else{
        //Datos para faena GIN GIN
        cycleapi.getBoxGinGin().then((response) => {
          if (response.status){
            this.setState({
              TruckInOperation: response.data[0].TruckInOperation,
              NCycles: response.data[0].NCycles,
              KmToday: response.data[0].KmToday,
              Payload: response.data[0].Payload,
              MinutesInOperation_GINGIN: response.data[0].MinutesInOperation_GINGIN,
              MinutesInTruckshoop_GINGIN: response.data[0].MinutesInTruckshoop_GINGIN,
              MinutesInService_GINGIN: response.data[0].MinutesInService_GINGIN,
              MinutesInIdle_GINGIN:response.data[0].MinutesInIdle_GINGIN,
              SpeedingAlarms: response.data[0].SpeedingAlarmsSpeedingAlarms,
            });
            const date = new Date();
            cycleapi.getTableGinGin(date).then((response) => {
              console.log('getTableGinGin :', response);
              this.setState({ data: response.data[0] });
              });
          }
          this.refreshdata = setInterval(() => {
            cycleapi.getBoxGinGin().then((response) => {
              if (response.status){
                this.setState({
                  TruckInOperation: response.data[0].TruckInOperation,
                  NCycles: response.data[0].NCycles,
                  KmToday: response.data[0].KmToday,
                  Payload: response.data[0].Payload,
                  MinutesInOperation_GINGIN: response.data[0].MinutesInOperation_GINGIN,
                  MinutesInTruckshoop_GINGIN: response.data[0].MinutesInTruckshoop_GINGIN,
                  MinutesInService_GINGIN: response.data[0].MinutesInService_GINGIN,
                  MinutesInIdle_GINGIN:response.data[0].MinutesInIdle_GINGIN,
                  SpeedingAlarms: response.data[0].SpeedingAlarmsSpeedingAlarms,
                });
                const date = new Date();
                cycleapi.getTableGinGin(date).then((response) => {
                  this.setState({ data: response.data[0] });
                  });
              }
            }).catch((e) => { console.log(e) });
          }, 5000);
        }).catch((e) => { console.log(e) });
      }
    }

    datatableSelect(data) {
        this.setState({ view: 'board', selectedRow: data })
    }

    boardreturn(e) {
        this.setState({ view: 'table' })
    }

    fleetreturn(e) {
      this.setState ({ view: 'fleet'})
    }

    unitreturn(e) {
      this.setState ({ view: 'table'})
    }

    renderSwitch() {
      switch(this.state.view) {
        case 'board':
        if(!auth.restrics('FAE-GIN')){
          return (
            <Board
              btnreturn={(e) => { this.boardreturn(e) }}
              btnfleetreturn={(e) => { this.fleetreturn(e) }}
              btnunitreturn={(e) => { this.unitreturn(e) }}
              data={this.state.selectedRow}
            />);
        }else{
          return (
            <BoardGINGIN
              btnreturn={(e) => { this.boardreturn(e) }}
              btnfleetreturn={(e) => { this.fleetreturn(e) }}
              btnunitreturn={(e) => { this.unitreturn(e) }}
              data={this.state.selectedRow}
            />);
        }
        case 'table':
        if(!auth.restrics('FAE-GIN')){
          return (
                  <Table
                    btnreturn={(e) => { this.boardreturn(e) }}
                    btnfleetreturn={(e) => { this.fleetreturn(e) }}
                    btnunitreturn={(e) => { this.unitreturn(e) }}
                    TruckInOperation= {this.state.TruckInOperation}
                    MinutesInOperation= {this.state.MinutesInOperation}
                    NCycles= {this.state.NCycles}
                    SpeedingAlarms= {this.state.SpeedingAlarms}
                    KmToday= {this.state.KmToday}
                    data={this.state.data}
                    columns={this.columns}
                    onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
                  />
                )
        }else{
          return (
                  <TableGINGIN
                    btnreturn={(e) => { this.boardreturn(e) }}
                    btnfleetreturn={(e) => { this.fleetreturn(e) }}
                    btnunitreturn={(e) => { this.unitreturn(e) }}
                    TruckInOperation= {this.state.TruckInOperation}
                    NCycles= {this.state.NCycles}
                    KmToday= {this.state.KmToday}
                    Payload = {this.state.Payload}
                    MinutesInOperation_GINGIN = {this.state.MinutesInOperation_GINGIN}
                    MinutesInTruckshoop_GINGIN = {this.state.MinutesInTruckshoop_GINGIN}
                    MinutesInService_GINGIN = {this.state.MinutesInService_GINGIN}
                    MinutesInIdle_GINGIN = {this.state.MinutesInIdle_GINGIN}
                    data={this.state.data}
                    columns={this.columnsGinGin}
                    onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
                  />
                )
        }
        case 'fleet':
          if(!auth.restrics('FAE-GIN')){
            return (<Fleet
              btnreturn={(e) => { this.boardreturn(e) }}
              btnfleetreturn={(e) => { this.fleetreturn(e) }}
              btnunitreturn={(e) => { this.unitreturn(e) }}
              />);
          }else{
            return (<FleetGINGIN
              btnreturn={(e) => { this.boardreturn(e) }}
              btnfleetreturn={(e) => { this.fleetreturn(e) }}
              btnunitreturn={(e) => { this.unitreturn(e) }}
              />);
          }

        default:
          return 'foo';
      }
    }

    render() {
      return (
        <div>
          <div>{this.renderSwitch()}</div>
        </div>
      );
    };
}
