import React, { Component } from "react";

import "materialize-css/dist/css/materialize.min.css";
import "./Login.css";
import M from "materialize-css";

import { AccountCircle, VpnKey } from "@material-ui/icons";
import logoPath from "../../assets/login/logoIniciosesion.png";
import bg from "../../assets/login/bg.jpg";
import { LoginAPi } from "../../api/LoginAPi";
import { Redirect } from "react-router-dom";
import env from "react-dotenv";

const loginAPi = new LoginAPi();
export class Login extends Component {
    state = {
        redirect: false,
        username: "",
        password: ""
    }
    constructor(props) {
        super(props);
        //let auth = localStorage.getItem('auth');
        let auth = sessionStorage.getItem('auth');
        if (auth != null) auth = JSON.parse(auth)
        if (auth != null && auth.status) {
            this.state = {
                redirect: true,
                username: "",
                password: ""
            }
        }
    }

    keyPress = (event) => {
      if (event.key == 'Enter'){
        this.login()

      }
    }

    login() {
        //console.log(this.state.username)
        //loginAPi.loginHttp("nexus", "1234").then(response => {
        loginAPi.loginHttp(this.state.username, this.state.password).then(response => {
            if (response.status) {
                //localStorage.setItem('auth', JSON.stringify(response));
                sessionStorage.setItem('auth', JSON.stringify(response))
                this.setState({ redirect: true })
            } else {
                M.toast({ html: "¡Usuario o contraseña incorrecto!" })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
      if (this.state.redirect) {
        return <Redirect to='/menu' />
      }
      return (
        <>
        <div class="row relleno">${process.env.REACT_APP_VERSION}.ver</div>
        <div class="row">
          <div class="col s6 offset-s3" ><img src={logoPath} alt={"Nexus"} width="100%" height="100%"/></div>
          <div className={"col s6 offset-s3"}></div>
        </div>
        <div className={"login"}>
          <img className={"bg"} src={bg} alt={"Background app"} />
          <div className={"row"}>
            <div className={"col s6 offset-s3"}></div>
            <div className={"col s6 offset-s3"}>
              <div className={"card login-box"}>
                <div className={"card-content white-text"}>
                  <div className={"col s12 input-field "}>
                    <i className={"material-icons prefix"}><AccountCircle /></i>
                    <input id="username" className="white-text" type="text" value={this.state.username}  onKeyPress={this.keyPress} onChange={(e) => { this.setState({ username: e.target.value }) }} />
                    <label for="username" >username</label>
                  </div>
                  <div className={" col s12 input-field"}>
                    <i className={"material-icons prefix"}><VpnKey /></i>
                    <input id="password" className="white-text" type="password" value={this.state.password}  onKeyPress={this.keyPress} onChange={(e) => { this.setState({ password: e.target.value }) }} />
                    <label for="password" >password</label>
                  </div>
                  <div className={"center-align"}>
                    <button className={"btn waves-effect waves-green"} onClick={(res) => { this.login() }}>Log-in</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      );
    }

}
