import React, { Component } from "react";
import "./Payload.css"
import { HomeAPi }            from "../../../../api/HomeAPI";
import { Table }              from "./modules/Table/Table";
import { Board }              from "./modules/Board/Board";
import { UnitAPi }            from "../../../../api/UnitAPI";
import { CycleAnalyticsAPi }  from "../../../../api/CycleAnalyticsApi";
import { Auth }               from "../../../../helpers/auth";

const auth = new Auth();
const homeapi = new HomeAPi();
const unitapi = new UnitAPi();
const cycleapi = new CycleAnalyticsAPi();

export class Payload extends Component {
  columns = [
      {
          name: 'id',
          selector: 'id_nodo',
          sortable: true,
          omit: true,
          center:true
      },
      {
          name: 'Truck #',
          selector: row => row.tolva_id_caex,
          sortable: true,
          center:true,
          conditionalCellStyles: [
            {
              when: row => row.tolva_id_caex > 300,
              style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'green',
                '&:hover': { cursor: 'pointer', },
              },
            },
          ],
      },
      {
          name: 'Avg. Payload (tonnes)',
          selector: 'avg_payload',
          sortable: true,
          center:true
      },
      {
          name: 'Payload efficiency (%)',
          selector: 'efficiency',
          sortable: true,
          center:true
      },
      {
          name: 'Battery level (%)',
          selector: 'batery_level',
          sortable: true,
          center:true
      },
      {
          name: 'Satellites connected  #',
          selector: 'sat_in_use',
          sortable: true,
          center:true
      },
  ];
  data = [ ];

  constructor(props){
      super(props);
      this.state = {
          TruckInOperation: 0,
          MinutesInOperation: 0,
          NCycles: 0,
          SpeedingAlarms: 0,
          KmToday: 0,
          data: this.data,
          view: 'table',
          selectedRow: {},
          batery_level: 0,
          sat_in_use: 0,
          avg_payload: 0
      }
  }

  componentWillMount() {
    this.updateData();
  }

  componentWillUnmount(){
    clearInterval(this.refreshdata)
  }

  updateData() {
    let datosTable = [];

    if(!auth.restrics('FAE-GIN')){
      cycleapi.getBox().then((response) => {
        if (response.status){
          this.setState({
            TruckInOperation: response.data[0].TruckInOperation ,
            MinutesInOperation: response.data[0].MinutesInOperation_GINGIN ,
            NCycles: response.data[0].NCycles ,
            SpeedingAlarms: response.data[0].SpeedingAlarms ,
            KmToday: response.data[0].KmToday
          });

          const date = new Date();
          cycleapi.getTable(date).then((response) => {
            this.setState({ data: response.data[0] });
            });
        }

        this.refreshdata = setInterval(() => {
          cycleapi.getBox().then((response) => {
            if (response.status){
              this.setState({
                TruckInOperation: response.data[0].TruckInOperation ,
                MinutesInOperation: response.data[0].MinutesInOperation_GINGIN ,
                NCycles: response.data[0].NCycles ,
                SpeedingAlarms: response.data[0].SpeedingAlarms ,
                KmToday: response.data[0].KmToday
              });
              const date = new Date();
              cycleapi.getTable(date).then((response) => {
                this.setState({ data: response.data[0] });
                });
            }
          }).catch((e) => { console.log(e) });
        }, 5000);
      }).catch((e) => { console.log(e) });
    }else{
      cycleapi.getBoxGinGin().then((response) => {
        if (response.status){
          this.setState({
            TruckInOperation: response.data[0].TruckInOperation ,
            MinutesInOperation: response.data[0].MinutesInOperation_GINGIN ,
            NCycles: response.data[0].NCycles ,
            SpeedingAlarms: response.data[0].SpeedingAlarms ,
            KmToday: response.data[0].KmToday
          });

          const date = new Date();
          cycleapi.getTableGinGin(date).then((response) => {
            this.setState({ data: response.data[0] });
            });
        }

        this.refreshdata = setInterval(() => {
          cycleapi.getBoxGinGin().then((response) => {
            if (response.status){
              this.setState({
                TruckInOperation: response.data[0].TruckInOperation ,
                MinutesInOperation: response.data[0].MinutesInOperation_GINGIN ,
                NCycles: response.data[0].NCycles ,
                SpeedingAlarms: response.data[0].SpeedingAlarms ,
                KmToday: response.data[0].KmToday
              });
              const date = new Date();
              cycleapi.getTableGinGin(date).then((response) => {
                this.setState({ data: response.data[0] });
                });
            }
          }).catch((e) => { console.log(e) });
        }, 5000);
      }).catch((e) => { console.log(e) });
    }



  }

  renderSwitch() {
    switch(this.state.view) {
      case 'board':
        return (
          <Board
            btnreturn={(e) => { this.boardreturn(e) }}
            btnunitreturn={(e) => { this.unitreturn(e) }}
            data={this.state.selectedRow}
          />);
      case 'table':
      return (
              <Table
                btnreturn={(e) => { this.boardreturn(e) }}
                btnfleetreturn={(e) => { this.fleetreturn(e) }}
                btnunitreturn={(e) => { this.unitreturn(e) }}
                TruckInOperation= {this.state.TruckInOperation}
                MinutesInOperation= {this.state.MinutesInOperation}
                NCycles= {this.state.NCycles}
                SpeedingAlarms= {this.state.SpeedingAlarms}
                KmToday= {this.state.KmToday}
                data={this.state.data}
                columns={this.columns}
                onRowClicked={(e) => { this.datatableSelect(e) }} onChangeSelectValue={(e) => { this.onChangeSelectValue(e) }}
              />
            )
      default:
        return 'foo';
    }
  }

  datatableSelect(data) {
      this.setState({ view: 'board', selectedRow: data })
  }

  boardreturn(e) {
      this.setState({ view: 'table' })
  }

  unitreturn(e) {
    this.setState ({ view: 'table'})
  }

  render() {
    return (
      <div>
        <div>{this.renderSwitch()}</div>
      </div>
    );
  };

}
