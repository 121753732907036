import React, { Component } from 'react';
import "materialize-css/dist/css/materialize.min.css";
import { GpsFixed }         from '@material-ui/icons';
import "./Dashboard.css";
import M from "materialize-css";
import { Menu }             from '../menu/Menu';
import { Auth }             from '../../helpers/auth'
import { HeadNav }          from "../HeadNav/HeadNav";
import { Home }             from "./Dashboard-components/Home/Home";
import { Home2 }            from "./Dashboard-components/Home2/Home2";
import { HomeMel }            from "./Dashboard-components/HomeMel/HomeMel";
import { Unit }             from "./Dashboard-components/Unit/Unit";
import { Fleet }            from './Dashboard-components/fleet/Fleet';
import { CycleAnalytics }   from './Dashboard-components/CycleAnalytics/CycleAnalytics';
import { Settings }         from './Dashboard-components/Settings/Settings';
import { RTM }              from './Dashboard-components/RTM/RTM';
import { RMS }              from './Dashboard-components/RMS/RMS';
import { Areacreate }       from './Dashboard-components/Settings/Areas/Areacreate';
import { Tolvacreate }      from './Dashboard-components/Settings/Tolvas/Tolvacreate';
import { Areaupdate }       from './Dashboard-components/Settings/Areas/Areaupdate';
import { Tolvaupdate }      from './Dashboard-components/Settings/Tolvas/Tolvaupdate';
import { Nodos }            from './Dashboard-components/Settings/Nodos/Nodos';
import { Payload }          from './Dashboard-components/Payload/Payload';
import { Asset }            from './Dashboard-components/Asset/Asset';
import { RTMPics }          from './Dashboard-components/RTMPics/RTMPics';
import { ListPics }         from './Dashboard-components/ListPics/ListPics';
import { Alarms }           from './Dashboard-components/Alarms/Alarms';

const auth = new Auth();
export class Dashboard extends Component {

    staticContent = {
        top: '94px',
        overflow: 'auto',
        height: "100%"
    };

    stylee = {
        // width:'50%',
    };

    page() {
        if (this.props.location.pathname == "/unit") {
            return (<Unit />);
        } else if (this.props.location.pathname == "/cycleanalytics") {
              return (<CycleAnalytics />);
        } else if (this.props.location.pathname == "/fleet") {
            return (<Fleet />);
        } else if ((this.props.location.pathname == "/settings")&&(auth.authoriza('SYS-SET'))) {
            return (<Settings />);
        } else if (this.props.location.pathname == "/areaupdate") {
            return (<Areaupdate id={this.props.location.state.id} />);
        } else if (this.props.location.pathname == "/tolvaupdate") {
            return (<Tolvaupdate id={this.props.location.state.id} />);
        } else if (this.props.location.pathname == "/areacreate") {
            return (<Areacreate id={this.props.location.state.id}/>);
        } else if (this.props.location.pathname == "/tolvacreate") {
            return (<Tolvacreate id={this.props.location.state.id}/>);
        }else if (this.props.location.pathname == "/shownodo") {
            return (<Nodos id={this.props.location.state.id}/>);
        } else if (this.props.location.pathname == "/menu") {
            return (<Menu />);
        }else if (this.props.location.pathname == "/rtm") {
            return (<RTM />);
        }else if (this.props.location.pathname == "/payload") {
            return (<Payload />);
        }else if (this.props.location.pathname == "/asset") {
            return (<Asset />);
        }else if (this.props.location.pathname == "/home2") {
            return (<Home2 />);
        }else if ((this.props.location.pathname == "/alarms")&&(auth.authoriza('USR-ALR'))) {
            return (<Alarms />);
        }else if ((this.props.location.pathname == "/rtmpics")&&(auth.authoriza('USR-PIC'))) {
            return (<RTMPics />);
        }else if ((this.props.location.pathname == "/listpics")&&(auth.authoriza('USR-PIC'))) {
            return (<ListPics />);
        }else if ((this.props.location.pathname == "/rms")&&(auth.restrics('FAE-MEL'))) {
            return (<RMS />);
        }else if ((this.props.location.pathname == "/home")&&(auth.restrics('FAE-MEL'))) {
            return (<HomeMel />);
        }
        if (auth.restrics('FAE-MEL')) {
            return (<HomeMel />);
        }else{
            return (<Home />);
        }

    }

    render() {
        if (!auth.validate()) {
            return (auth.render())
        }
        if (this.props.location.pathname == "/menu") {
            return (<Menu />);
        } else {
            return (
                <>
                    <HeadNav />

                    <main >
                        {/* Component */}
                        <div className={"dash-container"}>
                            <div className="row no-margin">
                                {/* DASH */}
                                <div className="{col s12 dashboard}" style={this.staticContent}>
                                    {this.page()}

                                </div>
                            </div>
                        </div>
                        {/* End Component */}
                    </main>
                </>
            );
        }
    }
};
