import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./GraphMel.css"
import Chart from "react-apexcharts";
import truck from "../../../assets/map/truck.png";

export class GraphMel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: {
                xaxis: {
                  type: 'datetime',
                  tickPlacement: 'on',
                  tickAmount: 'dataPoints',
                  title: {
                    text: "TRAMA",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "#FFFFFF",
                      fontSize: '12px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                    },
                  },
                  labels: { //Etiquetas del eje X
                    style: {
                      colors: "#ffffff"
                    },
                    formatter: function(value) {
                      var date = new Date(value)
                      var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                      return time
                    },
                  },
                },
                yaxis:[{
                    max: (props.max_series ? Math.round(props.max_series) : 300 ),
                    show: true,
                    title: {
                        style: {
                            color: "#ffffff"
                        },
                        text: 'Level'
                    },
                    labels: {
                        style: {
                            colors: "#ffffff"
                        }
                    }
                },{
                  max: (props.max_series ? Math.round(props.max_series) : 300 ),
                  show: false,
                },{
                  max: (props.max_series ? Math.round(props.max_series) : 300 ),
                  show: false,
                },],
                chart: {
                    height: 100,
                    stacked: false,
                    toolbar: {
                        show: true
                    }
                },
                stroke: {
                  curve: ['straight', 'straight', 'straight'],
                  width: [3, 3 , 3, 0, 0, 0, 0],
                  dashArray: [0, 0, 0, 0, 0, 0, 0],
                  colors:['#FF5555','#A994F6','#803096',],
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1],
                  background: {
                    opacity: 0.0,
                  },
                  formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                    var serie = w.config.series[seriesIndex].name;
                    var top = w.config.series[2].data[dataPointIndex].y
                    var val = value;
                    var max = 0;
                    if (serie == 'RMS2'){
                        if (value > top ) {val = value ; }
                        else {val = ''};
                    }else if (serie == 'RMS1'){
                      if (value < top ) {val = '' }
                      else {val = ''};
                    }else{
                      {val = value }
                    }
                    return val;
                  }
                },
                tooltip: {
                  enabled: true,
                  enabledOnSeries:[0],
                  y: {
                    formatter: undefined,
                    title: {formatter: (seriesName) => 'RMS',},
                  },
                },


                legend: {
                  colors:['#FF5555','#A994F6','#803096',],
                    show: false,
                    position: "right",
                    offsetY: 30,
                    inverseOrder: false,
                    labels: {
                        colors: "#ffffff"
                    },
                    itemMargin: {
                        vertical: 9,
                    },
                },
            }
        }
    }

    componentDidUpdate() {
      if(this.props.series_2[0] != null && this.props.max_series != null && this.props.max_series != false && Math.trunc(this.state.options.yaxis[0].max) != Math.trunc(this.props.max_series) ) {
        let umbralMax = this.props.series_2[0].y;
        this.setState({
          options: {
              labels: this.props.labels,
              xaxis: {
                type: ['datetime'],
                tickPlacement: 'on',
                tickAmount: 'dataPoints',
                title: {
                  text: "TRAMA",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: "#FFFFFF",
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
                labels: { //Etiquetas del eje X
                  style: {
                    colors: "#ffffff"
                  },
                  formatter: function(value) {
                    var date = new Date(value)
                    var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                    return time
                  },
                },
              },
              yaxis:[{
                  max: (this.props.max_series ? Math.round(this.props.max_series) : 300 ),
                  show: true,
                  title: {
                      style: {
                          color:'#ECECEC',
                      },
                      text: 'Level'
                  },
                  labels: {
                      style: {
                          colors: '#ECECEC',
                      }
                  }
              },{
                max: (this.props.max_series ? Math.round(this.props.max_series) : 300 ),
                show: false,
              },{
                max: (this.props.max_series ? Math.round(this.props.max_series) : 300 ),
                show: false,
              },],
              stroke: {
                curve: 'smooth',
                width: [3, 2 , 2, 0, 0, 0, 0],
                dashArray: [0, 0, 0, 0, 0, 0, 0],
                colors:  ['#5463FF','#FFFFFF','#FFC300',],//Cambia color de lineas
              },
              dataLabels: {
                enabled: true,
                enabledOnSeries: [0,1],
                background: {
                  enabled: true,
                  foreColor: '#ECECEC',
                  padding: 7,
                  borderRadius: 2,
                  borderWidth: 2,
                  borderColor: '#ECECEC',
                  opacity: 0.7,
                  dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color:  ['#ECECEC','#ECECEC','#ECECEC',],
                    opacity: 0.3
                  }
                },
                style: {
                    fontSize: '10px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: undefined,
                },
              },
              colors: ['#FF1818', '#5463FF','#803096',], //Color relleno curva
              legend: {
                  show: false,
                  position: "left",
                  offsetY: 30,
                  labels: ['#FF1818','#FF1818','#FF1818',],
                  itemMargin: {
                      vertical: 0,
                  },
                  inverseOrder: false,
                  itemMargin: {
                      vertical: 9,
                  },
              },
          }
        })

      }
    }

    render() {
        return (<>
            <div className={"row payload"}>
              <div id="#aux_payload-avg-title" className={"col s12 graphbar-title"}>{this.props.title}</div>
                <div className={"col s12 payload-graph"} style={{ background: "#191f27" }}>
                    <Chart
                        options={this.state.options}
                        series={[{
                            name: this.props.series_1_name+"2",
                            type: 'line',
                            data: this.props.series_1
                        },{
                            name: this.props.series_1_name+"1",
                            type: 'linea',
                            data: this.props.series_1
                        },{
                            name: this.props.series_2_name,
                            type: 'line',
                            data: this.props.series_2
                        }
                        ]}
                        height = {320}
                    />
                </div>
            </div>
        </>
        )
    }
}
