import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./Board.css"
import { BarTitle } from "../../../../../modulos/BarTitle/BarTitle";
import { SelectorDate } from "../../../../../modulos/SelectorDate/SelectorDate"
import { BoxTime } from "../../../../../modulos/BoxTime/BoxTime"
import { BoxSpeed } from "../../../../../modulos/BoxSpeed/BoxSpeed"
import { Donut } from "../../../../../modulos/Donut/Donut"
import { Payload } from "../../../../../modulos/Payload/Payload"
import { Cycle } from "../../../../../modulos/Cycle/Cycle"
import { Monitor } from "../../../../../modulos/Monitor/Monitor"
import { UnitAPi } from "../../../../../../api/UnitAPI";

const unitapi = new UnitAPi();

export class Board extends Component {
    subtitle = ["Efficiency", "Payload", "Cycle", "Speed", "Availability", "MRT"]


    constructor(props) {
        super(props)
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.state = {
            monitor: false,
            dateSelected: date,
            intervalSelected: "h",
            graph_key: [],
            graph_payload: [],
            graph_tt: [],
            data: false,
            refreshdata: false
        }
    }

    componentWillMount() {
        if (!this.state.refreshdata) {
            this.updateData()
            unitapi.getMRTByIdNodo(this.props.data.id_nodo).then(response => {


                if (response.status) this.setState({ monitor: response.data })

                this.state.refreshdata = setInterval(() => {
                    unitapi.getMRTByIdNodo(this.props.data.id_nodo).then(response => {
                        if (response.status) this.setState({ monitor: response.data })
                    })

                }, 5000);
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshdata)
    }

    updateData(date = null, interval = "h") {
        console.log("1")
        unitapi.getBoardByIdNodo(this.props.data.id_nodo, date || this.state.dateSelected, interval).then((response) => {

            if (response.status) {
                console.log(response.data)
                let payload = [];
                let tt = [];
                let date_x;
                let graph = {}
                for (const g of response.data.graph) {
                    graph[new Date(g.date).getTime()] = g
                }
                for (const gk of Object.keys(graph).sort()) {
                    console.log(this.state.intervalSelected)
                    if (this.state.intervalSelected == "h") {
                        date_x = new Date(graph[gk].date).toLocaleTimeString().substring(0, 5)
                    } else if (this.state.intervalSelected == "d") {
                        date_x = new Date(graph[gk].date).toDateString().split(" ")
                        date_x = date_x[1] + "  " + date_x[2];
                    } else if ((this.state.intervalSelected == "m")||
                               (this.state.intervalSelected == "y")||
                               (this.state.intervalSelected == "1y")||
                               (this.state.intervalSelected == "2y")){
                        date_x = new Date(graph[gk].date).toDateString().split(" ")
                        date_x = date_x[1] + ", " + date_x[3]
                    }
                    //  console.log(date_x)
                    payload.push({
                        x: date_x,
                        y: Math.round(graph[gk].payload)
                    })
                    tt.push({
                        x: date_x,
                        y: Math.round(graph[gk].tt)
                    })
                }
                if (response.status) this.setState({
                    data: response.data,
                    graph_payload: payload,
                    graph_tt: tt
                });
            }
        });
    }

    onClickbtnReturn(event) {
        //     console.log(event)
        if (this.props.btnreturn) {
            this.props.btnreturn(event)
        }
    }

    onChangeValue(date, interval) {
        this.setState({
            dateSelected: date,
            intervalSelected: interval
        })
        //  console.log(interval)
        this.updateData(date, interval);
    }

    MinuteDecimalToString(minuted) {
        let hour: number = 0
        let minutes: number =  0
        if(minuted != null){
          hour = Math.trunc(minuted / 60)
          minutes = Math.round(minuted - (hour * 60))

          if(minutes == 60 ){
            hour =  hour + 1;
            minutes = 0;
          }
        }

        return hour + ":" + minutes + "h"
    }

    render() {
        return (<>
            <div className={"row board-row"}>
                <div className={"col s12 board-margin-bottom board-bar-title"}>
                    <BarTitle onClickbtnReturn={(e) => { this.onClickbtnReturn(e) }} btnReturn title={this.props.data.tolva_id_caex} subtitle={this.subtitle} />
                </div>
                <div className={"col s12 box-componente-down"}>
                    <div className={"row "}>
                        <div className={"col s6 box-iz "}>
                            <div className={"row"}>
                                <div className={"col s12 board-margin-bottom board-selector-date"}>
                                    <SelectorDate onChange={(d, i) => { this.onChangeValue(d, i) }} />
                                </div>
                                <div className={"col s12 board-margin-bottom board-box-time "}>
                                    <BoxTime efficiency={(this.state.data ? Math.round(this.state.data.Efficiency) + " %" : "0 %")} runingTime={this.MinuteDecimalToString(this.state.data.time_runing)} queuingToLoad={this.MinuteDecimalToString(this.state.data.time_load)} queuingToDump={this.MinuteDecimalToString(this.state.data.time_dump)} fuelingstop={this.MinuteDecimalToString(this.state.data.time_service)} />
                                </div>

                                <div className={"col s12 board-margin-bottom board-payload"}>
                                    <Payload payloadAvg={(this.state.data ? Math.round(this.state.data.avg_payload) + " t" : "0 t")} series={this.state.graph_payload} />
                                </div>

                                <div className={"col s12 board-margin-bottom board-cycle"}>
                                    <Cycle series={this.state.graph_tt} averageCycleTime={(this.state.data ? Math.round(this.state.data.avg_tt) + " m" : "0 m")} />
                                </div>

                                <div className={"col s12 board-margin-bottom board-box-speed"}>
                                    <BoxSpeed averageEmptySpeed={(this.state.data ? this.state.data.Speed_avg_unload + " km" : "0 km")} averageLoadedSpeed={(this.state.data ? this.state.data.Speed_avg_load + " km" : "0 km")} speedingAlerts={(this.state.data ? this.state.data.ciclo_alarma : 0)} />

                                </div>
                            </div>
                        </div>
                        <div className={"col s6 box-der"}>

                            <div className={"row box-der-row"}>
                                <div className={"col s12 board-donut board-donut-r "}>
                                    <Donut title="Availability (%)" series={[(this.state.data ? Math.round(this.state.data.availability) : 0), (this.state.data ? Math.round(this.state.data.availability_truckshop) : 0), (this.state.data ? Math.round(this.state.data.availability_parking) : 0)]} labels={["Avaliable", "Truckshop", "Others (Parking)"]} />
                                </div>

                                <div className={"col s12 board-monitor-title"}>Real Time Monitor (RTM)</div>
                                <div className={"col s12 board-margin-bottom board-monitor"}>
                                    {(this.state.monitor ? <Monitor value={this.state.monitor} /> : null)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}
