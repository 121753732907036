import { render } from "@testing-library/react"
import { Component } from "react"
import "materialize-css/dist/css/materialize.min.css";
import "./BoxTimeGINGIN.css"
import { Auth } from "../../../helpers/auth";

const auth = new Auth();
export class BoxTimeGINGIN extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (<>

          <div className={"row gboxContent"}>
            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                  <div className={" col s12 gbox-time-info-number-chico"}>
                      <span> {this.props.Payload} tonnes</span>
                  </div>
                  <div className={" col s12 gbox-time-info-title-chico"}>
                      <span>Payload</span>
                  </div>
              </div>
            </div>

            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                  <div className={" col s12 gbox-time-info-number-chico"}>
                      <span> {this.props.CycleNumber} </span>
                  </div>
                  <div className={" col s12 gbox-time-info-title-chico"}>
                      <span>Cycle Number</span>
                  </div>
              </div>
            </div>

            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                <div className={" col s12 gbox-time-info-number-chico"}>
                  <span>{this.props.AvgCycleTime}</span>
                </div>
                <div className={" col s12 gbox-time-info-title-chico"}>
                  <span>Avg. Cycle Time</span>
                </div>
              </div>
            </div>

            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                <div className={" col s12 gbox-time-info-number-chico"}>
                  <span>{this.props.OperationTime}</span>
                </div>
                <div className={" col s12 gbox-time-info-title-chico"}>
                  <span>Operation Time</span>
                </div>
              </div>
            </div>

            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                  <div className={" col s12 gbox-time-info-number-chico"}>
                      <span> {this.props.TruckshopTime}</span>
                  </div>
                  <div className={" col s12 gbox-time-info-title-chico"}>
                      <span>Truckshop Time</span>
                  </div>
              </div>
            </div>

            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                  <div className={" col s12 gbox-time-info-number-chico"}>
                      <span> {this.props.ParkingTime}</span>
                  </div>
                  <div className={" col s12 gbox-time-info-title-chico"}>
                      <span>Parking Time</span>
                  </div>
              </div>
            </div>

            <div className={" col gbox-time-info"}>
              <div className={"row"}>
                  <div className={" col s12 gbox-time-info-number-chico"}>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </div>
                  <div className={" col s12 gbox-time-info-title-chico"}>
                      <span></span>
                  </div>
              </div>
            </div>
          </div>
        </>
        )
    }
}
