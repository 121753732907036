import React, { Component } from "react";
import { Monitor } from "../../../modulos/Monitor/Monitor";
import { Map } from "../../../modulos/Map/Map";
import { Graphome } from "../../../modulos/GraficoHome/GraficoHome";
import "./Home.css"
import { HomeAPi } from "../../../../api/HomeAPI";
import { Redirect } from "react-router";

const homeapi = new HomeAPi();

export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data: [],
          route: true
       }
    }

    componentWillMount() {
        if (!this.refreshdata) {
            homeapi.getMRT().then(response => {
                if (response.status) this.setState({ data: response.data });
                this.refreshdata = setInterval(() => {
                    homeapi.getMRT().then(response => {
                        if (response.status) this.setState({ data: response.data });
                        //console.log(response)
                    }).catch(err => {
                        console.log(err)
                    })
                }, 5000);
            }).catch(err => {
                console.log(err)
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshdata)
    }

    render() {
        return (
            <>
                <div className={"home-component"}>
                    <div className={"row home-component-row"}>
                        <div className={"col s12 home-map"}>
                            <Map
                              trucks={this.state.data}
                              route={this.state.route}
                            />
                        </div>
  {/*                   <div className={"col s6 home-graph"}>
                            <Graphome />
                        </div>
                        <div className="col s12 home-monitor-barra">
                            <table className={"home-monitor-barra-table"} >
                                <tr className={"home-monitor-barra-tr"}>

                                    {this.state.data.map((value, index) => {

                                        return <td className={"home-monitor"}><Monitor value={value} className={"home-monitor-barra-tr-monitor"}/></td>
                                    })}
                                </tr>
                            </table>
                        </div>
                  */}


                    </div>
                </div>
            </>)
    }
};

/*

                        <div className="col s12 home-monitor-barra">

                            <div className={"row "}>

                                {this.state.data.map((value, index) => {

                                    return <div className={"col s11"}> <Monitor value={value} className={"home-monitor-barra-tr-monitor"} /></div>


                                })}


                            </div>
                        </div>
*/
